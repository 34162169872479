import React, { useState, useEffect } from "react";
import _ from "underscore";
import { Row, Col } from "react-bootstrap";
import ErrorMdl from "../components/errorMdl";

const qs = require("query-string");
const axios = require("axios");
const store = require("store");

const OARedirect = (props) => {
  const [wayfayless_uri, setWayfayless] = useState("");
  const parsed = qs.parse(props.location.search);
  const [hasError, setErrors] = useState(false);
  const [errorshow, setErrorShow] = useState(false);
  /**
   * on page load/update
   */
  const OAAuth = async () => {
    axios
      .get("/api/redirect", { params: { code: parsed.code } })

      .then((res) => {
        if (res) {
          if (!_.isUndefined(res.status) && res.status === 200) {
            if (res.data.success) {
              if (!_.isUndefined(store.get("target_link_uri"))) {
                if (store.get("target_link_uri").includes("/Login")) {
                  props.history.push({
                    pathname: "/Home",
                    state: {
                      withCredentials: true,
                    },
                  });
                } else {
                  window.location.href = store.get("target_link_uri");
                  store.remove("target_link_uri");
                }
              } else {
                props.history.push({
                  pathname: "/Home",
                  state: {
                    withCredentials: true,
                  },
                });
              }
            } else {
              console.log("erroro here else");

              setErrors({
                message:
                  "Your Organisation is not registered on TRIP for PRO Access",
              });
              setErrorShow(true);
              setTimeout(() => {
                setErrorShow(false);
                props.history.push({
                  pathname: "/Home",
                  state: {
                    withCredentials: true,
                  },
                });
              }, 3200);
            }
          } else {
            setErrors({
              message:
                "Your Organisation is not registered on TRIP for PRO Access",
            });
            setErrorShow(true);
          }
        } else {
          setErrors({
            message: "Authentication failed!",
          });
          setErrorShow(true);
        }
        //callback for the response
      })
      .catch((error) => {
        setErrors({
          message: "Authentication failed!",
        });
        setErrorShow(true);
      });
  };
  useEffect(() => {
    document.title = "OpenAthens Login - Trip Medical Database";
    document.body.classList.remove("homepage");
    OAAuth();
  }, []);
  return (
    <main role="main" className="main mt-2">
      <ErrorMdl error={hasError} setErrorShow={setErrorShow} show={errorshow} />

      <Row></Row>
      <Row>
        <Col md="3">{/* <Sidebar {...props} /> */}</Col>
        <Col md="6">
          <Row>
            <Col>
              <div className="spinner-border spinner-border-md text-success"></div>
              &nbsp;&nbsp;Redirecting..... <br />
              {wayfayless_uri && (
                <a href={wayfayless_uri}>
                  Click here if the redirect is taking too long......
                </a>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </main>
  );
};

export default OARedirect;
