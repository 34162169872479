import React, { useState, useEffect } from "react";
import axios from "axios";
import NHSSvg from "../assets/svg/NHSLogo";
const store = require("store");
const _ = require("underscore");

const NHSLibkey = ({
  data,
  setLibkeyMdlShow,
  setChildInstitutions,
  institutionLibId,
}) => {
  const [isHidden, setHidden] = useState(true);
  //const [institutionAuth, setInstitutionAuth] = useState({});

  async function fetchChildInstitutions(institution_id) {
    axios
      .get("/childInstitutions", {
        params: { institution_id: institution_id },
      })
      .then((res) => {
        if (res.status === 200 && !_.isUndefined(res.data)) {
          var hidden = isHidden;
          if (!_.isUndefined(res.data.institutions)) {
            setChildInstitutions(res.data.institutions);
            if (_.size(res.data.institutions) > 0) hidden = false;
            //setHidden(false);
            else hidden = true; //setHidden(true);

            if (_.size(res.data.selected_childInstitutions) > 0) hidden = true;

            setHidden(hidden);
          } else {
            setChildInstitutions([]);
            setHidden(true);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    let existingInstTokens = "";
    if (!_.isUndefined(store.get("instution_auth_tokens"))) {
      existingInstTokens = store.get("instution_auth_tokens");
      //  console.log("here", existingInstTokens.institution_auth);
      //Make a call if empty
      if (!_.isNull(existingInstTokens.institution_auth))
        if (!_.isUndefined(existingInstTokens.institution_auth.id))
          fetchChildInstitutions(existingInstTokens.institution_auth.id);
    }
    // if (!_.isUndefined(data.id)) {
    //   if (data.id > 0) fetchChildInstitutions(data.id);
    // }
  }, []);
  return (
    !isHidden &&
    !institutionLibId && (
      <div className="notification notification--nhs" style={{ zIndex: "2" }}>
        <div className="notification--content">
          <a
            className="type-small"
            href="#"
            onClick={(e) => {
              setLibkeyMdlShow(true);
              e.preventDefault();
            }}
          >
            <div className="nhs-logo">
              <NHSSvg />
            </div>
            <p>
              NHS User? We can improve your results - select your institution
            </p>
          </a>
        </div>
        <div className="notification--dismiss">
          {/* <a className="js-dismiss" href="#">
            <i className="fa fa-times"></i>
          </a> */}
        </div>
      </div>
    )
  );
};
export default NHSLibkey;
