import React from "react";
import _ from "underscore";
import { Spinner } from "react-bootstrap";

import ResultSuggestions from "./ResultSuggestions";
const ResultHeader = ({
  props,
  totalArticles,
  loading,
  keyword,
  sort,
  categories_pro,
  currentParams = null,
}) => {
  var category_pro = _.where(categories_pro, { category_id: 11 });
  var category_pro_count = !_.isUndefined(category_pro[0])
    ? category_pro[0].count
    : 0;
  var str = "";

  function getParamsValues() {
    if (!_.isUndefined(currentParams.search_type)) {
      switch (currentParams.search_type) {
        case "standard":
          str += !_.isUndefined(currentParams.criteria)
            ? currentParams.criteria
            : "";
          str += !_.isUndefined(currentParams.clinical)
            ? " " + currentParams.clinical
            : "";
          break;
        case "pico":
          str += !_.isUndefined(currentParams.criteria)
            ? currentParams.criteria
            : "";
          str +=
            !_.isUndefined(currentParams.intervention) &&
            currentParams.intervention
              ? ", " + currentParams.intervention
              : "";
          str +=
            !_.isUndefined(currentParams.comparison) && currentParams.comparison
              ? ", " + currentParams.comparison
              : "";
          str +=
            !_.isUndefined(currentParams.outcome) && currentParams.outcome
              ? ", " + currentParams.outcome
              : "";
          break;
        case "advanced":
          str += !_.isUndefined(currentParams.criteria)
            ? currentParams.criteria
            : "";
          str +=
            !_.isUndefined(currentParams.anywords) && currentParams.anywords
              ? ", " + currentParams.anywords
              : "";
          str +=
            !_.isUndefined(currentParams.exactphrase) &&
            currentParams.exactphrase
              ? ", " + currentParams.exactphrase
              : "";
          str +=
            !_.isUndefined(currentParams.excludingwords) &&
            currentParams.excludingwords
              ? ", " + currentParams.excludingwords
              : "";
          str +=
            !_.isUndefined(currentParams.advfrom) && currentParams.advfrom
              ? ", " + currentParams.advfrom
              : "";
          str +=
            !_.isUndefined(currentParams.advto) && currentParams.advto
              ? ", " + currentParams.advto
              : "";
          break;
      }
      if (!_.isUndefined(currentParams.from_date))
        str += currentParams.from_date
          ? " from_date:" + currentParams.from_date
          : "";
      return str;
    } else return "";
  }
  // const handleSearch = (e) => {
  //   e.preventDefault();
  //   props.history.push({
  //     pathname: "/Top/" + str,
  //     search: "?criteria=" + str + "&search_type=standard",
  //     state: {
  //       criteria: str,
  //       search_type: "standard",
  //     },
  //   });
  // };
  return (
    <div className="col mt-0">
      <div className="search-term">
        <h1 style={{ marginTop: "0" }}>{getParamsValues()}</h1>
        <div className="search-term--results-numbers">
          {loading ? (
            <div className="pull-center">
              <Spinner animation="border" size="sm" variant="success" />{" "}
              Processing results..
            </div>
          ) : (
            <span>
              {totalArticles ? (
                <span className="bold">
                  {totalArticles.toLocaleString() + "   results"}
                </span>
              ) : (
                "Results not found"
              )}
            </span>
          )}
          {totalArticles ? (
            <span className="pro-label">
              Pro users have access to +{category_pro_count} Systematic Reviews
            </span>
          ) : (
            ""
          )}
        </div>
        {/* <ResultSuggestions
          keywords={keyword}
          props={props}
          qry={str}
          currentParams={currentParams}
        /> */}
      </div>
    </div>
  );
};

export default ResultHeader;
