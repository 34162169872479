import React from "react";
import RobGraphics from "./RobGraphics";
// import GuidelinesNoGraphics from "./GuidelinesNoGraphics";
const _ = require("underscore");

const RobScore = ({ score, handleRob, category_ct }) => {
  let total = 0;
  if (!_.isNull(score)) {
    total = score * 100;
    total = total.toFixed();
  }
  return !_.isNull(score) ? (
    <RobGraphics score={total} handleRob={handleRob}></RobGraphics>
  ) : category_ct ? null : null; // <RobNoGraphics /> : null;
};
export default RobScore;
