import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import axios from "axios";
import LibKeySvg from "../assets/svg/Libkey";
import LinkResolverSvg from "../assets/svg/LinkResolver";
import AbstractSvg from "../assets/svg/Abstract";
import FulltextSvg from "../assets/svg/Fulltext";
const _ = require("underscore");

const URLComponent = ({
  data,
  idx = 0,
  count,
  dotClass,
  isPro,
  libkeydata,
  linkResolver_url,
  handleArticleClick,
}) => {
  //const [isHidden, setHidden] = useState(true);
  //const [htmlRes, setHtmlRes] = useState("");
  const [libUrl, setLibUrl] = useState("");

  async function fetchLibKey() {
    if (
      !_.isUndefined(libkeydata.libkeylibraryid) &&
      !_.isUndefined(libkeydata.libkeyaccesstoken) &&
      libkeydata.libkeyaccesstoken &&
      data.fullText == false
    ) {
      var doistr = data.doi;
      let url =
        "https://public-api.thirdiron.com/public/v1/libraries/" +
        libkeydata.libkeylibraryid +
        "/articles/doi/" +
        doistr +
        "?access_token=" +
        libkeydata.libkeyaccesstoken;
      axios
        .get(url)
        .then((res) => {
          if (res.status === 200 && !_.isUndefined(res.data)) {
            var fullLink = !_.isEmpty(res.data.data.fullTextFile)
              ? res.data.data.fullTextFile
              : !_.isEmpty(res.data.data.contentLocation)
              ? res.data.data.contentLocation
              : null;

            setLibUrl(fullLink);
          }
        })
        .catch((error) => {
          console.log(error);
        });
      // setHidden(false);
    }
  }

  if (
    !_.isUndefined(libkeydata) &&
    libkeydata &&
    data.doi &&
    data.fullText == false
  ) {
    fetchLibKey(data.doi);
  }
  return (
    <div className="result--title">
      <div className="result--title--dot-and-title">
        <span className={dotClass}></span>
        <a
          href={libUrl ? libUrl : data.Url}
          target="_blank"
          rel="nofollow"
          pos={count}
          onClick={(e) => {
            handleArticleClick(e, data, count);
          }}
        >
          <h5>{data.Title}</h5>
        </a>
      </div>
      <div className="text-indicators">
        {/* Abstract for free users */}
        {data.Subtitle == "(Abstract)" ? (
          <OverlayTrigger
            key={"top abstract-free-users" + idx}
            placement="top"
            overlay={
              <Tooltip id={`tooltip-top-abstract-free-users` + idx}>
                <em>Abstract available from PubMed</em>
              </Tooltip>
            }
          >
            <a
              className="text-indicator text-indicator--abstract text-indicator__active"
              data-toggle="tooltip"
              data-html="true"
              title=""
              pos={count}
              data-original-title="<em>Abstract</em>"
              href={data.Url}
              onClick={(e) => {
                handleArticleClick(e, data, count);
              }}
              target="_blank"
            >
              <AbstractSvg />{" "}
            </a>
          </OverlayTrigger>
        ) : null}
        {/* end abstract for free users */}
        {data.fullText ? (
          <OverlayTrigger
            key={"top full-text-key" + idx}
            placement="top"
            overlay={
              <Tooltip id={`tooltip-top-full-text` + idx}>
                <em>Fulltext Access</em>
              </Tooltip>
            }
          >
            <a
              className="text-indicator text-indicator--abstract text-indicator__active"
              data-toggle="tooltip"
              data-html="true"
              title=""
              pos={count}
              data-original-title="<em>Abstract available from PubMed</em>"
              href={data.Url}
              onClick={(e) => {
                handleArticleClick(e, data, count);
              }}
              target="_blank"
            >
              <FulltextSvg />
            </a>
          </OverlayTrigger>
        ) : null}
        {data.fullText && <div className="text-indicator-divider"></div>}
        {data.OriginalUrl ? (
          <OverlayTrigger
            key={"top abstract" + idx}
            placement="top"
            overlay={
              <Tooltip id={`tooltip-top-abstract` + idx}>
                <em>Abstract available from PubMed</em>
              </Tooltip>
            }
          >
            <a
              className="text-indicator text-indicator--abstract text-indicator__active"
              data-toggle="tooltip"
              data-html="true"
              title=""
              pos={count}
              data-original-title="<em>Abstract available from PubMed</em>"
              href={data.OriginalUrl}
              onClick={(e) => {
                handleArticleClick(e, data, count);
              }}
              target="_blank"
            >
              <AbstractSvg />{" "}
            </a>
          </OverlayTrigger>
        ) : null}
        {data.OriginalUrl && <div className="text-indicator-divider"></div>}
        {data.Subtitle2 && (
          <OverlayTrigger
            key={"top fulltext-pro" + idx}
            placement="top"
            className="pro-tooltip "
            overlay={
              <Tooltip id={`tooltip-top-fulltext-pro` + idx}>
                Full Text available to Pro users
              </Tooltip>
            }
          >
            <a
              className="text-indicator text-indicator--full-text test"
              data-custom-class="pro-tooltip"
              data-toggle="tooltip"
              data-html="true"
              title=""
              href="/Why"
              data-original-title="<em>Full Text available to Pro users</em>"
            >
              <FulltextSvg />
            </a>
          </OverlayTrigger>
        )}
        {data.Subtitle2 ? (
          <OverlayTrigger
            key={"top fulltext-pro-usersonly" + idx}
            placement="top"
            className="pro-tooltip"
            overlay={
              <Tooltip id={`tooltip-top-fulltext-pro-useronly` + idx}>
                <em>Full Text available to Pro users</em>
              </Tooltip>
            }
          >
            <div dangerouslySetInnerHTML={{ __html: data.Subtitle2 }}></div>
          </OverlayTrigger>
        ) : null}
        {data.doi ? <span title={data.doi}>DOI</span> : null}
        {isPro && data.fullText == false && (data.doi || linkResolver_url) ? (
          <span className="text-indicator" key={"spntop" + idx}>
            {libUrl && data.Subtitle !== "(Abstract)" ? (
              <span className="text-indicator" key={"spnabstract2" + idx}>
                <OverlayTrigger
                  key={"top abstract-free-users" + idx}
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-top-abstract-free-users` + idx}>
                      <em>Abstract available from PubMed</em>
                    </Tooltip>
                  }
                >
                  <a
                    className="text-indicator text-indicator--abstract text-indicator__active"
                    data-toggle="tooltip"
                    data-html="true"
                    title=""
                    pos={count}
                    data-original-title="<em>Abstract</em>"
                    href={data.Url}
                    onClick={(e) => {
                      handleArticleClick(e, data, count);
                    }}
                    target="_blank"
                  >
                    <AbstractSvg />{" "}
                  </a>
                </OverlayTrigger>
              </span>
            ) : null}
            {/* {libUrl && data.Subtitle !== "(Abstract)" && (
              <div className="text-indicator-divider"></div>
            )} */}
            {libUrl ? (
              <span className="text-indicator" key={"spntoplibkey" + idx}>
                <div className="text-indicator-divider"></div>
                <OverlayTrigger
                  key={"top fulltext-pro-libkey" + idx}
                  placement="top"
                  className="pro-tooltip "
                  overlay={
                    <Tooltip id={`tooltip-top-fulltext-pro-libkey` + idx}>
                      <em>Full Text with LibKey</em>
                    </Tooltip>
                  }
                >
                  <a
                    href={libUrl}
                    className="text-indicator text-indicator--full-text text-indicator__active"
                    target="_blank"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Tooltip on top"
                  >
                    {libUrl ? <LibKeySvg /> : ""}
                  </a>
                </OverlayTrigger>
              </span>
            ) : linkResolver_url ? (
              <span className="text-indicator" key={"spnres" + idx}>
                <div className="text-indicator-divider"></div>
                <OverlayTrigger
                  key={"top fulltext-pro-libresolver" + idx}
                  placement="top"
                  className="pro-tooltip "
                  overlay={
                    <Tooltip id={`tooltip-top-fulltext-pro-libresolver` + idx}>
                      <em>Full Text with LinkResolver</em>
                    </Tooltip>
                  }
                >
                  <a
                    className="text-indicator text-indicator--full-text text-indicator__active"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Tooltip on top"
                    href={linkResolver_url}
                    target="_blank"
                  >
                    <LinkResolverSvg />
                  </a>
                </OverlayTrigger>
              </span>
            ) : (
              ""
            )}
          </span>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
export default URLComponent;
