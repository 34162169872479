import React from "react";

export default () => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 13.52 15.53"
  >
    <g>
      <path
        d="M9.3,1l3,2.87h-3ZM1,.84H8.38v4h4.17V14.7H1ZM0,0V15.53H13.52V3.88L9.47,0Z"
        fill="#b3b3b3"
        fillRule="evenodd"
      ></path>
      <rect x="2.58" y="2.94" width="4.73" height="0.47" fill="#b3b3b3"></rect>
      <rect x="2.58" y="4.34" width="4.73" height="0.47" fill="#b3b3b3"></rect>
      <rect x="2.58" y="5.74" width="7.19" height="0.47" fill="#b3b3b3"></rect>
    </g>
  </svg>
);
