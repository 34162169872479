import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useAuth } from "./../context/auth";
// import { PageBanner } from "./../context/PageBanner";
import _ from "underscore";
import { fectchInstitutionAuth } from "./../context/authInstitution";
const axios = require("axios");
const store = require("store");

const Home = (props) => {
  // const [institutionCheck, setInstitution] = useState(false);
  const [hasError, setErrors] = useState(false);
  const { setAuthTokens, authTokens } = useAuth();
  const [homePro, sethomePro] = useState(false);

  async function fetchAuth() {
    axios
      .get("/Auth/getAuth")

      .then((res) => {
        if (res.status === 200) {
          store.set("tokens", JSON.stringify(res.data));
          setAuthTokens(res.data);
        } else {
          setAuthTokens("");
        }
      })
      .catch((error) => {
        setAuthTokens("");
      });
  }

  function refreshLoginTokens() {
    if (!_.isUndefined(store.get("tokens"))) {
      var existingProfile =
        !_.isNull(store.get("tokens")) && store.get("tokens") !== ""
          ? JSON.parse(store.get("tokens"))
          : null;
      if (!_.isNull(existingProfile)) {
        if (!_.isUndefined(existingProfile.loggedin)) fetchAuth();
      }
    }
  }

  /**
   * useEffect - onload
   */
  useEffect(() => {
    document.body.classList.add("homepage");
    // if (!(!_.isUndefined(props.isPro) ? props.isPro : false)) {
    //   setInstitution(fectchInstitutionAuth()); //fectchInstitution();
    //   console.log("check institution");
    // }
    // refreshLoginTokens();
  }, []);
  return (
    <div className="container homepage-content">
      <div className="row">
        <div className="col col-lg-6 mb-5">
          <div className="card h-100">
            <div className="card-body pt-5 pb-5 p-md-5">
              <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 216.28 109"
              >
                <g>
                  <g>
                    <path
                      d="M59.36,14.25V10.79q0-5.58-3.34-5.58H3.34Q0,5.21,0,10.79v3.46q0,5.57,3.34,5.57H21.05v61.9H38.31V19.82H56q3.34,0,3.34-5.57M78.13,26.38q-7.47,0-12.14,8.48a17,17,0,0,1-.39-1.79A17.79,17.79,0,0,0,65.1,31c-.15-.41-.38-.93-.67-1.56a3.23,3.23,0,0,0-1.17-1.4,6.56,6.56,0,0,0-2.95-.44,38.78,38.78,0,0,0-6.74.89q-4.51.9-4.51,2.68a32.78,32.78,0,0,0,.67,4A69.65,69.65,0,0,1,50.4,46.9V81.72H66.88V46.45c2.23-3.71,5.19-5.57,8.91-5.57a11.35,11.35,0,0,1,2.89.45,11.28,11.28,0,0,0,2.34.44c1,0,1.79-1.17,2.45-3.51a22.09,22.09,0,0,0,1-5.86,6.75,6.75,0,0,0-1.4-4.18c-.93-1.22-2.58-1.84-5-1.84m81.25,8q-5.79-8-15-8a17.23,17.23,0,0,0-9,2.34,15.67,15.67,0,0,0-6,6,16.76,16.76,0,0,1-.39-1.68,19.62,19.62,0,0,0-.5-2,14.55,14.55,0,0,0-.67-1.62,3.23,3.23,0,0,0-1.17-1.4,6.53,6.53,0,0,0-3-.44,38.78,38.78,0,0,0-6.74.89q-4.52.9-4.51,2.68a32.78,32.78,0,0,0,.67,4,69.65,69.65,0,0,1,.67,11.71v57.18h16.48V83a18.85,18.85,0,0,0,11.36,3.46q11.13,0,17.32-8.09t6.18-22.08q0-14-5.79-22M138.11,73.44a15.68,15.68,0,0,1-7.8-2.12V46.45q3.78-6.57,9.13-6.57,9,0,9,16.78T138.11,73.44m-39-68.85H96c-4.46,0-6.68,1.19-6.68,3.57v8.11h16.48V8.16q0-3.57-6.68-3.57m0,22.85H96c-4.46,0-6.68,1.19-6.68,3.57V81.72h16.48V31q0-3.57-6.68-3.57"
                      fill="#533764"
                      fillRule="evenodd"
                    />
                    <path
                      d="M37.33,82.12v.58q0,3.9-6.36,3.9H27.26q-6.36,0-6.36-3.9v-.58Z"
                      fill="#63c608"
                      fillRule="evenodd"
                    />
                    <path
                      d="M67.19,82.12v.58q0,3.9-6.66,3.9h-3.1q-6.66,0-6.66-3.9v-.58Z"
                      fill="#0e6cbb"
                      fillRule="evenodd"
                    />
                    <path
                      d="M106,82.12V83c0,2.38-2.22,3.56-6.65,3.56H96.25c-4.44,0-6.66-1.18-6.66-3.56v-.92Z"
                      fill="#00a89d"
                      fillRule="evenodd"
                    />
                    <path
                      d="M130.7,104.65v.9q0,3.45-6.55,3.45h-3.06q-6.56,0-6.55-3.45v-.9Z"
                      fill="#eec82f"
                      fillRule="evenodd"
                    />
                    <path
                      d="M106,16.42v.92c0,2.38-2.22,3.56-6.65,3.56H96.25c-4.44,0-6.66-1.18-6.66-3.56v-.92Z"
                      fill="#ba390d"
                      fillRule="evenodd"
                    />
                  </g>
                  <rect
                    x="146.1"
                    width="70.18"
                    height="35.84"
                    rx="8"
                    fill="#11b6a8"
                  />
                  <path
                    d="M202.11,18.49h-6.65a4.13,4.13,0,0,1,.29-1.27,3.51,3.51,0,0,1,.67-1,3.17,3.17,0,0,1,1-.71,3.37,3.37,0,0,1,1.34-.25,3.32,3.32,0,0,1,1.31.25,3.48,3.48,0,0,1,1,.7,3.26,3.26,0,0,1,.69,1A3.93,3.93,0,0,1,202.11,18.49ZM199,25.63a5.26,5.26,0,0,0,3.27-1A4.56,4.56,0,0,0,204,21.71h-1.88a2.75,2.75,0,0,1-1,1.7,3.27,3.27,0,0,1-2,.56,3.93,3.93,0,0,1-1.64-.31,3.26,3.26,0,0,1-1.14-.84,3.15,3.15,0,0,1-.65-1.21,4.91,4.91,0,0,1-.18-1.44h8.67a9.52,9.52,0,0,0-.19-2.22,6.41,6.41,0,0,0-.85-2.15,5.11,5.11,0,0,0-1.64-1.63,4.79,4.79,0,0,0-2.56-.63,5.29,5.29,0,0,0-2.19.44A5.11,5.11,0,0,0,195,15.24a5.74,5.74,0,0,0-1.13,1.9,6.94,6.94,0,0,0-.41,2.42,9.36,9.36,0,0,0,.4,2.44,5.36,5.36,0,0,0,1,1.93,4.68,4.68,0,0,0,1.71,1.25A5.92,5.92,0,0,0,199,25.63Zm-8.91-7.14h-6.66a4.13,4.13,0,0,1,.29-1.27,3.35,3.35,0,0,1,.68-1,3,3,0,0,1,1-.71,3.61,3.61,0,0,1,2.64,0,3.35,3.35,0,0,1,1,.7,3.26,3.26,0,0,1,.69,1A3.94,3.94,0,0,1,190.09,18.49ZM187,25.63a5.22,5.22,0,0,0,3.27-1,4.56,4.56,0,0,0,1.68-2.93H190a2.66,2.66,0,0,1-1,1.7,3.23,3.23,0,0,1-2,.56,4,4,0,0,1-1.64-.31,3.26,3.26,0,0,1-1.14-.84,3.29,3.29,0,0,1-.65-1.21,4.91,4.91,0,0,1-.18-1.44h8.67a9,9,0,0,0-.19-2.22,6.41,6.41,0,0,0-.85-2.15,5.11,5.11,0,0,0-1.64-1.63,4.76,4.76,0,0,0-2.56-.63,5.21,5.21,0,0,0-2.18.44A5.15,5.15,0,0,0,183,15.24a5.92,5.92,0,0,0-1.13,1.9,6.94,6.94,0,0,0-.4,2.42,9.37,9.37,0,0,0,.39,2.44,5.36,5.36,0,0,0,1,1.93,4.68,4.68,0,0,0,1.71,1.25A6,6,0,0,0,187,25.63Zm-10.14-.25V20.23a7.89,7.89,0,0,1,.22-2,3.89,3.89,0,0,1,.72-1.47,3.11,3.11,0,0,1,1.3-.92,5.52,5.52,0,0,1,1.94-.31v-2a4.25,4.25,0,0,0-2.55.62,5.12,5.12,0,0,0-1.7,2.09h0V13.8h-1.79V25.38Zm-12.26,0V18.06h7.35V16.27h-7.35V11.18H173V9.39h-10.5v16Z"
                    fill="#fff"
                  />
                </g>
              </svg>
              <Helmet>
                <meta
                  name="description"
                  content="Trip - Evidence-based answers for health professionals | Searching sources such as systematic reviews, clinical guidelines and RCTs"
                />
              </Helmet>
              <h2>Trusted Answers</h2>

              <p>
                A smart, fast tool to find high quality clinical research
                evidence.
              </p>

              <ul>
                <li>Searched over 125,000,000 times</li>
                <li>Over 70% of clinical questions answered</li>
                <li>Millions of articles items indexed & uniquely ranked</li>
                <li>Twenty years of learning & fine tuning</li>
              </ul>

              <Link
                to="/About"
                className="btn btn-secondary"
                title="About Trip"
              >
                About Trip
              </Link>
            </div>
          </div>
        </div>

        <div className="col col-lg-6 mb-5">
          <div className="card h-100">
            <div className="card-body pt-5 pb-5 p-md-5">
              <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 216.28 109"
              >
                <g>
                  <path
                    d="M59.36,14.25V10.79q0-5.58-3.34-5.58H3.34Q0,5.21,0,10.79v3.46q0,5.57,3.34,5.57H21.05v61.9H38.31V19.82H56q3.34,0,3.34-5.57M78.13,26.38q-7.47,0-12.14,8.48a17,17,0,0,1-.39-1.79A17.79,17.79,0,0,0,65.1,31c-.15-.41-.38-.93-.67-1.56a3.23,3.23,0,0,0-1.17-1.4,6.56,6.56,0,0,0-2.95-.44,38.78,38.78,0,0,0-6.74.89q-4.51.9-4.51,2.68a32.78,32.78,0,0,0,.67,4A69.65,69.65,0,0,1,50.4,46.9V81.72H66.88V46.45c2.23-3.71,5.19-5.57,8.91-5.57a11.35,11.35,0,0,1,2.89.45,11.28,11.28,0,0,0,2.34.44c1,0,1.79-1.17,2.45-3.51a22.09,22.09,0,0,0,1-5.86,6.75,6.75,0,0,0-1.4-4.18c-.93-1.22-2.58-1.84-5-1.84m81.25,8q-5.79-8-15-8a17.23,17.23,0,0,0-9,2.34,15.67,15.67,0,0,0-6,6,16.76,16.76,0,0,1-.39-1.68,19.62,19.62,0,0,0-.5-2,14.55,14.55,0,0,0-.67-1.62,3.23,3.23,0,0,0-1.17-1.4,6.53,6.53,0,0,0-3-.44,38.78,38.78,0,0,0-6.74.89q-4.52.9-4.51,2.68a32.78,32.78,0,0,0,.67,4,69.65,69.65,0,0,1,.67,11.71v57.18h16.48V83a18.85,18.85,0,0,0,11.36,3.46q11.13,0,17.32-8.09t6.18-22.08q0-14-5.79-22M138.11,73.44a15.68,15.68,0,0,1-7.8-2.12V46.45q3.78-6.57,9.13-6.57,9,0,9,16.78T138.11,73.44m-39-68.85H96c-4.46,0-6.68,1.19-6.68,3.57v8.11h16.48V8.16q0-3.57-6.68-3.57m0,22.85H96c-4.46,0-6.68,1.19-6.68,3.57V81.72h16.48V31q0-3.57-6.68-3.57"
                    fill="#533764"
                    fillRule="evenodd"
                  />
                  <path
                    d="M37.33,82.12v.58q0,3.9-6.36,3.9H27.26q-6.36,0-6.36-3.9v-.58Z"
                    fill="#63c608"
                    fillRule="evenodd"
                  />
                  <path
                    d="M67.19,82.12v.58q0,3.9-6.66,3.9h-3.1q-6.66,0-6.66-3.9v-.58Z"
                    fill="#0e6cbb"
                    fillRule="evenodd"
                  />
                  <path
                    d="M106,82.12V83c0,2.38-2.22,3.56-6.65,3.56H96.25c-4.44,0-6.66-1.18-6.66-3.56v-.92Z"
                    fill="#00a89d"
                    fillRule="evenodd"
                  />
                  <path
                    d="M130.7,104.65v.9q0,3.45-6.55,3.45h-3.06q-6.56,0-6.55-3.45v-.9Z"
                    fill="#eec82f"
                    fillRule="evenodd"
                  />
                  <path
                    d="M106,16.42v.92c0,2.38-2.22,3.56-6.65,3.56H96.25c-4.44,0-6.66-1.18-6.66-3.56v-.92Z"
                    fill="#ba390d"
                    fillRule="evenodd"
                  />
                  <rect
                    x="146.1"
                    width="70.18"
                    height="35.84"
                    rx="8"
                    fill="#ffe300"
                  />
                  <path d="M191.5,24.38A3.37,3.37,0,0,1,189,23.25a3.8,3.8,0,0,1-.77-1.37A5.66,5.66,0,0,1,187.9,20a5.7,5.7,0,0,1,.28-1.87,4,4,0,0,1,.77-1.38,3.41,3.41,0,0,1,1.15-.85,3.52,3.52,0,0,1,2.8,0,3.32,3.32,0,0,1,1.15.85,4.21,4.21,0,0,1,.78,1.38,6.38,6.38,0,0,1,0,3.74,4,4,0,0,1-.78,1.37,3.42,3.42,0,0,1-1.15.84A3.51,3.51,0,0,1,191.5,24.38Zm0,1.66a6.05,6.05,0,0,0,2.43-.46,4.94,4.94,0,0,0,1.76-1.28,5.19,5.19,0,0,0,1.07-1.91,8,8,0,0,0,0-4.76,5.28,5.28,0,0,0-1.07-1.93,5.22,5.22,0,0,0-1.76-1.29,6.49,6.49,0,0,0-4.85,0,5.26,5.26,0,0,0-1.77,1.29,5.59,5.59,0,0,0-1.07,1.93,8,8,0,0,0,0,4.76,5.5,5.5,0,0,0,1.07,1.91,5,5,0,0,0,1.77,1.28A6.11,6.11,0,0,0,191.5,26Zm-10.21-.25V20.64a8.32,8.32,0,0,1,.22-2,3.75,3.75,0,0,1,.72-1.47,3.11,3.11,0,0,1,1.3-.92,5.39,5.39,0,0,1,1.95-.31v-2a4.25,4.25,0,0,0-2.55.63,5.17,5.17,0,0,0-1.71,2.08h0V14.21h-1.79V25.79Zm-9.74-8.33h-4.17V11.59h4.17a3.9,3.9,0,0,1,2.65.75,2.77,2.77,0,0,1,.84,2.18,2.82,2.82,0,0,1-.84,2.2A3.8,3.8,0,0,1,171.55,17.46Zm-4.17,8.33V19.25h4.89A4.88,4.88,0,0,0,175.93,18a4.75,4.75,0,0,0,1.24-3.5A4.7,4.7,0,0,0,175.93,11a5,5,0,0,0-3.66-1.24h-7v16Z" />
                </g>
              </svg>

              {document.body.className === "homepage pro-user" ? (
                <h2>Congratulations you're using Pro</h2>
              ) : (
                <h2>Upgrade to Trip PRO</h2>
              )}

              <p>
                Trip Pro is the most advanced version of Trip it has extra
                content and functionality, including:
              </p>

              <ul>
                <li>100,000+ extra systematic reviews</li>
                <li>Medical images and videos</li>
                <li>Links to millions of full-text articles</li>
                <li>Export facility to reference management software</li>
                <li>Advanced search</li>
                <li>Much more</li>
              </ul>

              {!(document.body.className === "homepage pro-user") && (
                <Link to="/Pro" className="btn btn-warning" title="Get Pro">
                  Get PRO
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
