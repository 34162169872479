import React from "react";
import { Card, CardDeck, Row, Col, Button, Breadcrumb } from "react-bootstrap";
import SidebarAdmin from "../../components/SidebarAdmin";

const Access = (props) => (
  <main role="main" className="container-fluid main mt-2">
    <Row>
      <SidebarAdmin></SidebarAdmin>

      <Col>
        <Row>
          <Col md="12">
            <Breadcrumb>
              <Breadcrumb.Item href="/Admin">
                <i className="fa fa-home"></i> Home
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                <i className="fa fa-graph"></i> Payment
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row className="mt-2 ml-1">Tabs</Row>
      </Col>
    </Row>
  </main>
);

export default Access;
