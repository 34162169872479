import React, { useState } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import _ from "underscore";
import "react-datepicker/dist/react-datepicker.css";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import AsyncSelect from "react-select/async";
import axios from "axios";

const BatchMdl = ({
  batch,
  batchselectbody,
  batchselectsubject,
  setBatchselectbody,
  setBatchselectsubject,
  setBatchName,
  setBatchStatus,
  handleClose,
  handleBatch,
  show,
}) => {
  const [templateselectdata, setTemplateSelectData] = useState("");

  //console.log(publicationdata);
  const promiseOptions = (inputValue) => {
    return axios
      .get(
        "/Administrator/getAllTemplates?type=Broadcast&search=" + inputValue,
        {
          withCredentials: true,
        }
      )

      .then((res) => {
        if (res.status === 200) {
          setTemplateSelectData(res.data.data);
          return res.data.lookupdata;
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleTemplateSelect = (newValue) => {
    if (newValue.value) {
      var getdata = _.findWhere(templateselectdata, {
        id: newValue.value,
      });
      setBatchselectsubject(getdata.subject);
      setBatchselectbody(getdata.body);
      setBatchName(getdata.subject);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} animation={false} size="xl">
      <Form width="100%" onSubmit={handleBatch}>
        <Modal.Header closeButton>
          <Modal.Title className="text-success">
            <i className="fa fa-book"></i>{" "}
            {batch.currentaction && batch.currentaction == "add-article"
              ? "Add New Batch"
              : "Update Batch -" + batch.batch_id}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="batch.template">
            <Form.Label>Template</Form.Label>
            <AsyncSelect
              cacheOptions
              defaultOptions
              loadOptions={promiseOptions}
              //value={batch.currenttemplate ? batch.currenttemplate : ""}
              onChange={(selectedOptions) =>
                handleTemplateSelect(selectedOptions)
              }
            />
          </Form.Group>
          <Form.Group controlId="template.subject">
            <Form.Label>
              <i className="fa fa-envelope"></i> Subject
            </Form.Label>
            <Form.Control
              type="text"
              name="subject"
              placeholder="Subject"
              value={batchselectsubject}
              onChange={(e) => {
                setBatchselectsubject(e.target.value);
              }}
              required
            />
          </Form.Group>

          <Form.Group controlId="template.body">
            <Form.Label>
              <i className="fa fa-copy"></i> Body
            </Form.Label>
            <CKEditor
              editor={ClassicEditor}
              data={batchselectbody}
              onChange={(event, editor) => {
                setBatchselectbody(editor.getData());
              }}
            />
          </Form.Group>
          <Form.Group controlId="batch.title">
            <Form.Label>
              <i className="fa fa-info-circle"></i> Name
            </Form.Label>
            <Form.Control
              type="text"
              name="batch_name"
              placeholder="Name"
              value={batch.batch_name ? batch.batch_name : ""}
              onChange={(e) => setBatchName(e.target.value)}
              required
            />
          </Form.Group>

          {batch.currentaction && batch.currentaction == "edit-article" ? (
            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Label>Status</Form.Label>
              <Form.Control
                width="100%"
                as="select"
                defaultValue={batch.batch_status}
                onChange={(e) => setBatchStatus(e.target.value)}
              >
                <option>Queued</option>
                <option>Progress</option>
                <option>Complete</option>
                <option>Test</option>
              </Form.Control>
            </Form.Group>
          ) : (
            ""
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            <i className="fa fa-times"></i> Close
          </Button>
          <Button
            variant="danger"
            onClick={(e) => {
              handleBatch(e, "Cancel");
            }}
          >
            <i className="fa fa-back"></i> Abort/Cancel Email
          </Button>
          <Button
            variant="warning"
            onClick={(e) => {
              handleBatch(e, "Test");
            }}
          >
            <i className="fa fa-check"></i> Test Email
          </Button>
          <Button
            variant="success"
            onClick={(e) => {
              // setBatchStatus("Queued");
              handleBatch(e, "Queued");
            }}
          >
            <i className="fa fa-envolope"></i> Send Email
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default BatchMdl;
