const axios = require("axios");
const PublicationPromiseOptions = (inputValue) => {
  return axios
    .get("/Administrator/getAllPublications?search=" + inputValue, {
      withCredentials: true,
    })

    .then((res) => {
      if (res.status === 200) {
        return res.data.lookupdata;
      } else {
      }
    })
    .catch((error) => {
      console.log(error);
    });
};
export default PublicationPromiseOptions;
