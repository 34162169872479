import React, { useState, useEffect } from "react";
import { Table, Row, Col, Button, Alert, Card } from "react-bootstrap";
import DataTable, { pagination } from "react-data-table-component";
import Sidebar from "../components/SidebarRegistration";
import _ from "underscore";
import BookmarkedComponent from "./BookemarkedComponent";

const dateFormat = require("dateformat");
const axios = require("axios");
const { customStyles } = require("./CMS/tableStyle");

const Bookmarked = (props) => {
  const [currentTotal, setTotal] = useState(0);
  const [hasError, setErrors] = useState(false);
  const [data, setData] = useState([]);
  const [userid, setUserid] = useState(0);
  const [loading, setLoading] = useState(true);
  const [currentPerpage, setPerpage] = useState(10);
  const [currentSort, setSort] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [togglerefresh, setTogglerefresh] = useState(false);
  const [isloggedin, setLoggedin] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setLoading(true);
    setCurrentPage(page);
  };
  const handlePerRowsChange = (perPage, page) => {
    setLoading(true);
    setPerpage(perPage);
    setCurrentPage(page);
  };

  const handleSearch = (event, document_id) => {
    props.history.push({
      pathname: "/r/" + document_id,
    });
    event.preventDefault();
  };

  return (
    <main role="main" className="main mt-2">
      <Row></Row>
      {hasError ? (
        <Alert variant="danger">Request Failed.Access Denied!</Alert>
      ) : (
        <Row>
          <Col md="3">
            <Sidebar {...props} />
          </Col>
          <Col md="9">
            <BookmarkedComponent
              currentPage={currentPage}
              currentPerpage={currentPerpage}
              currentTotal={currentTotal}
              data={data}
              error={hasError}
              loading={loading}
              setData={setData}
              setErrors={setErrors}
              setLoading={setLoading}
              setLoggedin={setLoggedin}
              setTotal={setTotal}
              handlePerRowsChange={handlePerRowsChange}
              handlePageChange={handlePageChange}
              handleSearch={handleSearch}
            />
          </Col>
        </Row>
      )}
    </main>
  );
};

export default Bookmarked;
