import React from "react";
import { Nav, Navbar, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import _ from "underscore";
import { useAuth } from "../context/auth";
const store = require("store");

const SidebarRegistration = (props) => {
  const { authTokens, setAuthTokens } = useAuth();
  const isPro = !_.isUndefined(props.isPro) ? props.isPro : false;
  var isLogin = false;
  if (authTokens) isLogin = authTokens.loggedin;
  const { location } = props;
  let active_link = !_.isUndefined(location.pathname) ? location.pathname : "";
  return (
    <Row className="ml-0 mr-1" style={{ zIndex: "2" }}>
      {isLogin || isPro ? (
        <div className="account-sidebar sidebar-sticky col-md-12 d-md-block bg-light sidebar nav">
          <div
            className={active_link == "/Inbox" ? "nav-item active" : "nav-item"}
          >
            <Link
              className="nav-link"
              to="/Inbox"
              eventkey="inbox"
              data-rb-event-key="/Inbox"
              title="Latest"
            >
              <i className="fa fa-envelope feather"></i> Latest{" "}
            </Link>
          </div>
          <div
            className={
              active_link == "/History" ? "nav-item active" : "nav-item"
            }
          >
            <Link
              className="nav-link"
              to="/History"
              eventkey="history"
              data-rb-event-key="/History"
              title="History"
            >
              <i className="fa fa-history feather"></i> History
            </Link>
          </div>
          <div
            className={
              active_link == "/Bookmarked" ? "nav-item active" : "nav-item"
            }
          >
            <Link
              className="nav-link"
              to="/Bookmarked"
              eventkey="starred"
              data-rb-event-key="/Bookmarked"
              title="Bookmarked"
            >
              <i className="fa fa-star feather"></i> Bookmarked
            </Link>
          </div>

          <div
            className={
              active_link == "/Profile" ? "nav-item active" : "nav-item"
            }
          >
            <Link
              className="nav-link"
              to="/Profile"
              eventkey="profile"
              data-rb-event-key="/Profile"
              title="Edit Profile"
            >
              <i className="fa fa-edit feather"></i> Edit Profile
            </Link>
          </div>
          <div
            className={
              active_link == "/Subscription" ? "nav-item active" : "nav-item"
            }
          >
            <Link
              className="nav-link"
              to="/Subscription"
              eventkey="subscription"
              data-rb-event-key="/Subscription"
              title="Upgrade Pro"
            >
              {!isPro || document.body.className !== "pro-user" ? (
                <span className="pro-label">
                  <i className="fa fa-dollar feather"></i> Upgrade Pro
                </span>
              ) : (
                <span className="user-label">
                  <i className="fa fa-dollar feather"></i> Renew Pro
                </span>
              )}
            </Link>
          </div>

          <div
            className={
              active_link == "/Changepassword" ? "nav-item active" : "nav-item"
            }
          >
            <Link
              className="nav-link"
              to="/Changepassword"
              eventkey="changepassword"
              data-rb-event-key="/Changepassword"
              title="Change password"
            >
              <i className="fa fa-asterisk feather"></i> Change Password
            </Link>
          </div>
          <div
            className={
              active_link == "/Logout" ? "nav-item active" : "nav-item"
            }
          >
            <Link
              className="nav-link"
              to="/Logout?remember=false"
              eventkey="logout"
              data-rb-event-key="/Logout"
              title="Logout"
            >
              <i className="fa fa-power-off feather"></i> Logout
            </Link>
          </div>
        </div>
      ) : (
        ""
        // <div className="sidebar-sticky col-md-12 d-none d-md-block bg-light sidebar nav"></div>
      )}
    </Row>
  );
};

export default SidebarRegistration;
