import React from "react";
import { Badge, Row, Col, Card } from "react-bootstrap";
import Sidebar from "../components/SidebarRegistration";

const Checkoutcancel = (props) => {
  return (
    <main role="main" className="main mt-2">
      <Row></Row>

      <Row>
        <Col md="3">
          <Sidebar {...props} />
        </Col>
        <Col md="6">
          <Card>
            <Card.Body>
              <Card.Header className="label label-success">
                <h2>Upgrade</h2> to Trip<Badge variant="info">PRO</Badge>
              </Card.Header>
              <p>Payment Failed</p>
              <a href="/Subscription">Go Back to Payment</a>
            </Card.Body>
          </Card>
          {/* )} */}
        </Col>
      </Row>
    </main>
  );
};

export default Checkoutcancel;
