import React from "react";
import { Form, Button, Modal, Row, Col, Table } from "react-bootstrap";
import DataTable, { pagination } from "react-data-table-component";
import _ from "underscore";

const { customStyles } = require("./tableStyle");

const InstitutionReportMdl = ({
  institution,
  statsData,
  statsDataTotal = 0,
  handleClose,
  handleInstitutionPageChange,
  show,
}) => {
  if (!statsData) {
    return <div>Processing results...</div>; //<div className="spinner-border text-info"></div>;
  }
  var stats = !_.isUndefined(statsData.data) ? statsData.data : [];
  const columns_institutions = [
    {
      name: "Period",
      selector: "dt",
      sortable: false,
      wrap: true,
      maxWidth: "100px",
    },
    {
      name: "Users",
      selector: "uid",
      sortable: true,
      maxWidth: "600px",
      wrap: true,
    },
    {
      name: "Sessions",
      selector: "sid",
      sortable: true,
      maxWidth: "600px",
      wrap: true,
    },
    {
      name: "Searches",
      selector: "cid",
      sortable: true,
      maxWidth: "30px",
      wrap: true,
    },
    {
      name: "Documents",
      selector: "documents_viewed",
      sortable: true,
      maxWidth: "400px",
      wrap: true,
    },
  ];
  return (
    <Modal show={show} onHide={handleClose} animation={false} size="xl">
      <Form width="100%">
        <Modal.Header closeButton>
          <Modal.Title className="text-success">
            <i className="fa fa-book"></i> Usage Report -{" "}
            {!_.isUndefined(institution.name) ? institution.name : null}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DataTable
            striped={true}
            customStyles={customStyles}
            columns={columns_institutions}
            data={stats}
            highlightOnHover
            //defaultSortField="pubication"

            pagination
            paginationServer
            paginationTotalRows={statsDataTotal}
            //  onChangeRowsPerPage={handleInstitutionPageChange}
            onChangePage={handleInstitutionPageChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            <i className="fa fa-times"></i> Close
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default InstitutionReportMdl;
