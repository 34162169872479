import React, { useState, useEffect } from "react";
import _ from "underscore";
import { useAuth } from "./../context/auth";
import { Link } from "react-router-dom";
import Search from "./search/Search";
import Logosvg from "./assets/svg/Logo";
import LogoProsvg from "./assets/svg/LogoPro";
import Navbarnetworksvg from "./assets/svg/Navbarnetwork";
import { checkTokensStorage } from "./../context/checkTokens";
import { isMobile } from "react-device-detect";
import { userBanner } from "./../context/userBanner";
import LoginMdl from "./LoginMdl";
import SignupMdl from "./SignupMdl";
import ErrorMdl from "./../components/errorMdl";

// import { handleLoginRemember } from "./../context/rememberMe";

const axios = require("axios");
const store = require("store");

const NavigationBar = (props) => {
  const [loginshow, setLoginShow] = useState(false);
  const [signupshow, setSignupShow] = useState(false);
  const [proshow, setProShow] = useState(false);
  const [hasLogin, setNothing] = useState(false);
  const [hasError, setErrors] = useState(false);
  const [errorshow, setErrorShow] = useState(false);
  // const [fullname, setFullname] = useState("");
  // const [isLogin, setLogin] = useState(false);
  // const [isPro, setProUser] = useState(false);
  // const { setAuthTokens, authTokens } = useAuth();
  const [toggleMobile, setMobile] = useState(true);

  var loginObj = checkTokensStorage();
  /**
   * handle Mobile
   */
  function handleMobile() {
    if (document.body.classList.contains("admin-page"))
      document.body.classList.remove("admin-page");
    else document.body.classList.add("admin-page");
    if (document.body.classList.contains("mobile-off-canvas--is-visible")) {
      document.body.classList.remove("mobile-off-canvas--is-visible");
    } else {
      document.body.classList.add("mobile-off-canvas--is-visible");
    }
  }
  let componentMounted = true; // (3) component is mounted
  useEffect(() => {
    // var loginObj = checkTokensStorage(authTokens);
    // console.log("nva ", loginObj);
    // setLogin(loginObj.chkLogin);
    // setProUser(loginObj.chkPro || props.isPro);

    if (componentMounted) {
      if (isMobile) document.body.classList.add("hide-account-btn");
    }
    // userBanner(props.isPro || isPro || loginObj.chkPro);
    return () => {
      // This code runs when component is unmounted
      componentMounted = false; // (4) set it to false if we leave the page
    };
  }, []);

  userBanner(props.isPro);
  let active_link = !_.isUndefined(window.location.pathname)
    ? window.location.pathname
    : "";
  return (
    <nav className="navbar navbar-expand-lg mb-0">
      <div id="navbar-bg" className="navbar--bg" style={{ opacity: "0.3" }}>
        <Navbarnetworksvg />
      </div>
      <LoginMdl
        props={props}
        setLoginShow={setLoginShow}
        setSignupShow={setSignupShow}
        setErrorShow={setErrorShow}
        setErrors={setErrors}
        show={loginshow}
      />
      <SignupMdl
        props={props}
        setLoginShow={setLoginShow}
        setSignupShow={setSignupShow}
        setErrorShow={setErrorShow}
        setErrors={setErrors}
        show={signupshow}
      />

      <ErrorMdl error={hasError} setErrorShow={setErrorShow} show={errorshow} />

      <div className="navbar--brand-and-navigation">
        <Link className="navbar--brand" to="/Home">
          <div className="navbar--brand__free">
            <Logosvg />
          </div>
          <div className="navbar--brand__pro">
            <LogoProsvg />
          </div>
        </Link>

        <div className="navbar--navigation">
          <div className="mobile_navigation_wrapper">
            <div className="mobile_navigation mobile_navigation__admin">
              <ul className="navbar-nav navbar-nav__admin-nav navbar-nav__my_account">
                <li className="nav-item">
                  <Link
                    className={
                      active_link == "/Profile"
                        ? "nav-link btn btn-admin btn-primary"
                        : "nav-link btn btn-admin"
                    }
                    title="Profile"
                    to="/Profile"
                  >
                    <i className="fas fa-user-circle"></i>
                    &nbsp; My Account
                  </Link>
                </li>
              </ul>
            </div>

            <div className="mobile_navigation mobile_navigation__site_nav">
              <div className="mobile-menu-toggle">
                <button
                  className="navbar-btn js-menu-toggle btn btn-primary navbar-dark"
                  type="button"
                  data-toggle=""
                  onClick={(e) => {
                    setMobile(!toggleMobile);
                    handleMobile();
                  }}
                >
                  <span className="navbar-toggler-icon"></span>
                  Menu
                </button>
              </div>
            </div>
          </div>

          <div className="desktop_navigation desktop_navigation__site-nav">
            <ul className="navbar-nav navbar-nav__main-nav">
              <li
                className={
                  active_link == "/About" ? "nav-item active" : "nav-item"
                }
              >
                <Link
                  className={
                    active_link == "/About"
                      ? "nav-link btn btn-primary"
                      : "nav-link"
                  }
                  to="/About"
                  title="About"
                >
                  About
                </Link>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://blog.tripdatabase.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Blog"
                >
                  Blog <i className="fa fa-external-link-alt"></i>
                </a>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link pro-button"
                  to="/Why"
                  title="Why go Pro"
                >
                  Why Go Pro?
                </Link>
              </li>
            </ul>
          </div>

          <div className="desktop_navigation desktop_navigation__admin">
            <div className="navbar-divider"></div>

            {props.isLoggedin ? (
              <ul className="navbar-nav navbar-nav__admin-nav navbar-nav__my_account">
                <li className="nav-item">
                  <Link
                    className={
                      active_link == "/Profile"
                        ? "nav-link btn btn-admin btn-primary"
                        : "nav-link btn btn-admin"
                    }
                    to="/Profile"
                    title="My Account"
                  >
                    <i className="fas fa-user-circle"></i>
                    &nbsp; My Account
                  </Link>
                </li>
              </ul>
            ) : (
              <ul className="navbar-nav navbar-nav__admin-nav navbar-nav__sign-up-and-login">
                <li className="nav-item">
                  <Link
                    id="nav-signup"
                    className={
                      active_link == "/Registration"
                        ? "nav-link btn btn-primary"
                        : "nav-link"
                    }
                    to="/Registration"
                    title="Sign up"
                    onClick={(e) => {
                      setErrors("");
                      setSignupShow(!signupshow);
                      setLoginShow(false);
                      e.preventDefault();
                    }}
                  >
                    Sign Up
                  </Link>
                </li>

                {loginObj.chkInstitution.oa_login === true ? (
                  // <>
                  //   <li className="nav-item">
                  //     <Link to="/Logout" className="nav-link" title="Logout">
                  //       Logout
                  //     </Link>
                  //   </li>
                  <li className="nav-item">
                    <Link
                      id="nav-login"
                      className={
                        active_link == "/Login" || active_link == "/Home"
                          ? "nav-link btn btn-primary"
                          : "nav-link"
                      }
                      to="/Login"
                      title="Login"
                      onClick={(e) => {
                        setErrors("");
                        setLoginShow(!loginshow);
                        setSignupShow(false);
                        e.preventDefault();
                      }}
                    >
                      Trip Login
                    </Link>
                  </li>
                ) : (
                  // </>
                  <li className="nav-item">
                    <Link
                      id="nav-login"
                      className={
                        active_link == "/Login" || active_link == "/Home"
                          ? "nav-link btn btn-primary"
                          : "nav-link"
                      }
                      to="/Login"
                      title="Login"
                      onClick={(e) => {
                        setErrors("");
                        setLoginShow(!loginshow);
                        setSignupShow(false);
                        e.preventDefault();
                      }}
                    >
                      Login
                    </Link>
                  </li>
                )}
              </ul>
            )}
          </div>
        </div>
      </div>

      <div id="navbar--search" className="navbar--search">
        <div className="navbar--search--homepage-intro">
          <h1>
            <span className="bold">Trip medical database</span>
            <br></br>A smart, fast tool to find high quality clinical research
            evidence
          </h1>
        </div>
        <Search
          {...props}
          isPro={!_.isUndefined(props.isPro) ? props.isPro : false}
        />
      </div>
    </nav>
  );
};

export default NavigationBar;
