import React from "react";

export default () => (
  // <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1815 579">
  //   <g id="dots_new">
  //     <g>
  //       <path
  //         d="M1556.38,91.86a10,10,0,1,0-6.06,12.78,10,10,0,0,0,6.06-12.78h0"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M1664.89,279.82a6.5,6.5,0,1,0-4,8.31,6.51,6.51,0,0,0,4-8.31"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M1736.84,451.63a10,10,0,1,0-6.69,12.47l.47-.16a9.77,9.77,0,0,0,6.22-12.31"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M1764.62,359a9.5,9.5,0,1,0-5.76,12.15,9.5,9.5,0,0,0,5.76-12.15"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M1704,545.6a9.5,9.5,0,1,0,5.76-12.13h0A9.5,9.5,0,0,0,1704,545.6"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M1339.73,26.4a11.5,11.5,0,1,0,7-14.7h0a11.5,11.5,0,0,0-7,14.69"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M1574.18,195.42a7.5,7.5,0,1,0,4.56-9.59,7.51,7.51,0,0,0-4.56,9.59"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M1619.63,212.64a8,8,0,1,0,4.91-10.19h0a8,8,0,0,0-4.88,10.18"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M1624.29,484.38a13.5,13.5,0,1,0-8.18,17.25,13.49,13.49,0,0,0,8.18-17.25v0"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M1585.52,331a12.5,12.5,0,1,0-7.56,16h0a12.51,12.51,0,0,0,7.56-16"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M1565.68,584.93a9.5,9.5,0,1,0-5.74,12.15,9.5,9.5,0,0,0,5.74-12.15v0"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M956.21,513.7A13.5,13.5,0,1,0,948,531h0a13.5,13.5,0,0,0,8.17-17.25"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M1377.15,44.89a10.5,10.5,0,1,0,6.36-13.42h0a10.5,10.5,0,0,0-6.36,13.42"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M1477.9,202.17a11.5,11.5,0,1,0,7-14.69h0a11.49,11.49,0,0,0-7,14.68h0"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M1287,128.56a8,8,0,1,0-4.85,10.22h0a8,8,0,0,0,4.85-10.22"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M1525.68,211.13a10,10,0,1,0-6,12.78h0a10,10,0,0,0,6-12.78"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M1800.7,226.7a8,8,0,1,0-4.9,10.2h0a8,8,0,0,0,4.89-10.19"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M1718.63,114.84a6.06,6.06,0,1,0-3.72,7.72h0a6.06,6.06,0,0,0,3.7-7.72"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M1192.81,14.35a6.05,6.05,0,1,0-3.71,7.72h0a6,6,0,0,0,3.7-7.71"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M1245.13,61.75a12,12,0,1,0-7.26,15.34h0a12,12,0,0,0,7.26-15.34"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M1243,510.55a12,12,0,1,0-7.26,15.34h0a12,12,0,0,0,7.26-15.34"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M1454.87,339.93a8.5,8.5,0,1,0-5.15,10.87,8.5,8.5,0,0,0,5.15-10.87"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M1559.91,462.37a8.5,8.5,0,1,0-5.15,10.87,8.5,8.5,0,0,0,5.15-10.87"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M1536.17,461.49a12,12,0,1,0-7.27,15.35,12,12,0,0,0,7.27-15.35"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M1364.55,452.83a12.5,12.5,0,1,0,7.58-16h0a12.52,12.52,0,0,0-7.57,16"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M1443.11,262.37a11.5,11.5,0,1,0,7-14.7h0a11.49,11.49,0,0,0-7,14.68v0"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M1376.84,359.29a8.5,8.5,0,1,0-5.16,10.87,8.51,8.51,0,0,0,5.16-10.87h0"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M1804.52,33.08a8.5,8.5,0,1,0-10.87-5.14,8.49,8.49,0,0,0,10.87,5.14h0"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M1321.65,142.19a9.5,9.5,0,1,0-5.75,12.15,9.49,9.49,0,0,0,5.75-12.15h0"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M1160.26,139.3a13.5,13.5,0,1,0-8.17,17.25h0a13.5,13.5,0,0,0,8.17-17.25"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M1262.75,273.13a11.5,11.5,0,1,0-14.7-7h0a11.5,11.5,0,0,0,14.69,7"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M1175.64,313.25a11.5,11.5,0,1,0-7,14.71,11.5,11.5,0,0,0,7-14.71v0"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M1310.18,580.06a12,12,0,1,0-7.27,15.34h0a12,12,0,0,0,7.27-15.33"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M1168.54,537.26a9.28,9.28,0,1,0-5.63,11.86h0a9.28,9.28,0,0,0,5.62-11.86"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M1453.22,585.22a12,12,0,1,0,7.26-15.34h0a12,12,0,0,0-7.26,15.34"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M1730.88,149.56a8.5,8.5,0,1,0-5.15,10.87,8.5,8.5,0,0,0,5.15-10.87h0"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M1787.57,22.65a9.5,9.5,0,1,0-5.76,12.15,9.5,9.5,0,0,0,5.76-12.15v0"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M1083.69,63.11a9.5,9.5,0,1,0-12.14-5.75h0a9.48,9.48,0,0,0,12.12,5.75h0"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M816,566.53a8,8,0,1,0-10.24-4.85h0A8,8,0,0,0,816,566.53h0"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M1135.56,484.35a8,8,0,1,0-10.25-4.85h0a8,8,0,0,0,10.24,4.86h0"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M1502.93,41.59a8,8,0,1,0-10.25-4.85h0a8,8,0,0,0,10.23,4.85h0"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M1071.38,556.65a8,8,0,1,0-10.25-4.85h0a8,8,0,0,0,10.23,4.85h0"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M1073.72,528.4a11.67,11.67,0,1,0-14.92-7.06h0a11.66,11.66,0,0,0,14.89,7.07h0"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M881.43,423.85A8,8,0,1,0,871.18,419h0a8,8,0,0,0,10.23,4.86h0"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M1283.24,458.07a8,8,0,1,0-10.24-4.85h0a8,8,0,0,0,10.23,4.85h0"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M1616.52,51.43a8,8,0,1,0-10.25-4.85h0a8,8,0,0,0,10.23,4.85h0"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M1064.71,160.15a8,8,0,1,0-4.91,10.19h0a8,8,0,0,0,4.88-10.18"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M1065.86,71.06a10.5,10.5,0,1,0-6.37,13.41h0a10.49,10.49,0,0,0,6.36-13.41"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M882.35,210.14A10.5,10.5,0,1,0,876,223.56h0a10.5,10.5,0,0,0,6.37-13.41"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M955.91,77.48a8.45,8.45,0,1,0-5.13,10.79h0a8.44,8.44,0,0,0,5.12-10.78"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M693.39,573.63a8.45,8.45,0,1,0-5.13,10.78h0a8.44,8.44,0,0,0,5.12-10.78"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M1182.83,398.82a12.5,12.5,0,1,0-7.57,16h0a12.48,12.48,0,0,0,7.57-16h0"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M1061.28,394.44a7.5,7.5,0,0,0-9.58-4.55h0a7.5,7.5,0,0,0-4.53,9.59h0a7.49,7.49,0,0,0,9.57,4.54h0a7.49,7.49,0,0,0,4.56-9.56h0"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M1130.94,390.27a10,10,0,1,0-6.06,12.78h0a10,10,0,0,0,6.06-12.78"
  //         fill="#87cac4"
  //       ></path>
  //       <path
  //         d="M1149.73,321.14a10.5,10.5,0,1,0-6.36,13.42h0a10.51,10.51,0,0,0,6.36-13.42"
  //         fill="#87cac4"
  //       ></path>
  //     </g>
  //   </g>
  //   <g id="lines_1_new">
  //     <polyline
  //       points="1164.82 317.13 1258.89 262.3 1147.55 143.84 1057.16 162.79 1139.84 324.67 1147.55 139.25 1279.5 130.34 1258.89 266.98 1368.83 362.15 1139.84 328.63 1171.06 403.02 1121.52 393.63 1134.04 477.54 1280.55 450.52 1054.22 396.95 1069.79 518.24 1231.73 514.59 1159.79 541.08 1171.06 408.29 1374.48 448.63 1446.87 345.02 1453.94 258.5 1312.71 143.84 1488.72 198.31 1516.26 214.49 1524.87 465.54 1573.75 335.18 1551.9 465.24 1613.91 488.92 1556.96 588.14 1498.28 607.28 1464.52 581.18 1554.94 584.09 1635.18 617.5 1712.91 542.42 1727.3 453.53 1755.67 362.15 1656.48 282 1627.18 210 1722.41 152.42 1712.91 116.85 1581.25 192.9 1546.96 95.22 1793.15 229.35 1856.5 145.4 1921.15 456.73 1866.66 510.41 1955.44 551.92 1805.65 625.5 1708.35 540.11 1611.58 483.36 1722.41 454.52 1577.55 335.18 1298.88 584.09 1390.12 635.93 1467.59 576.47 1231.73 510.41 1187.1 605.81 1068.68 549.1 870.71 601.03 943.5 518.24 815.37 558.98 682.8 576.46 878.73 419.06 870.71 213.66 1055.97 74.58 946.17 80.31 1080.49 53.78 1233.83 64.08 1187.1 16.35 995.84 -45.91 1350.56 22.54 1387.04 42.06 1308.86 148.15 1500.23 35.86 1613.82 44.66 1648.98 -28.39 1778.62 25.08 1799.25 25.08 1862.56 -10.39"
  //       fill="none"
  //       stroke="#87cac4"
  //       strokeMiterlimit="10"
  //     ></polyline>
  //   </g>
  //   <g id="lines_2_new">
  //     <polyline
  //       points="873.45 213.66 1139.84 325.73 1054.22 396.95 869.68 214.66 946.83 81.64 993.83 -45.91"
  //       fill="none"
  //       stroke="#87cac4"
  //       strokeMiterlimit="10"
  //     ></polyline>
  //     <polyline
  //       points="1297.78 584.09 1280.55 450.52 1231.73 514.59 1373.82 450.52 1283.59 450.52 1367.79 361.25 1448.97 342.79 1255.91 264.03 1171.06 403.02"
  //       fill="none"
  //       stroke="#87cac4"
  //       strokeMiterlimit="10"
  //     ></polyline>
  //     <polyline
  //       points="1611.58 488.92 1754.69 362.15 1551.9 465.24 1556.74 588.14 1523.68 465.54 1464.52 579 1376.33 448.63 1527.01 465.54"
  //       fill="none"
  //       stroke="#87cac4"
  //       strokeMiterlimit="10"
  //     ></polyline>
  //     <polyline
  //       points="995.84 -45.91 1080.49 53.78 1055.97 74.58 1147.27 145.87 1233.83 64.08 1279.5 130.34 1350.56 22.54 1308.86 148.15 1258.89 262.3 1452.52 257.35 1488.72 198.31 1546.96 95.22 1500.23 35.86 1648.98 -28.39 1712.91 116.85 1550.76 97.29 1627.18 210 1582.21 194.27 1573.75 334.29 1515.26 214.49 1658.77 282 1573.08 339.17"
  //       fill="none"
  //       stroke="#87cac4"
  //       strokeMiterlimit="10"
  //     ></polyline>
  //   </g>
  //   <g id="lines_3_new">
  //     <polyline
  //       points="943.5 518.24 878.59 415.53 1054.22 396.95 947.94 517.41 1069.79 518.24 1068.68 549.1 1159.79 541.08 1073.42 518.24 1121.52 393.63"
  //       fill="none"
  //       stroke="#87cac4"
  //       strokeMiterlimit="10"
  //     ></polyline>
  //     <polyline
  //       points="873.45 213.66 1057.16 162.79 1055.97 74.58"
  //       fill="none"
  //       stroke="#87cac4"
  //       strokeMiterlimit="10"
  //     ></polyline>
  //     <polyline
  //       points="1080.49 53.78 1187.1 16.35 1350.56 22.54 1500.23 35.86 1387.04 42.06 1488.72 198.31"
  //       fill="none"
  //       stroke="#87cac4"
  //       strokeMiterlimit="10"
  //     ></polyline>
  //     <polyline
  //       points="1546.96 95.22 1613.82 44.66 1712.91 116.85 1778.62 25.08 1793.15 229.35 1755.67 362.15 1920.69 454.52 1727.3 453.53 1866.66 510.41"
  //       fill="none"
  //       stroke="#87cac4"
  //       strokeMiterlimit="10"
  //     ></polyline>
  //     <polyline
  //       points="1139.84 324.67 1164.82 317.13 1147.55 143.84"
  //       fill="none"
  //       stroke="#87cac4"
  //       strokeMiterlimit="10"
  //     ></polyline>
  //     <line
  //       x1="1279.5"
  //       y1="130.34"
  //       x2="1312.71"
  //       y2="143.84"
  //       fill="none"
  //       stroke="#87cac4"
  //       strokeMiterlimit="10"
  //     ></line>
  //     <line
  //       x1="1452.52"
  //       y1="257.35"
  //       x2="1573.75"
  //       y2="334.29"
  //       fill="none"
  //       stroke="#87cac4"
  //       strokeMiterlimit="10"
  //     ></line>
  //   </g>
  // </svg>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2644 1886">
    <g id="lines">
      <g>
        <polyline
          points="2122.55 516.45 1861.29 428.19 1814.56 380.46 1967.89 390.76 2088.22 368.23 1992.41 369.96 2177.68 230.88"
          fill="none"
          stroke="#30b4ad"
          strokeMiterlimit="10"
          style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
        ></polyline>
        <polyline
          points="1233.71 72.39 1391.9 162.54 1421.2 234.54 1325.97 292.12 1335.47 327.69 1467.13 251.64 1501.42 349.32 1255.23 215.19 1191.88 299.14 1086.01 354.47 1185.83 454.93 1249.13 419.46 1269.77 419.46 1399.4 472.93 1434.57 399.88 1548.15 408.68 1739.53 296.39 1661.35 402.48 1697.83 422"
          fill="none"
          stroke="#30b4ad"
          strokeMiterlimit="10"
          style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
        ></polyline>
        <polyline
          points="1297.35 1417.89 1388.59 1469.73 1466.06 1410.27 1230.2 1344.21 1185.57 1439.61 1067.15 1382.9 869.18 1434.83 941.97 1352.04 813.84 1392.78 681.27 1410.26 877.2 1252.86"
          fill="none"
          stroke="#30b4ad"
          strokeMiterlimit="10"
          style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
        ></polyline>
        <polyline
          points="2173.62 1367.53 1865.13 1344.21 1953.91 1385.72 1804.12 1459.3 1706.82 1373.91 1610.05 1317.16 1720.88 1288.32"
          fill="none"
          stroke="#30b4ad"
          strokeMiterlimit="10"
          style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
        ></polyline>
        <polyline
          points="1550.37 1299.04 1612.38 1322.72 1555.43 1421.94 1496.75 1441.08 1462.99 1414.98 1553.41 1417.89 1633.65 1451.3 1711.38 1376.22 1725.77 1287.33"
          fill="none"
          stroke="#30b4ad"
          strokeMiterlimit="10"
          style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
        ></polyline>
        <polyline
          points="1608.52 34.52 1594.44 186.04 1735.67 300.7 1559.66 246.23 1532.13 230.05"
          fill="none"
          stroke="#30b4ad"
          strokeMiterlimit="10"
          style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
        ></polyline>
        <polyline
          points="1052.69 1230.75 1068.26 1352.04 1230.2 1348.39 1158.26 1374.88 1169.53 1242.09 1372.95 1282.43"
          fill="none"
          stroke="#30b4ad"
          strokeMiterlimit="10"
          style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
        ></polyline>
        <polyline
          points="1169.53 1236.82 1119.99 1227.43 1132.51 1311.34 1279.02 1284.32"
          fill="none"
          stroke="#30b4ad"
          strokeMiterlimit="10"
          style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
        ></polyline>
        <line
          x1="2174.93"
          y1="230.88"
          x2="2057.54"
          y2="152.81"
          fill="none"
          stroke="#30b4ad"
          strokeMiterlimit="10"
        ></line>
        <polyline
          points="1702.6 71.29 1608.5 42.5 1478.5 66.5"
          fill="none"
          stroke="#30b4ad"
          strokeMiterlimit="10"
          style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
        ></polyline>
        <polyline
          points="1550.37 1299.04 1555.21 1421.94 1522.15 1299.34 1462.99 1412.8 1374.8 1282.43 1525.48 1299.34"
          fill="none"
          stroke="#30b4ad"
          strokeMiterlimit="10"
          style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
        ></polyline>
        <polyline
          points="1987.01 -35.44 2112.5 92.5 2276.99 88.98 2202.01 145.47 2361.48 173.81 2368.98 95.93 2473.71 93.57 2522.26 38.2 2581.04 88.98 2499.49 169.05 2562.04 215.47 2509.41 298.67 2388.63 256.2 2406.08 384.07 2327.41 362.47 2210.01 395.31 2088.22 368.23 2174.93 230.88 1991.22 281.75 1992.41 369.96"
          fill="none"
          stroke="#30b4ad"
          strokeMiterlimit="10"
          style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
        ></polyline>
        <polyline
          points="1548.15 408.68 1661.35 402.48 1559.66 246.23"
          fill="none"
          stroke="#30b4ad"
          strokeMiterlimit="10"
          style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
        ></polyline>
        <polyline
          points="1967.89 390.76 1861.29 428.19 1697.83 422"
          fill="none"
          stroke="#30b4ad"
          strokeMiterlimit="10"
          style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
        ></polyline>
        <polyline
          points="1501.42 349.32 1434.57 399.88 1335.47 327.69 1269.77 419.46 1255.23 215.19 1233.71 72.39 1350.12 -35.44 1608.5 42.5 1816.49 -55.39 1883.57 127.41 1789.49 182.24 1900.84 300.7 1991.22 281.75 2057.55 153.87 1900.84 305.29 1768.88 314.2 1789.49 177.56 1701.56 70.39 2112.5 92.5 2057.55 153.87 1883.57 127.41 1900.84 300.7"
          fill="none"
          stroke="#30b4ad"
          strokeMiterlimit="10"
          style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
        ></polyline>
        <line
          x1="1768.88"
          y1="314.2"
          x2="1735.67"
          y2="300.7"
          fill="none"
          stroke="#30b4ad"
          strokeMiterlimit="10"
        ></line>
        <line
          x1="1595.86"
          y1="187.19"
          x2="1477.63"
          y2="71.25"
          fill="none"
          stroke="#30b4ad"
          strokeMiterlimit="10"
        ></line>
        <polyline
          points="1119.99 1227.43 1045.2 1106.07 937.63 1157.31 811.77 1105.7 865.01 1042.47 735.63 935.31 872.63 842.31 728.01 720.47 845.48 749.7 872.63 647.31 675.63 574.31 841.48 522.7 770.43 516.64 831.01 441.47 726.01 354.47 811.48 234.31 921.01 240.03 973.63 349.32 1091.63 187.19 1191.88 299.14 1185.83 454.93 1255.23 215.19 1097.5 65.5 921.01 240.03 981.23 46.84 1086.01 354.47 1004.01 482.81 973.63 349.32 811.48 234.31 831.01 441.47 921.01 240.03 823.01 99.52 692.01 207.19 588.65 323.7 726.01 354.47 770.43 516.64 596.5 448.5 675.63 574.31 728.01 720.47 591.33 829.81 679.48 909.7 735.63 935.31 743.63 1160.67 811.77 1105.7 562.26 1004.81 865.01 1042.47 872.63 842.31 845.48 749.7 591.5 829.5"
          fill="none"
          stroke="#30b4ad"
          strokeMiterlimit="10"
          style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
        ></polyline>
        <polyline
          points="1067.15 1382.9 1109.99 1630.88 990.48 1476.7 1243.17 1479.47 1185.57 1439.61 1296.25 1417.89 1279.02 1284.32 1230.2 1348.39 1372.29 1284.32 1282.06 1284.32"
          fill="none"
          stroke="#30b4ad"
          strokeMiterlimit="10"
          style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
        ></polyline>
        <polyline
          points="1594.38 1601.13 1633.65 1451.3 1804.12 1459.3 1720.93 1587.86 1548.17 1498.7 1388.59 1469.73 1409.13 1587.86 1496.75 1441.08"
          fill="none"
          stroke="#30b4ad"
          strokeMiterlimit="10"
          style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
        ></polyline>
        <polyline
          points="1572.01 1164.47 1839.63 1199.64 1688.05 1017.31 1725.77 1287.33"
          fill="none"
          stroke="#30b4ad"
          strokeMiterlimit="10"
          style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
        ></polyline>
        <polyline
          points="517.97 796.21 591.33 829.81 675.63 574.31 726.01 354.47 692.01 207.19 831.01 441.47 973.63 349.32"
          fill="none"
          stroke="#30b4ad"
          strokeMiterlimit="10"
          style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
        ></polyline>
        <polyline
          points="1958.39 536.47 1830.55 566.31 1713.63 743.64 1824.49 847.7 1748.01 892.31 1688.05 1017.31 1907.63 841.31 1914.63 1052.31 1839.63 1199.64 1725.77 1287.33 1523.35 1230.75 1572.01 1164.47 1372.29 1284.32 1523.35 1230.75 1498.17 1122.2 1380.01 1160.67 1225.49 1092.77 1119.99 1227.43 1071.89 1352.04 1158.26 1374.88 1067.15 1382.9 1068.26 1352.04 946.41 1351.21 1052.69 1230.75 877.06 1249.33 941.97 1352.04 990.48 1476.7 904.5 1620.5 789.17 1471.47 869.18 1434.83"
          fill="none"
          stroke="#30b4ad"
          strokeMiterlimit="10"
          style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
        ></polyline>
        <polygon
          points="1572.01 1164.47 1725.77 1287.33 1816.49 1039.81 1914.63 1052.31 1572.01 1164.47"
          fill="none"
          stroke="#30b4ad"
          strokeMiterlimit="10"
        ></polygon>
        <polyline
          points="1548.15 408.68 1576.49 480.7 1697.83 422 1749.63 536.47 1834.01 468.47 1830.55 566.31 1861.28 663.31 1839.63 763.47 1713.63 743.64 1748.01 892.31 1687.01 920.47 1688.05 1017.31 1816.49 1039.81 1907.63 841.31 1958.39 536.47 1996.63 657.31 1824.49 847.7 1984.13 989.47 1914.63 1052.31 1961.49 1134.7 1839.63 1199.64 1971.63 1261.31 2173.16 1365.32 1725.77 1287.33 1865.13 1344.21 1711.38 1376.22 1953.91 1385.72 1945.49 1461.7 1804.12 1459.3 1939.43 1620.5 2122.5 1451.5 2079.89 1571.25 2185.17 1631.65 2207.48 1553.8 2337.95 1511.79 2386.48 1637.7 2537.57 1543.93 2410.57 1523.85 2453.57 1435.93 2273.19 1411.12 2342.12 1378.48 2114.01 1270.31 2261.01 1202.06 2207.48 1266.7 2348.18 1206.79 2422.67 1282.81 2613.85 1241.68 2511.55 1298.07 2476.17 1182.35 2376.95 1128.64 2383.9 1035.3 2516.47 947.1 2499.55 1095.65 2568.41 1097.31 2516.47 947.1 2690.93 947.1 2514.55 853.81 2450.49 802.48 2424.71 729.34 2488.26 702.3 2562.04 743.31 2571.45 692.3 2568.13 625.01 2447.67 514.26 2447.67 640.57 2398.08 644.81 2367.54 508.31 2210.01 395.31 2227.01 602.47 2322.18 562.79 2122.55 516.45 2167.63 677.31 2072.5 735.5 1996.63 657.31"
          fill="none"
          stroke="#30b4ad"
          strokeMiterlimit="10"
          style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
        ></polyline>
        <polyline
          points="1633.65 1451.3 1721.32 1590.61 1780.61 1627.97 1804.12 1459.3 1865.13 1344.21"
          fill="none"
          stroke="#30b4ad"
          strokeMiterlimit="10"
          style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
        ></polyline>
        <polyline
          points="1421.2 234.54 1466.17 250.27 1477.63 71.25 1533.13 230.05 1389.62 162.54 1478.31 66.37"
          fill="none"
          stroke="#30b4ad"
          strokeMiterlimit="10"
          style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
        ></polyline>
        <polyline
          points="1739.53 296.39 1789.49 182.24 1595.87 187.19 1559.66 246.23 1501.42 349.32 1548.15 408.68 1399.4 472.93 1335.47 327.69 1497.63 347.25"
          fill="none"
          stroke="#30b4ad"
          strokeMiterlimit="10"
          style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
        ></polyline>
        <polyline
          points="2173.16 1365.32 2122.5 1451.5 1953.91 1385.72 1971.63 1261.31 2064.32 1236.82 1961.49 1134.7 2075.5 1070.5 1984.13 989.47 2072.5 735.5 1907.63 841.31 1996.63 657.31 2122.55 516.45 1967.89 390.76 1992.41 369.96 1901.12 298.67 1814.56 380.46 1768.88 314.2 1697.83 422"
          fill="none"
          stroke="#30b4ad"
          strokeMiterlimit="10"
          style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
        ></polyline>
        <line
          x1="1861.28"
          y1="428.19"
          x2="1834.01"
          y2="468.47"
          fill="none"
          stroke="#30b4ad"
          strokeMiterlimit="10"
        ></line>
        <line
          x1="1967.89"
          y1="390.76"
          x2="1958.39"
          y2="536.47"
          fill="none"
          stroke="#30b4ad"
          strokeMiterlimit="10"
        ></line>
        <polyline
          points="841.5 522.5 831.01 441.47 1004.01 482.81 841.48 522.7 872.63 647.31 952.53 595.31"
          fill="none"
          stroke="#30b4ad"
          strokeMiterlimit="10"
          style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
        ></polyline>
        <polyline
          points="59.49 515.7 90.41 658.48 151.99 713.85 287.48 791.1 433.17 647.47 519.97 801.93 509.23 951.65 562.26 1004.81 735.63 935.31"
          fill="none"
          stroke="#30b4ad"
          strokeMiterlimit="10"
          style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
        ></polyline>
        <polyline
          points="789.17 1471.47 673.43 1602.98 681.27 1410.26 457 1412.5 593.74 1381.69 463.74 1299.69 308.57 1387.93 368.58 1502.07 550.74 1554.69 631.97 1544.22 566.5 1722.5 444.08 1601.07 356.08 1619.39 330.5 1720.5 243.59 1579.07 181.48 1461.7 116.97 1589.21 110.58 1475.07 45.01 1553.47 -35.5 1358.5 108.18 1361.79 224.57 1279.93 181.57 1368.93 407.7 1196.11 563.97 1201.22 517.73 1144.97 436.97 1050.79 542.67 1112.12 743.63 1160.67"
          fill="none"
          stroke="#30b4ad"
          strokeMiterlimit="10"
          style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
        ></polyline>
        <polyline
          points="845.48 749.7 679.48 909.7 743.63 1160.67 556.44 1314.39 681.27 1410.26 789.17 1471.47 813.84 1392.78 877.06 1249.33 726.01 1290.53 743.63 1160.67 777.17 1205.7 937.63 1157.31 1052.69 1230.75 1045.2 1106.07 1225.49 1092.77 1279.02 1284.32 1380.01 1160.67 1372.29 1284.32"
          fill="none"
          stroke="#30b4ad"
          strokeMiterlimit="10"
          style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
        ></polyline>
      </g>
      <path
        d="M281.56,1142.07l-173.15,80.41,116.16,57.45-30.9-152.2H-18.62l137.8-77.51,128-23.87,23.38-59.67L148,971.51l-76.77-7.72L154.9,879.3l115.65,58.94,68.86,3.07L287.48,791.1l171.11,93.18-360.18-80L152,713.85l69.5-67.37,64.05,48.83L90.41,658.48l105.3-83.91,136.74,11.27,100.72,61.63,146.46-71.16L511.91,724.36l60.58-74.66L490.63,533.31l-148.18,3L339.13,469l260.78-19.7-135-62L218.67,357s-.54,123.2-.86,124.37S319.76,293.5,319.76,293.5l-60.5,254.13-74.17-64.18L93.18,405.18-60.5,454.5l120,61.2,136.22,62.07L-43.5,632.5l135,29.07L-33.5,850.5l129-44L152,876.72-21.5,922.5"
        fill="none"
        stroke="#30b4ad"
        strokeMiterlimit="10"
        style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
      ></path>
      <polyline
        points="1939.43 1620.5 1780.61 1627.97 1663.5 1711.5 1594.38 1601.13 1548.17 1498.7 1477.38 1642.7 1271.01 1636.65 1388.59 1469.73 1230.17 1569.63 1243.17 1479.47 1109.99 1630.88 1216.34 1707.08 1225.19 1571.33 1266.87 1636.76"
        fill="none"
        stroke="#30b4ad"
        strokeMiterlimit="10"
        style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
      ></polyline>
      <polyline
        points="789.17 1471.47 794.63 1601.07 904.5 1620.5 1023.26 1556.21 960.5 1708.5 906.22 1624.64"
        fill="none"
        stroke="#30b4ad"
        strokeMiterlimit="10"
        style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
      ></polyline>
      <polyline
        points="93.18 405.18 -35.5 102.11 99.24 205.48 -71.49 327.7 177.08 229.6 319.76 293.5 280.41 142.31 159.63 100.2 299.98 -67.36 392.63 68.31 650.76 88.73 692.01 207.19"
        fill="none"
        stroke="#30b4ad"
        strokeMiterlimit="10"
        style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
      ></polyline>
      <polyline
        points="464.89 387.31 342.45 536.3 433.17 647.47 285.55 695.31 259.26 547.63 217.81 481.33"
        fill="none"
        stroke="#30b4ad"
        strokeMiterlimit="10"
        style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
      ></polyline>
      <polyline
        points="270.55 966.68 401.39 1060.19 247.17 1026.35 147.95 971.51 193.67 1127.73 119.18 1050.22 -72.5 987.5 71.18 963.79"
        fill="none"
        stroke="#30b4ad"
        strokeMiterlimit="10"
        style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
      ></polyline>
      <polyline
        points="193.67 1127.73 108.41 1222.48 -40.23 1279.93 108.18 1361.79 181.57 1368.93 181.48 1461.7 110.58 1475.07 243.59 1579.07 308.57 1387.93 407.7 1196.11 556.44 1314.39 563.97 1201.22 542.96 1118.22 562.26 1004.81"
        fill="none"
        stroke="#30b4ad"
        strokeMiterlimit="10"
        style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
      ></polyline>
      <line
        x1="517.73"
        y1="1144.97"
        x2="281.56"
        y2="1142.07"
        fill="none"
        stroke="#30b4ad"
        strokeMiterlimit="10"
      ></line>
      <polyline
        points="401.39 1060.19 436.97 1050.79 407.7 1196.11 281.56 1142.07 401.39 1060.19 509.23 951.65 339.41 941.31"
        fill="none"
        stroke="#30b4ad"
        strokeMiterlimit="10"
        style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
      ></polyline>
      <line
        x1="509.23"
        y1="951.65"
        x2="679.48"
        y2="909.7"
        fill="none"
        stroke="#30b4ad"
        strokeMiterlimit="10"
      ></line>
      <line
        x1="511.91"
        y1="724.36"
        x2="591.33"
        y2="829.81"
        fill="none"
        stroke="#30b4ad"
        strokeMiterlimit="10"
      ></line>
      <polyline
        points="631.97 1544.22 593.74 1381.69 726.01 1290.53"
        fill="none"
        stroke="#30b4ad"
        strokeMiterlimit="10"
        style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
      ></polyline>
      <polyline
        points="550.74 1554.69 444.08 1601.07 368.58 1502.07 243.59 1579.07 102.5 1784.5 116.97 1589.21 -40.23 1637.91 45.01 1553.47"
        fill="none"
        stroke="#30b4ad"
        strokeMiterlimit="10"
        style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
      ></polyline>
      <polyline
        points="673.43 1602.98 722.5 1787.5 794.63 1601.07"
        fill="none"
        stroke="#30b4ad"
        strokeMiterlimit="10"
        style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
      ></polyline>
      <polyline
        points="1477.38 1642.7 1396.01 1722.5 1409.13 1587.86 1243.17 1479.47"
        fill="none"
        stroke="#30b4ad"
        strokeMiterlimit="10"
        style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
      ></polyline>
      <polyline
        points="1945.49 1461.7 1993.59 1637.7 1939.43 1620.5 1939.43 1701.31"
        fill="none"
        stroke="#30b4ad"
        strokeMiterlimit="10"
        style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
      ></polyline>
      <polygon
        points="2514.55 853.81 2380.99 869.65 2450.49 802.48 2562.04 743.31 2681.95 843.31 2516.47 947.1 2514.55 853.81"
        fill="none"
        stroke="#30b4ad"
        strokeMiterlimit="10"
      ></polygon>
      <polyline
        points="2122.55 516.45 2088.22 368.23 2388.63 256.2 2174.93 230.88 2202.01 145.47 2057.55 153.87"
        fill="none"
        stroke="#30b4ad"
        strokeMiterlimit="10"
        style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
      ></polyline>
      <polyline
        points="2509.41 298.67 2548.76 448.33 2708.88 448.33"
        fill="none"
        stroke="#30b4ad"
        strokeMiterlimit="10"
        style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
      ></polyline>
      <polyline
        points="2473.71 93.57 2499.49 169.05 2276.99 88.98 2155.13 -70.35 2112.5 92.5 2400.01 -70.35 2368.98 95.93 2522.26 38.2 2593.54 -35.44 2696.5 -23.5 2690.93 79.89 2581.04 88.98 2696.5 214.31 2562.04 215.47 2548.76 448.33 2447.67 514.26 2367.54 508.31 2406.08 384.07 2398.08 644.81 2322.18 562.79 2237.48 683.7 2227.01 602.47 2424.71 729.34 2447.67 640.57 2488.26 702.3 2568.13 625.01 2678.18 669.81 2571.45 692.3 2450.49 802.48 2333.63 819.31 2279.57 799.48 2167.63 677.31 2124.01 881.66 2072.5 735.5 2241.48 910.7 2380.99 869.65 2516.47 947.1 2327.41 960.31 2383.9 1035.3 2300.18 1119.79 2268.63 1003.31 2131.63 1096.31 2075.5 1070.5 2124.01 881.66 2241.48 910.7 2327.41 960.31 2300.18 1119.79 2131.63 1096.31 2261.01 1202.06 2376.95 1128.64 2348.18 1206.79 2122.5 1451.5 2273.19 1411.12 2410.57 1523.85 2386.48 1637.7 2207.48 1553.8"
        fill="none"
        stroke="#30b4ad"
        strokeMiterlimit="10"
        style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
      ></polyline>
      <line
        x1="2361.48"
        y1="173.81"
        x2="2276.99"
        y2="88.98"
        fill="none"
        stroke="#30b4ad"
        strokeMiterlimit="10"
      ></line>
      <polyline
        points="1097.5 65.5 1233.71 72.39 1142.5 -48.5 981.23 46.84 1091.63 187.19 823.01 99.52 801.13 -31.11 755.5 157.68 714.38 97.23 686.5 -24.5 534.63 26.42 509.5 -42.5 449.55 35.7 534.63 29.64 650.76 88.73 443.49 227.7 541.63 240.31 369.01 168.47 392.63 68.31 280.41 142.31 99.24 205.48 177.08 229.6 93.18 405.18 218.67 356.96"
        fill="none"
        stroke="#30b4ad"
        strokeMiterlimit="10"
        style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
      ></polyline>
      <polyline
        points="443.49 227.7 392.63 68.31 449.55 35.7"
        fill="none"
        stroke="#30b4ad"
        strokeMiterlimit="10"
        style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
      ></polyline>
      <polyline
        points="2453.57 1435.93 2696.5 1440.5 2511.55 1298.07 2568.41 1097.31 2690.93 1163.57 2696.5 1441.08"
        fill="none"
        stroke="#30b4ad"
        strokeMiterlimit="10"
        style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
      ></polyline>
      <polyline
        points="2696.5 1440.5 2537.57 1543.93 2567.54 1756.5 2386.48 1637.7 2207.77 1711.5 2185.17 1631.65 2057.35 1771.5"
        fill="none"
        stroke="#30b4ad"
        strokeMiterlimit="10"
        style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
      ></polyline>
      <polyline
        points="1185.83 454.93 1203.19 573.12 1269.49 540.48 1267.95 675.07 1052.01 612.47"
        fill="none"
        stroke="#30b4ad"
        strokeMiterlimit="10"
        style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
      ></polyline>
      <polyline
        points="1383.57 598.16 1399.4 472.93 1550.5 603.32 1576.49 480.7 1749.63 536.47 1713.63 743.64 1660.04 637.13 1550.5 603.32 1467.57 705.93 1383.57 598.16 1340.57 686.93 1427.27 731.29 1546.01 758.47 1619.09 831.77 1580.01 931.31 1515.51 886.47 1401.22 878.47 1475.08 1016.5 1397.49 994.48 1278.63 1028.31 1316.48 799.7 1195.81 778.9 1213.98 866.81 1157.03 1002.96 1045.2 1106.07 1036.89 1023.38 1061.42 1002.96 930.98 969.7 872.63 842.31 1027.13 889.31 1115.17 793.64 1137.77 715.8 1009.89 733.25 952.53 595.31 1052.01 612.47 1102.48 528.47 1004.01 482.81"
        fill="none"
        stroke="#30b4ad"
        strokeMiterlimit="10"
        style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
      ></polyline>
      <line
        x1="1115.17"
        y1="793.64"
        x2="1157.03"
        y2="1002.96"
        fill="none"
        stroke="#30b4ad"
        strokeMiterlimit="10"
      ></line>
      <line
        x1="1278.63"
        y1="1028.31"
        x2="1380.01"
        y2="1160.67"
        fill="none"
        stroke="#30b4ad"
        strokeMiterlimit="10"
      ></line>
      <line
        x1="1225.49"
        y1="1092.77"
        x2="1157.03"
        y2="1002.96"
        fill="none"
        stroke="#30b4ad"
        strokeMiterlimit="10"
      ></line>
      <path
        d="M1009.5,733.5,1052,612l-48-129.19-51.48,112.5"
        fill="none"
        stroke="#30b4ad"
        strokeMiterlimit="10"
        style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
      ></path>
      <path
        d="M1017.86,730.43a8.45,8.45,0,1,0-5.14,10.78h0a8.44,8.44,0,0,0,5.12-10.78"
        fill="#30b4ad"
        style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
      ></path>
      <polyline
        points="1203.19 573.12 1102.48 528.47 1190.2 452.7"
        fill="none"
        stroke="#30b4ad"
        strokeMiterlimit="10"
        style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
      ></polyline>
      <polyline
        points="1267.95 675.07 1137.77 715.8 1195.81 778.9 1267.95 675.07 1340.57 686.93 1401.22 878.47 1546.01 758.47 1660.04 637.13 1467.57 705.93 1427.27 731.29 1316.48 799.7 1213.98 866.81 1397.49 994.48 1580.01 931.31 1688.05 1017.31 1475.08 1016.5 1498.17 1122.2"
        fill="none"
        stroke="#30b4ad"
        strokeMiterlimit="10"
        style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
      ></polyline>
      <polyline
        points="1061.42 1002.96 1027.13 889.31 1009.89 733.25 872.63 842.31"
        fill="none"
        stroke="#30b4ad"
        strokeMiterlimit="10"
        style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
      ></polyline>
      <line
        x1="930.98"
        y1="969.7"
        x2="937.63"
        y2="1157.31"
        fill="none"
        stroke="#30b4ad"
        strokeMiterlimit="10"
      ></line>
      <line
        x1="1572.01"
        y1="1164.47"
        x2="1580.01"
        y2="931.31"
        fill="none"
        stroke="#30b4ad"
        strokeMiterlimit="10"
      ></line>
      <polyline
        points="1687.01 920.47 1619.09 831.77 1515.51 886.47"
        fill="none"
        stroke="#30b4ad"
        strokeMiterlimit="10"
        style={{ strokeDashoffset: 0, strokeDasharray: "none" }}
      ></polyline>
    </g>
    <g id="dots">
      <path
        d="M1492,352.68a10,10,0,1,0,6.06-12.78,10,10,0,0,0-6.06,12.78h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1383.49,164.72a6.5,6.5,0,1,0,4-8.31,6.51,6.51,0,0,0-4,8.31v0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1735.31,1285.43a10,10,0,1,0-6.69,12.47l.47-.16a9.77,9.77,0,0,0,6.22-12.31"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1702.47,1379.4a9.5,9.5,0,1,0,5.77-12.14h0a9.5,9.5,0,0,0-5.76,12.13"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1708.65,418.14a11.5,11.5,0,1,0-7,14.7h0a11.5,11.5,0,0,0,7-14.68h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1196.87,297.17a7.73,7.73,0,1,0-4.7,9.88h0a7.73,7.73,0,0,0,4.71-9.86h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1474.2,249.12a7.5,7.5,0,1,0-4.56,9.59,7.5,7.5,0,0,0,4.56-9.59"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1428.75,231.9a8,8,0,1,0-4.91,10.19h0a8,8,0,0,0,4.88-10.18"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1622.76,1318.18a13.5,13.5,0,1,0-8.18,17.25h0a13.5,13.5,0,0,0,8.19-17.24h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1384.86,474.54a12.5,12.5,0,1,0,7.56-16h0a12.51,12.51,0,0,0-7.56,16h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1079.86,190.54a12.5,12.5,0,1,0,7.56-16h0a12.51,12.51,0,0,0-7.56,16h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M961.86,351.54a12.5,12.5,0,1,0,7.56-16h0a12.51,12.51,0,0,0-7.56,16h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M801.86,238.54a12.5,12.5,0,1,0,7.56-16h0a12.51,12.51,0,0,0-7.56,16h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M759.86,520.54a12.5,12.5,0,1,0,7.56-16h0a12.51,12.51,0,0,0-7.56,16h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M860.86,651.54a12.5,12.5,0,1,0,7.56-16h0a12.51,12.51,0,0,0-7.56,16h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M723.86,939.54a12.5,12.5,0,1,0,7.56-16h0a12.51,12.51,0,0,0-7.56,16h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M860.86,846.54a12.5,12.5,0,1,0,7.56-16h0a12.51,12.51,0,0,0-7.56,16h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M925.86,1161.54a12.5,12.5,0,1,0,7.56-16h0a12.51,12.51,0,0,0-7.56,16h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M731.86,1162.54a12.5,12.5,0,1,0,7.56-16h0a12.51,12.51,0,0,0-7.56,16h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1819.86,570.54a12.5,12.5,0,1,0,7.56-16h0a12.51,12.51,0,0,0-7.56,16h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M663.86,578.54a12.5,12.5,0,1,0,7.56-16h0a12.51,12.51,0,0,0-7.56,16h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1849.86,667.54a12.5,12.5,0,1,0,7.56-16h0a12.51,12.51,0,0,0-7.56,16h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1895.86,845.54a12.5,12.5,0,1,0,7.56-16h0a12.51,12.51,0,0,0-7.56,16h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1940.86,1392.54a12.5,12.5,0,1,0,7.56-16h0a12.51,12.51,0,0,0-7.56,16h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1855.75,1348.14a8.37,8.37,0,1,0,5.06-10.71h0a8.38,8.38,0,0,0-5.06,10.71h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1701.86,747.54a12.5,12.5,0,1,0,7.56-16h0a12.51,12.51,0,0,0-7.56,16h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1677.86,1021.54a12.5,12.5,0,1,0,7.56-16h0a12.51,12.51,0,0,0-7.56,16h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1902.86,1056.54a12.5,12.5,0,1,0,7.56-16h0a12.51,12.51,0,0,0-7.56,16h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1737.86,896.54a12.5,12.5,0,1,0,7.56-16h0a12.51,12.51,0,0,0-7.56,16h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1827.86,1203.54a12.5,12.5,0,1,0,7.56-16h0a12.51,12.51,0,0,0-7.56,16h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1959.86,1265.54a12.5,12.5,0,1,0,7.56-16h0a12.51,12.51,0,0,0-7.56,16h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1984.86,661.54a12.5,12.5,0,1,0,7.56-16h0a12.51,12.51,0,0,0-7.56,16h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1564.15,1418.73a9.5,9.5,0,1,0-5.74,12.15,9.5,9.5,0,0,0,5.74-12.15h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M954.68,1347.5a13.5,13.5,0,1,0-8.21,17.3h0a13.49,13.49,0,0,0,8.17-17.25"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1671.23,399.65a10.5,10.5,0,1,0-6.36,13.42h0a10.51,10.51,0,0,0,6.37-13.42h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1570.48,242.37a11.5,11.5,0,1,0-7,14.69h0a11.48,11.48,0,0,0,7-14.66v0h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1761.38,316a8,8,0,1,0,4.86-10.22h0a8,8,0,0,0-4.85,10.22"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1600.7,45.41a10,10,0,1,0,6-12.79h0a10,10,0,0,0-6,12.78"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1247.68,217.84a8,8,0,1,0,4.9-10.2h0a8,8,0,0,0-4.89,10.19"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1329.75,329.7a6.06,6.06,0,1,0,3.73-7.72h0a6.06,6.06,0,0,0-3.7,7.72"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1178.75,456.7a6.06,6.06,0,1,0,3.73-7.72h0a6.06,6.06,0,0,0-3.7,7.72"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M720.75,356.7a6.06,6.06,0,1,0,3.73-7.72h0a6.06,6.06,0,0,0-3.7,7.72"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M835.75,524.7a6.06,6.06,0,1,0,3.73-7.72h0a6.06,6.06,0,0,0-3.7,7.72"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M839.75,751.7a6.06,6.06,0,1,0,3.73-7.72h0a6.06,6.06,0,0,0-3.7,7.72"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M673.75,911.7a6.06,6.06,0,1,0,3.73-7.72h0a6.06,6.06,0,0,0-3.7,7.72"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M805.75,1107.7a6.06,6.06,0,1,0,3.73-7.72h0a6.06,6.06,0,0,0-3.7,7.72"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M770.75,1207.7a6.06,6.06,0,1,0,3.73-7.72h0a6.06,6.06,0,0,0-3.7,7.72"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M984.75,1478.7a6.06,6.06,0,1,0,3.73-7.72h0a6.06,6.06,0,0,0-3.7,7.72"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1541.75,1500.7a6.06,6.06,0,1,0,3.73-7.72h0a6.06,6.06,0,0,0-3.7,7.72"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1628.75,1452.7a6.06,6.06,0,1,0,3.73-7.72h0a6.06,6.06,0,0,0-3.7,7.72"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1939.75,1463.7a6.07,6.07,0,1,0,3.73-7.72h0a6.06,6.06,0,0,0-3.7,7.72"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2203.75,1712.7a6.07,6.07,0,1,0,3.73-7.72h0a6.06,6.06,0,0,0-3.7,7.72"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1955.75,1136.7a6.07,6.07,0,1,0,3.73-7.72h0a6.06,6.06,0,0,0-3.7,7.72"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1818.75,849.7a6.07,6.07,0,1,0,3.73-7.72h0a6.06,6.06,0,0,0-3.7,7.72"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1810.75,1041.7a6.07,6.07,0,1,0,3.73-7.72h0a6.06,6.06,0,0,0-3.7,7.72"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1952.75,535.7a6.07,6.07,0,1,0,3.73-7.72h0a6.06,6.06,0,0,0-3.7,7.72"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1570.75,482.7a6.07,6.07,0,1,0,3.73-7.72h0a6.06,6.06,0,0,0-3.7,7.72"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1855.57,430.19a6.05,6.05,0,1,0,3.71-7.72h0a6,6,0,0,0-3.73,7.62l0,.09"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1803.25,382.79a12,12,0,1,0,7.26-15.34h0a12,12,0,0,0-7.26,15.34h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1241.47,1344.35a12,12,0,1,0-7.26,15.34h0a12,12,0,0,0,7.26-15.34h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M800.47,1466.35a12,12,0,1,0-7.26,15.34h0a12,12,0,0,0,7.26-15.34h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1254.47,1474.35a12,12,0,1,0-7.26,15.34h0a12,12,0,0,0,7.26-15.34h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1817.47,1455.35a12,12,0,1,0-7.26,15.34h0a12,12,0,0,0,7.26-15.34h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1558.38,1296.17a8.5,8.5,0,1,0-5.15,10.87,8.49,8.49,0,0,0,5.15-10.87h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1239.64,1567.29a12,12,0,1,0-7.27,15.35,12,12,0,0,0,7.27-15.35v0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1363,1286.63a12.5,12.5,0,1,0,7.58-16h0a12.52,12.52,0,0,0-7.57,16"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1605.27,182.17a11.5,11.5,0,1,0-7,14.7h0a11.48,11.48,0,0,0,7-14.66v0h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1230.86,63.46a8.5,8.5,0,1,0,10.87,5.14,8.51,8.51,0,0,0-10.87-5.14h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1726.73,302.35a9.5,9.5,0,1,0,5.75-12.15,9.5,9.5,0,0,0-5.75,12.15v0h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1888.12,305.24A13.5,13.5,0,1,0,1896.3,288h0a13.51,13.51,0,0,0-8.17,17.25"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1785.63,171.41a11.5,11.5,0,1,0,14.7,7h0a11.49,11.49,0,0,0-14.67-7h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1872.74,131.29a11.51,11.51,0,1,0,7-14.71,11.5,11.5,0,0,0-7,14.71v0h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1308.65,1413.86a12,12,0,1,0-7.27,15.34h0a12,12,0,0,0,7.27-15.33"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1167,1371.06a9.28,9.28,0,1,0-5.63,11.86h0a9.28,9.28,0,0,0,5.62-11.86h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1451.69,1419a12,12,0,1,0,7.26-15.34h0a12,12,0,0,0-7.26,15.34h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1317.5,295a8.5,8.5,0,1,0,5.15-10.87A8.51,8.51,0,0,0,1317.5,295h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1260.81,421.89a9.5,9.5,0,1,0,5.76-12.15,9.5,9.5,0,0,0-5.76,12.15v0h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1964.69,381.43a9.5,9.5,0,1,0,12.15,5.75h0a9.48,9.48,0,0,0-12.1-5.76h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M814.47,1400.33a8,8,0,1,0-10.24-4.85h0a8,8,0,0,0,10.22,4.85h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1724,1597.15a8,8,0,1,0-10.25-4.85h0a8,8,0,0,0,10.21,4.87h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1524,1307.15a8,8,0,1,0-10.25-4.85h0a8,8,0,0,0,10.21,4.87h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1137,1317.15a8,8,0,1,0-10.25-4.85h0a8,8,0,0,0,10.21,4.87h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1074.83,1358.83a13.31,13.31,0,1,0-17-8.07h0a13.31,13.31,0,0,0,17,8.1l0,0h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1545.45,403a8,8,0,1,0,10.25,4.85h0a8,8,0,0,0-10.21-4.86h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1069.85,1390.45a8,8,0,1,0-10.25-4.85h0a8,8,0,0,0,10.22,4.85h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1027.19,1567.2a11.67,11.67,0,1,0-14.92-7.06h0a11.67,11.67,0,0,0,14.89,7.07h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M726.19,1798.2a11.67,11.67,0,1,0-14.92-7.06h0a11.67,11.67,0,0,0,14.89,7.07h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M879.9,1257.65a8,8,0,1,0-10.25-4.85h0a8,8,0,0,0,10.22,4.86h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1281.71,1291.87a8,8,0,1,0-10.24-4.85h0a8,8,0,0,0,10.22,4.85h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1390.71,1479a8,8,0,1,0-10.24-4.85h0a8,8,0,0,0,10.22,4.85h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2378.15,92.73a9.5,9.5,0,1,0-5.74,12.15,9.5,9.5,0,0,0,5.74-12.15h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2355.75,174.7a6.06,6.06,0,1,0,3.73-7.72h0a6.06,6.06,0,0,0-3.7,7.72"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2068.47,148.35a12,12,0,1,0-7.26,15.34h0a12,12,0,0,0,7.26-15.34h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2122.65,87.86a12,12,0,1,0-7.27,15.34h0a12,12,0,0,0,7.27-15.33"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2265.69,93A12,12,0,1,0,2273,77.68h0A12,12,0,0,0,2265.69,93h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2204.71,153a8,8,0,1,0-10.24-4.85h0a8,8,0,0,0,10.22,4.85h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M867.71,1050a8,8,0,1,0-10.24-4.85h0a8,8,0,0,0,10.22,4.85h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1387.33,1169.92a12.5,12.5,0,1,0-17.67-.87h0a12.51,12.51,0,0,0,17.67.88h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1049.42,1110.6a6.06,6.06,0,1,0-8.56-.49h0a6.06,6.06,0,0,0,8.55.47"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1502.2,1126.73a6.06,6.06,0,1,0-8.56-.49h0a6,6,0,0,0,8.54.47"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1231.42,1087.4a8,8,0,1,0-.57,11.31h0a8,8,0,0,0,.58-11.3h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M730.71,728a8,8,0,1,0-10.24-4.85h0A8,8,0,0,0,730.69,728h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M833.71,449a8,8,0,1,0-10.24-4.85h0A8,8,0,0,0,833.69,449h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M923.71,245a8,8,0,1,0-10.24-4.85h0A8,8,0,0,0,923.69,245h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M694.71,215a8,8,0,1,0-10.24-4.85h0A8,8,0,0,0,694.69,215h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1750.71,544a8,8,0,1,0-10.24-4.85h0a8,8,0,0,0,10.22,4.85h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1088.71,362a8,8,0,1,0-10.24-4.85h0a8,8,0,0,0,10.22,4.85h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1006.71,492a8,8,0,1,0-10.24-4.85h0a8,8,0,0,0,10.22,4.85h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1836.71,476a8,8,0,1,0-10.24-4.85h0a8,8,0,0,0,10.22,4.85h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1840.71,771a8,8,0,1,0-10.24-4.85h0a8,8,0,0,0,10.22,4.85h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M380.86,72.54a12.5,12.5,0,1,0,7.56-16h0a12.51,12.51,0,0,0-7.56,16h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M522.86,33.54a12.5,12.5,0,1,0,7.56-16h0a12.51,12.51,0,0,0-7.56,16h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M567.86,580.54a12.5,12.5,0,1,0,7.56-16h0a12.51,12.51,0,0,0-7.56,16h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M482.75,536.14a8.37,8.37,0,1,0,5.06-10.71h0a8.38,8.38,0,0,0-5.06,10.71h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M529.86,244.54a12.5,12.5,0,1,0,7.56-16h0a12.51,12.51,0,0,0-7.56,16h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M454.86,391.54a12.5,12.5,0,1,0,7.56-16h0a12.51,12.51,0,0,0-7.56,16h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M586.86,453.54a12.5,12.5,0,1,0,7.56-16h0a12.51,12.51,0,0,0-7.56,16h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M566.75,651.7a6.07,6.07,0,1,0,3.73-7.72h0a6.06,6.06,0,0,0-3.7,7.72"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M582.75,324.7a6.07,6.07,0,1,0,3.73-7.72h0a6.06,6.06,0,0,0-3.7,7.72"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M445.75,37.7A6.07,6.07,0,1,0,449.48,30h0a6.06,6.06,0,0,0-3.7,7.72"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M437.75,229.7a6.07,6.07,0,1,0,3.73-7.72h0a6.06,6.06,0,0,0-3.7,7.72"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M444.47,643.35a12,12,0,1,0-7.26,15.34h0a12,12,0,0,0,7.26-15.34h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M371.71,176a8,8,0,1,0-10.24-4.85h0A8,8,0,0,0,371.69,176h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1689.71,928a8,8,0,1,0-10.24-4.85h0a8,8,0,0,0,10.22,4.85h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1989.71,997a8,8,0,1,0-10.24-4.85h0a8,8,0,0,0,10.22,4.85h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1574.71,1172a8,8,0,1,0-10.24-4.85h0a8,8,0,0,0,10.22,4.85h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1527.71,1238a8,8,0,1,0-10.24-4.85h0a8,8,0,0,0,10.22,4.85h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M728.71,1297a8,8,0,1,0-10.24-4.85h0a8,8,0,0,0,10.22,4.85h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1431.86,393.11a8,8,0,1,0,10.25,4.85h0a8,8,0,0,0-10.21-4.86h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M462,893.7a10,10,0,1,0-12.78-6.06A10,10,0,0,0,462,893.7h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M406.45,1188.83a7.73,7.73,0,1,0,9.88,4.7h0a7.73,7.73,0,0,0-9.87-4.7h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M567.81,1015.84a12.5,12.5,0,1,0-16-7.56h0a12.52,12.52,0,0,0,16,7.56h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M460.81,1423.84a12.5,12.5,0,1,0-16-7.56h0a12.52,12.52,0,0,0,16,7.56h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M677.81,1612.84a12.5,12.5,0,1,0-16-7.56h0a12.52,12.52,0,0,0,16,7.56h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M570.81,1732.84a12.5,12.5,0,1,0-16-7.56h0a12.52,12.52,0,0,0,16,7.56h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M106.81,1794.84a12.5,12.5,0,1,0-16-7.56h0a12.52,12.52,0,0,0,16,7.56h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1220.81,1717.84a12.5,12.5,0,1,0-16-7.56h0a12.52,12.52,0,0,0,16,7.56h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1402.81,1733.84a12.5,12.5,0,1,0-16-7.56h0a12.52,12.52,0,0,0,16,7.56h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M372.81,1513.84a12.5,12.5,0,1,0-16-7.56h0a12.52,12.52,0,0,0,16,7.56h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M508.92,714.47a10.5,10.5,0,1,0,13.42,6.36h0a10.48,10.48,0,0,0-13.41-6.36h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M439,1056a6.06,6.06,0,1,0-7.72-3.72h0a6.07,6.07,0,0,0,7.72,3.7"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M566,1207a6.06,6.06,0,1,0-7.72-3.72h0a6.07,6.07,0,0,0,7.72,3.7"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M447,1607a6.06,6.06,0,1,0-7.72-3.72h0a6.07,6.07,0,0,0,7.72,3.7"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M634,1550a6.06,6.06,0,1,0-7.72-3.72h0a6.07,6.07,0,0,0,7.72,3.7"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M520,802a6.07,6.07,0,1,0-7.72-3.72h0a6.07,6.07,0,0,0,7.72,3.7"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M509.73,1147.84a8.5,8.5,0,1,0,5.14-10.87,8.5,8.5,0,0,0-5.14,10.87v0h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M404.25,1068.2a8.5,8.5,0,1,0-10.87-5.15,8.5,8.5,0,0,0,10.87,5.15h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M546.16,1121.89a9.5,9.5,0,1,0-12.15-5.76,9.51,9.51,0,0,0,12.15,5.76h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M586,832.25A8,8,0,1,0,590.85,822h0A8,8,0,0,0,586,832.22h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M558.28,1552a8,8,0,1,0-4.85,10.24h0a8,8,0,0,0,4.85-10.22h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M471.28,1297a8,8,0,1,0-4.85,10.24h0a8,8,0,0,0,4.85-10.22h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M601.28,1379a8,8,0,1,0-4.85,10.24h0a8,8,0,0,0,4.85-10.22h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M114.81,1486.84a12.5,12.5,0,1,0-16-7.56h0a12.52,12.52,0,0,0,16,7.56h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M247.81,1590.84a12.5,12.5,0,1,0-16-7.56h0a12.52,12.52,0,0,0,16,7.56h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M119,1595a6.07,6.07,0,1,0-7.72-3.72h0a6.07,6.07,0,0,0,7.72,3.7"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M502.38,953.84a8,8,0,1,0,4.85-10.25h0a8,8,0,0,0-4.85,10.22h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1983.67,284.39a8,8,0,1,0,4.92-10.19h0a8,8,0,0,0-4.88,10.18"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1982.52,373.48a10.5,10.5,0,1,0,6.38-13.41h0a10.49,10.49,0,0,0-6.36,13.4h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M691.86,1407.43a8.45,8.45,0,1,0-5.14,10.78h0a8.44,8.44,0,0,0,5.12-10.78"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2357.86,512.54a12.5,12.5,0,1,0,7.56-16h0a12.51,12.51,0,0,0-7.56,16h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2197.86,399.54a12.5,12.5,0,1,0,7.56-16h0a12.51,12.51,0,0,0-7.56,16h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2155.86,681.54a12.5,12.5,0,1,0,7.56-16h0a12.51,12.51,0,0,0-7.56,16h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2321.86,823.54a12.5,12.5,0,1,0,7.56-16h0a12.51,12.51,0,0,0-7.56,16h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2119.86,1100.54a12.5,12.5,0,1,0,7.56-16h0a12.51,12.51,0,0,0-7.56,16h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2256.86,1007.54a12.5,12.5,0,1,0,7.56-16h0a12.51,12.51,0,0,0-7.56,16h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2101.86,1274.54a12.5,12.5,0,1,0,7.56-16h0a12.51,12.51,0,0,0-7.56,16h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2059.86,739.54a12.5,12.5,0,1,0,7.56-16h0a12.51,12.51,0,0,0-7.56,16h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2350.68,1508.5a13.5,13.5,0,1,0-8.21,17.3h0a13.49,13.49,0,0,0,8.17-17.25"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2116.75,517.7a6.06,6.06,0,1,0,3.73-7.72h0a6.06,6.06,0,0,0-3.7,7.72"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2231.75,685.7a6.06,6.06,0,1,0,3.73-7.72h0a6.06,6.06,0,0,0-3.7,7.72"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2235.75,912.7a6.06,6.06,0,1,0,3.73-7.72h0a6.06,6.06,0,0,0-3.7,7.72"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2069.75,1072.7a6.06,6.06,0,1,0,3.73-7.72h0a6.06,6.06,0,0,0-3.7,7.72"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2201.75,1268.7a6.06,6.06,0,1,0,3.73-7.72h0a6.06,6.06,0,0,0-3.7,7.72"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2166.75,1368.7a6.06,6.06,0,1,0,3.73-7.72h0a6.06,6.06,0,0,0-3.7,7.72"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2380.75,1639.7a6.06,6.06,0,1,0,3.73-7.72h0a6.06,6.06,0,0,0-3.7,7.72"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2196.47,1627.35a12,12,0,1,0-7.26,15.34h0a12,12,0,0,0,7.26-15.34h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2210.47,1561.33a8,8,0,1,0-10.24-4.85h0a8,8,0,0,0,10.22,4.85h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2275.9,1418.65a8,8,0,1,0-10.25-4.85h0a8,8,0,0,0,10.22,4.86h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2263.71,1211a8,8,0,1,0-10.24-4.85h0a8,8,0,0,0,10.22,4.85h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2126.71,889a8,8,0,1,0-10.24-4.85h0a8,8,0,0,0,10.22,4.85h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2229.71,610a8,8,0,1,0-10.24-4.85h0a8,8,0,0,0,10.22,4.85h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2176.71,239a8,8,0,1,0-10.24-4.85h0a8,8,0,0,0,10.22,4.85h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2090.71,376a8,8,0,1,0-10.24-4.85h0a8,8,0,0,0,10.22,4.85h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2402.71,653a8,8,0,1,0-10.24-4.85h0a8,8,0,0,0,10.22,4.85h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1548.71,766a8,8,0,1,0-10.24-4.85h0a8,8,0,0,0,10.22,4.85h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2124.71,1458a8,8,0,1,0-10.24-4.85h0a8,8,0,0,0,10.22,4.85h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2059.71,1777a8,8,0,1,0-10.24-4.85h0a8,8,0,0,0,10.22,4.85h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2087.86,1568.43a8.45,8.45,0,1,0-5.14,10.78h0a8.44,8.44,0,0,0,5.12-10.78"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1181.3,1232.62a12.5,12.5,0,1,0-7.57,16h0a12.49,12.49,0,0,0,7.6-15.94l0-.06h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1059.75,1228.24a7.5,7.5,0,0,0-9.58-4.55h0a7.49,7.49,0,0,0-4.53,9.59h0a7.5,7.5,0,0,0,9.57,4.54h0a7.49,7.49,0,0,0,4.56-9.56h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1129.41,1224.07a10,10,0,1,0-6.06,12.78h0a10,10,0,0,0,6.06-12.78h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M639.51,96.06a13.5,13.5,0,1,0,3.79-18.71h0a13.5,13.5,0,0,0-3.8,18.7h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M707.3,101.94a8.5,8.5,0,1,0,2.38-11.79,8.49,8.49,0,0,0-2.38,11.79h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M834.21,93.19a12.5,12.5,0,1,0-3.5,17.36h0A12.52,12.52,0,0,0,834.2,93.2"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M976.87,39.54A8,8,0,1,0,988,41.79h0a8,8,0,0,0-11.09-2.25h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M751.06,151a8,8,0,1,0,11.11,2.24h0A8,8,0,0,0,751.08,151h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1088.59,72.89a12.5,12.5,0,1,0,3.5-17.35h0a12.48,12.48,0,0,0-3.54,17.29l0,.06h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1694.62,74.9A7.51,7.51,0,0,0,1705,77h0a7.51,7.51,0,0,0,2.09-10.4h0a7.5,7.5,0,0,0-10.39-2.1h0a7.49,7.49,0,0,0-2.12,10.38h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1469,76.71a10,10,0,1,0,2.81-13.86h0A10,10,0,0,0,1469,76.71h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M284.45,1151.62a10,10,0,1,0-12.47-6.69l.16.47a9.77,9.77,0,0,0,12.31,6.22"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M190.48,1118.78a9.5,9.5,0,1,0,12.14,5.77h0a9.49,9.49,0,0,0-12.13-5.76"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M251.7,1039.07a13.5,13.5,0,1,0-17.25-8.18h0a13.49,13.49,0,0,0,17.24,8.18h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M177.34,1357.17a12.5,12.5,0,1,0,16,7.56h0a12.5,12.5,0,0,0-16-7.56h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M221.74,1272.06a8.37,8.37,0,1,0,10.71,5.06h0a8.37,8.37,0,0,0-10.71-5.06h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M304.34,1376.17a12.5,12.5,0,1,0,16,7.56h0a12.5,12.5,0,0,0-16-7.56h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M151.15,980.46A9.5,9.5,0,1,0,139,974.72a9.5,9.5,0,0,0,12.15,5.74h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M222.38,371a13.5,13.5,0,1,0-17.3-8.21h0A13.51,13.51,0,0,0,222.33,371"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M91.18,401.06a6.06,6.06,0,1,0,7.72,3.72h0a6.05,6.05,0,0,0-7.72-3.7"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M69.18,958.06a6.06,6.06,0,1,0,7.72,3.72h0a6.05,6.05,0,0,0-7.72-3.7"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M117.18,1045.06a6.06,6.06,0,1,0,7.72,3.72h0a6.05,6.05,0,0,0-7.72-3.7"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M106.18,1356.06a6.06,6.06,0,1,0,7.72,3.72h0a6.05,6.05,0,0,0-7.72-3.7"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M225.53,657.78a12,12,0,1,0-15.34-7.26h0a12,12,0,0,0,15.34,7.26h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M103.53,216.78a12,12,0,1,0-15.34-7.26h0a12,12,0,0,0,15.34,7.26h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M95.53,670.78a12,12,0,1,0-15.34-7.26h0a12,12,0,0,0,15.34,7.26h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M114.53,1233.78a12,12,0,1,0-15.34-7.26h0a12,12,0,0,0,15.34,7.26h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M273.71,974.69a8.5,8.5,0,1,0-10.87-5.15,8.5,8.5,0,0,0,10.87,5.15h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M274.59,951a12,12,0,1,0-15.35-7.27A12,12,0,0,0,274.59,951h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M283.25,779.33a12.5,12.5,0,1,0,16,7.58h0a12.51,12.51,0,0,0-16-7.57"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M156,725a12,12,0,1,0-15.34-7.27h0A12,12,0,0,0,156,725"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M198.82,583.32A9.28,9.28,0,1,0,187,577.69h0a9.29,9.29,0,0,0,11.86,5.62h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M150.86,868a12,12,0,1,0,15.34,7.26h0A12,12,0,0,0,150.86,868h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M169.55,230.78a8,8,0,1,0,4.85-10.24h0a8,8,0,0,0-4.85,10.21v0h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M251.73,550.34a8,8,0,1,0,4.85-10.25h0a8,8,0,0,0-4.87,10.21v0h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M179.43,486.16a8,8,0,1,0,4.85-10.25h0a8,8,0,0,0-4.85,10.22h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M207.68,488.5a11.67,11.67,0,1,0,7.06-14.92h0a11.66,11.66,0,0,0-7.07,14.89h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M312.23,296.21A8,8,0,1,0,317.08,286h0a8,8,0,0,0-4.86,10.21v0h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M278,698a8,8,0,1,0,4.85-10.24h0A8,8,0,0,0,278,698h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M90.88,807a8,8,0,1,0,4.85-10.24h0A8,8,0,0,0,90.88,807h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M331.88,944a8,8,0,1,0,4.85-10.24h0A8,8,0,0,0,331.88,944h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M272.88,145a8,8,0,1,0,4.85-10.24h0A8,8,0,0,0,272.88,145h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M162.45,108.17A8.45,8.45,0,1,0,151.67,103v0h0a8.44,8.44,0,0,0,10.78,5.12"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M337.26,597.61a12.5,12.5,0,1,0-16-7.57h0a12.5,12.5,0,0,0,15.94,7.59l.06,0h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M341.64,476.06a7.49,7.49,0,0,0,4.55-9.58h0A7.49,7.49,0,0,0,336.6,462h0a7.49,7.49,0,0,0-4.54,9.57h0a7.49,7.49,0,0,0,9.56,4.56h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M345.81,545.72A10,10,0,1,0,333,539.66h0a10,10,0,0,0,12.78,6.06h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2513.45,1307.62a10,10,0,1,0-12.47-6.69l.16.47a9.77,9.77,0,0,0,12.31,6.22"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2419.48,1274.78a9.5,9.5,0,1,0,12.14,5.77h0a9.49,9.49,0,0,0-12.13-5.76"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2480.7,1195.07a13.5,13.5,0,1,0-17.25-8.18h0a13.49,13.49,0,0,0,17.24,8.18h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2406.34,1513.17a12.5,12.5,0,1,0,16,7.56h0a12.5,12.5,0,0,0-16-7.56h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2450.74,1428.06a8.37,8.37,0,1,0,10.71,5.06h0a8.37,8.37,0,0,0-10.71-5.06h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2533.34,1532.17a12.5,12.5,0,1,0,16,7.56h0a12.5,12.5,0,0,0-16-7.56h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2563.34,1750.17a12.5,12.5,0,1,0,16,7.56h0a12.5,12.5,0,0,0-16-7.56h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2380.15,1136.46a9.5,9.5,0,1,0-12.15-5.74,9.5,9.5,0,0,0,12.15,5.74h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2451.38,527a13.5,13.5,0,1,0-17.3-8.21h0a13.51,13.51,0,0,0,17.25,8.17"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2320.18,557.06a6.06,6.06,0,1,0,7.72,3.72h0a6.05,6.05,0,0,0-7.72-3.7"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2298.18,1114.06a6.06,6.06,0,1,0,7.72,3.72h0a6.05,6.05,0,0,0-7.72-3.7"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2346.18,1201.06a6.06,6.06,0,1,0,7.72,3.72h0a6.05,6.05,0,0,0-7.72-3.7"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2335.18,1512.06a6.06,6.06,0,1,0,7.72,3.72h0a6.05,6.05,0,0,0-7.72-3.7"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2454.53,813.78a12,12,0,1,0-15.34-7.26h0a12,12,0,0,0,15.34,7.26h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2332.53,372.78a12,12,0,1,0-15.34-7.26h0a12,12,0,0,0,15.34,7.26h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2285.53,810.78a12,12,0,1,0-15.34-7.26h0a12,12,0,0,0,15.34,7.26h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2343.53,1389.78a12,12,0,1,0-15.34-7.26h0a12,12,0,0,0,15.34,7.26h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1280.68,670.5a13.5,13.5,0,1,0-8.21,17.3h0a13.49,13.49,0,0,0,8.17-17.25"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1096.75,530.7a6.06,6.06,0,1,0,3.73-7.72h0a6.06,6.06,0,0,0-3.7,7.72"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1310.75,801.7a6.06,6.06,0,1,0,3.73-7.72h0a6.06,6.06,0,0,0-3.7,7.72"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1126.47,789.35a12,12,0,1,0-7.26,15.34h0a12,12,0,0,0,7.26-15.34h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1140.47,723.33a8,8,0,1,0-10.24-4.85h0a8,8,0,0,0,10.22,4.85h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1205.9,580.65a8,8,0,1,0-10.25-4.85h0a8,8,0,0,0,10.22,4.86h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1054.71,620a8,8,0,1,0-10.24-4.85h0a8,8,0,0,0,10.22,4.85h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1336.34,675.17a12.5,12.5,0,1,0,16,7.56h0a12.5,12.5,0,0,0-16-7.56h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1380.74,590.06a8.37,8.37,0,1,0,10.71,5.06h0a8.37,8.37,0,0,0-10.71-5.06h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1463.34,694.17a12.5,12.5,0,1,0,16,7.56h0a12.5,12.5,0,0,0-16-7.56h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1273.53,551.78a12,12,0,1,0-15.34-7.26h0a12,12,0,0,0,15.34,7.26h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M548.47,1317.4a9.5,9.5,0,1,0,5.77-12.14h0a9.5,9.5,0,0,0-5.76,12.13"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M892.86,1624.54a12.5,12.5,0,1,0,7.56-16h0a12.51,12.51,0,0,0-7.56,16h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M786.75,1605.14a8.37,8.37,0,1,0,5.06-10.71h0a8.38,8.38,0,0,0-5.06,10.71h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M175.75,1463.7a6.06,6.06,0,1,0,3.73-7.72h0a6.06,6.06,0,0,0-3.7,7.72"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M53.75,517.7A6.07,6.07,0,1,0,57.48,510h0a6.06,6.06,0,0,0-3.7,7.72"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M367.47,1615.35a12,12,0,1,0-7.26,15.34h0a12,12,0,0,0,7.26-15.34h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1104.75,1632.7a6.06,6.06,0,1,0,3.73-7.72h0a6.06,6.06,0,0,0-3.7,7.72"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M955.75,1708.7a6.06,6.06,0,1,0,3.73-7.72h0a6.06,6.06,0,0,0-3.7,7.72"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M324.75,1722.7a6.06,6.06,0,1,0,3.73-7.72h0a6.06,6.06,0,0,0-3.7,7.72"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1626.37,829.17a7.73,7.73,0,1,0-4.7,9.88h0a7.73,7.73,0,0,0,4.71-9.86h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1391.36,883.54a12.5,12.5,0,1,0,7.56-16h0a12.51,12.51,0,0,0-7.56,16h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1015.36,893.54a12.5,12.5,0,1,0,7.56-16h0a12.51,12.51,0,0,0-7.56,16h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1208.25,869.7A6.06,6.06,0,1,0,1212,862h0a6.06,6.06,0,0,0-3.7,7.72"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M925.25,971.7A6.06,6.06,0,1,0,929,964h0a6.06,6.06,0,0,0-3.7,7.72"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1518.21,894a8,8,0,1,0-10.24-4.85h0a8,8,0,0,0,10.22,4.85h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1439,727.07a12.5,12.5,0,1,0-7.56,16h0a12.51,12.51,0,0,0,7.56-16h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1199.85,790.2a12,12,0,1,0-15.34-7.26h0a12,12,0,0,0,15.34,7.26h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1548.5,598.48a6.06,6.06,0,1,0,7.72,3.72h0a6.05,6.05,0,0,0-7.72-3.7"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1411.9,1595.65a8,8,0,1,0-10.25-4.85h0a8,8,0,0,0,10.22,4.86h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1941.9,1709.65a8,8,0,1,0-10.25-4.85h0a8,8,0,0,0,10.22,4.86h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1666.9,1718.65a8,8,0,1,0-10.25-4.85h0a8,8,0,0,0,10.22,4.86h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M47.71,1561a8,8,0,1,0-10.24-4.85h0A8,8,0,0,0,47.69,1561h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1274.53,1646.78a12,12,0,1,0-15.34-7.26h0a12,12,0,0,0,15.34,7.26h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2616.71,1249.69a8.5,8.5,0,1,0-10.87-5.15,8.5,8.5,0,0,0,10.87,5.15h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2503.59,1107a12,12,0,1,0-15.35-7.27,12,12,0,0,0,15.35,7.27h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1936.85,1610a8,8,0,1,0,10.24,4.85h0a8,8,0,0,0-10.22-4.85h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1589.85,1588.42a13.5,13.5,0,1,0,17.26,8.18h0a13.49,13.49,0,0,0-17.23-8.19h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1777.4,1619a9.5,9.5,0,1,0,12.15,5.74,9.51,9.51,0,0,0-12.15-5.74h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1997.38,1643.43a6.06,6.06,0,1,0-7.72-3.72h0a6.06,6.06,0,0,0,7.72,3.7"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1479.38,1648.43a6.06,6.06,0,1,0-7.72-3.72h0a6.06,6.06,0,0,0,7.72,3.7"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2512.25,935.33a12.5,12.5,0,1,0,16,7.58h0a12.51,12.51,0,0,0-16-7.57"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2385,881a12,12,0,1,0-15.34-7.27h0A12,12,0,0,0,2385,881"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2427.82,739.32a9.28,9.28,0,1,0-11.86-5.63h0a9.29,9.29,0,0,0,11.86,5.62h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2379.86,1024a12,12,0,1,0,15.34,7.26h0a12,12,0,0,0-15.34-7.26h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2398.55,386.78a8,8,0,1,0,4.85-10.24h0a8,8,0,0,0-4.85,10.21v0h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2480.73,706.34a8,8,0,1,0,4.85-10.25h0a8,8,0,0,0-4.87,10.21v0h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2436.68,644.5a11.67,11.67,0,1,0,7.06-14.92h0a11.66,11.66,0,0,0-7.07,14.89h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2541.23,452.21a8,8,0,1,0,4.85-10.25h0a8,8,0,0,0-4.86,10.21v0h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2507,854a8,8,0,1,0,4.85-10.24h0A8,8,0,0,0,2507,854h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2319.88,963a8,8,0,1,0,4.85-10.24h0a8,8,0,0,0-4.85,10.22h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M945,598a8,8,0,1,0,4.85-10.24h0A8,8,0,0,0,945,598h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2560.88,1100a8,8,0,1,0,4.85-10.24h0a8,8,0,0,0-4.85,10.22h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2501.88,301a8,8,0,1,0,4.85-10.24h0a8,8,0,0,0-4.85,10.22h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1033.69,1014.43a9.5,9.5,0,1,0,12.15,5.75h0a9.48,9.48,0,0,0-12.1-5.76h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1051.52,1006.48a10.5,10.5,0,1,0,6.38-13.41h0a10.49,10.49,0,0,0-6.36,13.4h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1266.86,1032.54a12.5,12.5,0,1,0,7.56-16h0a12.51,12.51,0,0,0-7.56,16h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1159.71,1009a8,8,0,1,0-10.24-4.85h0a8,8,0,0,0,10.22,4.85h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1401.53,1005.78a12,12,0,1,0-15.34-7.26h0a12,12,0,0,0,15.34,7.26h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1467.55,1019.78a8,8,0,1,0,4.85-10.24h0a8,8,0,0,0-4.85,10.21v0h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1570.88,934a8,8,0,1,0,4.85-10.24h0a8,8,0,0,0-4.85,10.22h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2391.45,264.17a8.45,8.45,0,1,0-10.78-5.15v0h0a8.44,8.44,0,0,0,10.78,5.12"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2566.26,753.61a12.5,12.5,0,1,0-16-7.57h0a12.5,12.5,0,0,0,15.94,7.59l.06,0h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M1664.26,649.61a12.5,12.5,0,1,0-16-7.57h0a12.5,12.5,0,0,0,15.94,7.59l.06,0h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2570.64,632.06a7.49,7.49,0,0,0,4.55-9.58h0a7.49,7.49,0,0,0-9.59-4.53h0a7.49,7.49,0,0,0-4.54,9.57h0a7.49,7.49,0,0,0,9.56,4.56h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2574.81,701.72a10,10,0,1,0-12.78-6.06h0a10,10,0,0,0,12.78,6.06h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2503.53,176.78a12,12,0,1,0-15.34-7.26h0a12,12,0,0,0,15.34,7.26h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2476.82,102.32A9.28,9.28,0,1,0,2465,96.69h0a9.29,9.29,0,0,0,11.86,5.62h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2514.73,40.34a8,8,0,1,0,4.85-10.25h0a8,8,0,0,0-4.87,10.21v0h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2556,217a8,8,0,1,0,4.85-10.24h0A8,8,0,0,0,2556,217h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
      <path
        d="M2585.26,100.61a12.5,12.5,0,1,0-16-7.57h0a12.5,12.5,0,0,0,15.94,7.59l.06,0h0"
        fill="#30b4ad"
        style={{ opacity: 1, visibility: "inherit" }}
      ></path>
    </g>
  </svg>
);
