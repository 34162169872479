import React, { useState } from "react";
import _ from "underscore";
import { Link } from "react-router-dom";
import Advertbgsvg from "./assets/svg/Advertbg";
import AdvertProsvg from "./assets/svg/AdvertPro";
import checkDismiss from "../context/checkDismiss";
import { checkTokensStorage } from "../context/checkTokens";

const NavAdvert = ({ isPro }) => {
  const [advert, setAdvert] = useState(true);
  var loginObj = checkTokensStorage();
  var pro = isPro || loginObj.chkPro;
  return (
    <>
      {advert && !checkDismiss.getDismiss() && !pro && (
        <div className="advert advert__pro">
          <div className="advert--dismiss">
            <a
              className="js-dismiss"
              href="#"
              title="Close advertisement"
              onClick={(e) => {
                setAdvert(false);
                checkDismiss.setDismiss(true);
                e.preventDefault();
              }}
            >
              <i className="fa fa-times"></i>
            </a>
          </div>
          <div className="advert--content">
            <div className="advert--pro-graphic">
              <AdvertProsvg />
            </div>
            <div className="advert--copy">
              <h2>You're one step away from becoming a Pro user</h2>
              <p>
                The Trip Pro offering takes an already wonderful – and free –
                version of Trip and makes it even better. With more content,
                more functionality, no adverts and more, it really makes the
                Trip experience even better
              </p>
            </div>
            <div className="advert--cta">
              <Link
                to="/Pro"
                style={{ color: "black" }}
                className="btn btn-warning"
                title="Learn more about Pro"
              >
                Learn more about Pro
              </Link>
            </div>
          </div>
          <div className="advert--bg-graphic">
            <Advertbgsvg />
          </div>
        </div>
      )}
    </>
  );
};

export default NavAdvert;
