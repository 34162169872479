import React from "react";

export default () => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14.05 14.05"
  >
    <g>
      <path
        d="M7,11.54a4.51,4.51,0,1,1,3.19-1.32A4.5,4.5,0,0,1,7,11.54Z"
        fill="#89be49"
        fillRule="evenodd"
      ></path>
      <path
        d="M7,13.8a6.76,6.76,0,1,1,4.79-2A6.81,6.81,0,0,1,7,13.8Z"
        fill="none"
        stroke="#b3b3b3"
        strokeMiterlimit="10"
        strokeWidth="0.5"
        fillRule="evenodd"
      ></path>
    </g>
  </svg>
);
