import React, { useState } from "react";
import ErrorMdl from "../components/errorMdl";
import PriceListMdl from "../components/personal/PriceList";
import ProComponent from "../components/ProComponent";
const axios = require("axios");

const Pro = (props) => {
  const [errorshow, setErrorShow] = useState(false);
  const [hasError, setErrors] = useState(false);
  const [show, setShow] = useState(false);

  document.body.classList.remove("homepage");
  const handleModal = (e) => {
    setShow(true);
    e.preventDefault();
  };
  return (
    <div>
      <ErrorMdl error={hasError} setErrorShow={setErrorShow} show={errorshow} />
      <PriceListMdl setShow={setShow} show={show}></PriceListMdl>

      <ProComponent
        isPro={props.isPro ? props.isPro : null}
        handleModal={handleModal}
      ></ProComponent>
    </div>
  );
};

export default Pro;
