import React, { useState, useEffect } from "react";

const qs = require("query-string");
const store = require("store");
const RecentSearch = ({ props, getHistory }) => {
  const [search_history, setSearchHisotry] = useState("");

  // var search_history = "";
  const clearHistory = () => {
    store.remove("recent");
    setSearchHisotry("");
  };
  const handleSearch = (event, criteria) => {
    var prepare_qs = criteria ? "?" + qs.stringify(criteria) : "";
    props.history.push({
      pathname: "/Searchresult",
      search: prepare_qs,
      state: criteria ? criteria : "",
    });
    event.preventDefault();
  };

  // search_history = getHistory().reverse();
  useEffect(() => {
    setSearchHisotry(getHistory().reverse());
  }, []);
  return (
    props.isPro &&
    search_history && (
      <div className="notification notification--nhs mt-0">
        <div className="search-term--related-container mt-0">
          <ul>
            {search_history.map((value, idx) => (
              <li key={idx}>
                <a
                  key={"tag" + idx}
                  className="type-small"
                  href="#"
                  onClick={(e) => {
                    handleSearch(e, value);
                    e.preventDefault();
                  }}
                >
                  #{idx + 1}{" "}
                  {value.criteria
                    ? value.criteria
                    : value.anywords
                    ? value.anywords
                    : value.exactphrase
                    ? value.exactphrase
                    : value.excludingwords
                    ? value.excludingwords
                    : ""}{" "}
                </a>
              </li>
            ))}
          </ul>
          {search_history && (
            <a
              href="!#"
              onClick={(e) => {
                clearHistory();
                e.preventDefault();
              }}
            >
              Clear Recent
            </a>
          )}
        </div>
      </div>
    )
  );
};
export default RecentSearch;
