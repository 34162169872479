import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Card, Alert, Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Sidebar from "../components/SidebarRegistration";
import ErrorMdl from "../components/errorMdl";
import _ from "underscore";
import { useAuth } from "./../context/auth";

const axios = require("axios");
const Registration = (props) => {
  const [validated, setValidated] = useState(false);
  const [hasError, setErrors] = useState(false);
  const [hasSuccess, setSuccess] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [gdpr, setGDPR] = useState("");
  const [terms, setTerms] = useState("");
  const [errorshow, setErrorShow] = useState(false);

  const { setAuthTokens } = useAuth();

  async function Login() {
    axios
      .post("/Auth/Login", {
        username: email,
        password: password,
      })

      .then((res) => {
        if (res) {
          if (!_.isUndefined(res.status) && res.status === 200) {
            setAuthTokens(res.data);
          } else {
            //todo setError;
            setErrors(res.Error);
            setErrorShow(true);
          }
        } else {
          // const error = new Error(res.error);
          setErrors("Invalid User name or Password");
          setErrorShow(true);
        }
        //callback for the response
      })
      .catch((error) => {
        setErrors(error);
        setErrorShow(true);
      });
  }
  const handleSubmit = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      axios
        .post("/Registration/saveRegistration", {
          email: email,
          password: password,
          confirmpassword: confirmpassword,
          terms: terms,
          GDPR: gdpr,
        })
        .catch((error) => {
          console.log(error);
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.status == false) {
              setErrors(res.data);
              setErrorShow(true);
            } else {
              setSuccess(true);
              Login();
              window.setTimeout(() => {
                setSuccess(false);
                props.history.push({
                  pathname: "/Profile",
                  state: {
                    withCredentials: true,
                  },
                });
              }, 2000);
            }
          } else {
            const error = new Error(res.error_message);
            setErrors(error);
            setErrorShow(true);
          }
        });
    }
    e.preventDefault();
    setValidated(true);
  };
  useEffect(() => {
    document.title = "Signup - Trip Medical Database";
    document.body.classList.remove("homepage");
  }, []);
  return (
    <main role="main" className="main mt-2">
      <ErrorMdl error={hasError} setErrorShow={setErrorShow} show={errorshow} />
      <Helmet>
        <meta
          name="description"
          content="Evidence-based answers for health professionals | Searching sources such as systematic reviews, clinical guidelines and RCTs"
        />
      </Helmet>
      <Row></Row>
      <Row>
        <Col md="3">
          <Sidebar {...props} />
        </Col>
        <Col md="6">
          <Card>
            <Card.Body>
              <Card.Header>
                <h2>Create your Trip Account</h2> It opens up a host of features
                and helps us to keep in touch with you
              </Card.Header>

              <br></br>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group controlId="Email">
                  <Form.Label>
                    <i className="fa fa-envelope"></i> Email address
                  </Form.Label>
                  <Form.Control
                    type="email"
                    name="Email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a valid email
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="Password">
                  <Form.Label>
                    <i className="fa fa-lock"></i> Password
                  </Form.Label>
                  <Form.Control
                    type="password"
                    name="Password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    minLength={8}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a password - minimum length 8 characters
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="ConfirmPassword">
                  <Form.Label>
                    <i className="fa fa-lock"></i> Confirm Password
                  </Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Confirm Password"
                    name="ConfirmPassword"
                    value={confirmpassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    minLength={8}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide a password - minimum length 8 characters
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group id="GDPRcheckbox">
                  <Form.Check
                    type="checkbox"
                    name="GDPRcheckbox"
                    onChange={(e) => setGDPR(e.target.checked)}
                    feedback="You must agree to GDPR requirements in order to proceed"
                    label="Click here to allow Trip Database to send me occasional emails relating
to Trip and its products (such as new features, evidence, etc).
Under GDPR this constitutes marketing so we need your permission"
                  />
                </Form.Group>

                <Form.Group id="Termscheckbox">
                  <Form.Check
                    type="checkbox"
                    name="Termscheckbox"
                    // value={"1"}
                    //checked={terms === "on"}
                    onChange={(e) => setTerms(e.target.checked)}
                    label="I accept the Terms of use"
                    required
                    feedback="You must agree to the terms and condition in order to proceed"
                  />
                </Form.Group>
                {hasSuccess ? (
                  <div className="pull-center">
                    <Spinner animation="border" variant="success" />
                  </div>
                ) : (
                  <Button
                    className="btn btn-success pull-right btn-lg"
                    type="submit"
                  >
                    <i className="fa fa-register"></i> Register
                  </Button>
                )}
                {hasSuccess ? (
                  <Alert variant="success pull-left" dismissible>
                    <div>Registration Successfull!</div>
                  </Alert>
                ) : (
                  ""
                )}
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </main>
  );
};

export default Registration;
