import React from "react";
import { Form, Button, Modal } from "react-bootstrap";
import _ from "underscore";
import "react-datepicker/dist/react-datepicker.css";
const GuidelinesMdl = ({
  guidelines,
  setMethodology,
  setEvidenceGrading,
  setSystematicEvidence,
  setFunding,
  setConflict,
  setComments,
  handleClose,
  handleGuidelines,
  validated,
  show,
}) => {
  // var catdata = categorydata.categories ? categorydata.categories : [];
  return (
    <Modal show={show} onHide={handleClose} animation={false} size="xl">
      <Form
        width="100%"
        noValidate
        validated={validated}
        onSubmit={handleGuidelines}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-success">
            <i className="fa fa-book"></i> Add/Update Guidelines (
            {guidelines.publication ? guidelines.publication : ""})
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="guidelines">
            <Form.Label>
              <i className="fa fa-upload"></i> Do they publish their
              methodology?
            </Form.Label>
            <Form.Control
              type="text"
              name="methodology"
              placeholder="Methodology"
              value={guidelines.methodology ? guidelines.methodology : "0"}
              onChange={(e) => setMethodology(e.target.value)}
              required
            />
            <Form.Control.Feedback type="invalid">
              *Methodology score required
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="evidencegrading">
            <Form.Label>
              <i className="fa fa-compass"></i> Do they use any evidence
              grading?
            </Form.Label>
            <Form.Control
              type="text"
              name="evidencegrading"
              placeholder="Evidence grading"
              value={
                guidelines.evidencegrading ? guidelines.evidencegrading : "0"
              }
              onChange={(e) => setEvidenceGrading(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="form.sysevidence">
            <Form.Label>
              <i className="fa fa-link"></i> Do they undertake a systematic
              evidence search?
            </Form.Label>
            <Form.Control
              type="text"
              defaultValue={
                guidelines.systematicevidence
                  ? guidelines.systematicevidence
                  : "0"
              }
              placeholder="Systematic Evidence"
              onChange={(e) => setSystematicEvidence(e.target.value)}
              required
            ></Form.Control>
            <Form.Control.Feedback type="invalid">
              *Systematic Evidence required
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="funding">
            <Form.Label>
              <i className="fa fa-dollar-sign"></i> Are they clear about
              funding?
            </Form.Label>
            <Form.Control
              type="text"
              name="funding"
              placeholder="Funding"
              value={guidelines.funding ? guidelines.funding : "0"}
              onChange={(e) => setFunding(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="conflict">
            <Form.Label>
              <i className="fa fa-anchor"></i> Do they mention how they handle
              conflict of interest?
            </Form.Label>
            <Form.Control
              type="text"
              name="conflict"
              value={guidelines.conflict ? guidelines.conflict : "0"}
              onChange={(e) => setConflict(e.target.value)}
              required
            />
            <Form.Control.Feedback type="invalid">
              *Conflict score required
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="comments">
            <Form.Label>
              <i className="fa fa-anchor"></i> Comments (optional)
            </Form.Label>
            <Form.Control
              type="text"
              name="comments"
              value={guidelines.comments ? guidelines.comments : ""}
              onChange={(e) => setComments(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            <i className="fa fa-times"></i> Close
          </Button>
          <Button variant="primary success" type="submit">
            <i className="fa fa-disc"></i> Save Changes
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default GuidelinesMdl;
