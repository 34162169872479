import React, { useState } from "react";
// import { OverlayTrigger, Tooltip } from "react-bootstrap";
import GuidelinesGraphics from "./GuidelinesGraphics";
import GuidelinesNoGraphics from "./GuidelinesNoGraphics";
const _ = require("underscore");

const GuidelinesScore = ({
  score,
  handleGuidelines,
  publication_id,
  category_guideline,
}) => {
  let total = 0;
  let total_score = 8;
  score = _.isArray(score) ? score[0] : null;
  if (!_.isNull(score) && score > 0) {
    total = (score * 100) / total_score;
    total = total.toFixed();
  }
  return !_.isNull(score) ? (
    <GuidelinesGraphics
      score={total}
      publication_id={publication_id}
      handleGuidelines={handleGuidelines}
    ></GuidelinesGraphics>
  ) : category_guideline ? (
    <GuidelinesNoGraphics />
  ) : null;

};
export default GuidelinesScore;
