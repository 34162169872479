import React, { useState, useEffect } from "react";
import { Card, CardDeck, Row, Col, Button, Alert } from "react-bootstrap";
import SidebarAdmin from "../../components/SidebarAdmin";

const CMS = (props) => {
  useEffect(() => {
    document.title = "CMS - Trip Medical Database";
    document.body.classList.add("admin-user");
  }, []);
  return (
    <main role="main" className="container-fluid main mt-2">
      <Row>
        <SidebarAdmin></SidebarAdmin>

        <Col>
          <Row className="mt-2 ml-1">
            <CardDeck>
              <Card style={{ width: "18rem" }}>
                <Card.Body>
                  <Card.Text>
                    <Card.Img
                      variant="top"
                      height="240"
                      width="80"
                      src={require("../../components/assets/users.png")}
                    />
                  </Card.Text>
                  <Card.Link
                    href="/Admin/Users"
                    className="stretched-link"
                  ></Card.Link>
                </Card.Body>
                <Card.Footer>
                  <h5>
                    <i className="fa fa-users fa-lg"></i> Users
                  </h5>
                </Card.Footer>
              </Card>

              <Card style={{ width: "18rem" }}>
                <Card.Body>
                  <Card.Text>
                    <Card.Img
                      variant="top"
                      height="240"
                      width="80"
                      src={require("../../components/assets/publications.png")}
                    />
                  </Card.Text>
                  <Card.Link
                    href="/Admin/Publications"
                    className="stretched-link"
                  ></Card.Link>
                </Card.Body>
                <Card.Footer>
                  <h5>
                    <i className="fa fa-clipboard fa-lg"></i> Publications
                  </h5>
                </Card.Footer>
              </Card>

              <Card style={{ width: "18rem" }}>
                <Card.Body>
                  <Card.Text>
                    <Card.Img
                      variant="top"
                      height="240"
                      width="80"
                      src={require("../../components/assets/settings.png")}
                    />
                  </Card.Text>
                  <Card.Link
                    href="/Admin/Settings"
                    className="stretched-link"
                  ></Card.Link>
                </Card.Body>
                <Card.Footer>
                  <h5>
                    <i className="fa fa-university fa-lg"></i> Institutions
                  </h5>
                </Card.Footer>
              </Card>

              <Card style={{ width: "18rem" }}>
                <Card.Body>
                  <Card.Text>
                    <Card.Img
                      variant="top"
                      height="240"
                      width="80"
                      src={require("../../components/assets/emails.png")}
                    />
                  </Card.Text>
                  <Card.Link
                    href="/Admin/Emails"
                    className="stretched-link"
                  ></Card.Link>
                </Card.Body>
                <Card.Footer>
                  <h5>
                    <i className="fa fa-envelope fa-lg"></i> Emails
                  </h5>
                </Card.Footer>
              </Card>
            </CardDeck>
          </Row>
        </Col>
      </Row>
    </main>
  );
};

export default CMS;
