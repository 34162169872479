import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import _ from "underscore";

import { Button, Modal, Form, Spinner, Alert } from "react-bootstrap";

const axios = require("axios");
const ProMdl = ({ props, show, setProShow, setSignupShow }) => {
  useEffect(() => {
    document.title = "Signup - Trip Medical Database";
    document.body.classList.remove("homepage");
  }, []);
  return (
    <>
      <Modal
        size="lg"
        id="goPro-mdl"
        className="go-pro-modal"
        show={show}
        onHide={(e) => setProShow(false)}
        onShow={(e) => {
          let el = document.getElementById("goPro-mdl");
          if (el) el.classList.add("modal-dialog--login");
        }}
        animation={false}
        centered
      >
        <Modal.Header closeButton>
          <div className="logo">
            <svg
              id="Layer_1"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 165.17 104.41"
            >
              <g>
                <path
                  d="M59.36,9.66V6.2Q59.36.62,56,.62H3.34Q0,.62,0,6.2V9.66q0,5.56,3.34,5.57H21.05v61.9H38.31V15.23H56q3.34,0,3.34-5.57M78.13,21.79q-7.47,0-12.14,8.48a17,17,0,0,1-.39-1.79,17.79,17.79,0,0,0-.5-2.06c-.15-.41-.38-.93-.67-1.56a3.23,3.23,0,0,0-1.17-1.4A6.56,6.56,0,0,0,60.31,23a38.78,38.78,0,0,0-6.74.89q-4.51.9-4.51,2.68a32.78,32.78,0,0,0,.67,4,69.65,69.65,0,0,1,.67,11.71V77.13H66.88V41.87c2.23-3.72,5.19-5.58,8.91-5.58a11.35,11.35,0,0,1,2.89.45,11.28,11.28,0,0,0,2.34.44c1,0,1.79-1.17,2.45-3.51a22.09,22.09,0,0,0,1-5.86,6.75,6.75,0,0,0-1.4-4.18c-.93-1.22-2.58-1.84-5-1.84m81.25,8q-5.79-8-15-8a17.23,17.23,0,0,0-9,2.34,15.67,15.67,0,0,0-6,6,16.76,16.76,0,0,1-.39-1.68,19.62,19.62,0,0,0-.5-2,14.55,14.55,0,0,0-.67-1.62,3.23,3.23,0,0,0-1.17-1.4,6.53,6.53,0,0,0-3-.44,38.78,38.78,0,0,0-6.74.89q-4.52.9-4.51,2.68a32.78,32.78,0,0,0,.67,4,69.65,69.65,0,0,1,.67,11.71V99.49h16.48v-21a18.85,18.85,0,0,0,11.36,3.46q11.13,0,17.32-8.09t6.18-22.08q0-14-5.79-22M138.11,68.85a15.68,15.68,0,0,1-7.8-2.12V41.87q3.78-6.59,9.13-6.58,9,0,9,16.78T138.11,68.85M99.08,0H96c-4.46,0-6.68,1.19-6.68,3.57v8.11h16.48V3.57Q105.76,0,99.08,0m0,22.85H96c-4.46,0-6.68,1.19-6.68,3.57V77.13h16.48V26.42q0-3.57-6.68-3.57"
                  fill="#533764"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M37.33,77.53v.58Q37.33,82,31,82H27.26q-6.36,0-6.36-3.9v-.58Z"
                  fill="#63c608"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M67.19,77.53v.58q0,3.9-6.66,3.9h-3.1q-6.66,0-6.66-3.9v-.58Z"
                  fill="#0e6cbb"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M106,77.53v.92c0,2.38-2.22,3.56-6.65,3.56H96.25c-4.44,0-6.66-1.18-6.66-3.56v-.92Z"
                  fill="#00a89d"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M130.7,100.06v.9q0,3.45-6.55,3.45h-3.06q-6.56,0-6.55-3.45v-.9Z"
                  fill="#eec82f"
                  fillRule="evenodd"
                ></path>
                <path
                  d="M106,11.83v.92c0,2.38-2.22,3.56-6.65,3.56H96.25c-4.44,0-6.66-1.18-6.66-3.56v-.92Z"
                  fill="#ba390d"
                  fillRule="evenodd"
                ></path>
              </g>
            </svg>
          </div>
        </Modal.Header>
        <Modal.Body>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={(e) => setProShow(false)}
          >
            <span aria-hidden="true">×</span>
          </button>
          <h2 className="modal-title" id="loginModalLabel">
            Upgrade to Pro
          </h2>
          <p className="mt-2">
            Trip Pro offers more content, more functionality, no adverts and
            more
          </p>
          <h5>Personal Subscription:</h5>
          <button
            type="button"
            className="btn btn-default btn btn-primary"
            onClick={(e) => (window.location.href = "/Subscription")}
          >
            Become a pro user for just $55.00 a year
          </button>
          <p className="small-print mt-3">
            Don’t forget you can cancel subscription renewal at any time
          </p>
          <hr></hr>
          <h5>Institutional Subscription</h5>
          <a
            target="_blank"
            href="https://blog.tripdatabase.com/2021/07/03/institutional-subscriptions/"
            className="btn btn-primary "
          >
            Click here to see Institutional price list
          </a>
          <a
            href="mailto:enquiries@tripdatabase.com"
            target="_blank"
            className="btn btn-primary btn-outline mt-3"
          >
            <i className="fa fa-envelope mr-2"></i>Contact us about
            Institutional Subscription
          </a>
          <hr></hr>
          <p className="text-center text-muted mt-4 mb-2">
            Don't have an account yet?{" "}
            <a
              className="fw-bold text-body"
              href="#!"
              onClick={(e) => {
                let el = document.getElementById("nav-signup");
                el.click();
                setProShow(false);
                setSignupShow(true);
                e.preventDefault();
              }}
            >
              <u>Sign up here</u>
            </a>
          </p>
          {/* <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group controlId="Email">
              <Form.Label>
                <i className="fa fa-envelope"></i> Email address
              </Form.Label>
              <Form.Control
                type="email"
                name="Email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid email
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="Password">
              <Form.Label>
                <i className="fa fa-lock"></i> Password
              </Form.Label>
              <Form.Control
                type="password"
                name="Password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                minLength={8}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide a password - minimum length 8 characters
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="ConfirmPassword">
              <Form.Label>
                <i className="fa fa-lock"></i> Confirm Password
              </Form.Label>
              <Form.Control
                type="password"
                placeholder="Confirm Password"
                name="ConfirmPassword"
                value={confirmpassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                minLength={8}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide a password - minimum length 8 characters
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group id="GDPRcheckbox">
              <Form.Check
                type="checkbox"
                name="GDPRcheckbox"
                onChange={(e) => setGDPR(e.target.checked)}
                feedback="You must agree to GDPR requirements in order to proceed"
                label="Click here to allow Trip Database to send me occasional emails relating
to Trip and its products (such as new features, evidence, etc).
Under GDPR this constitutes marketing so we need your permission"
              />
            </Form.Group>

            <Form.Group id="Termscheckbox">
              <Form.Check
                type="checkbox"
                name="Termscheckbox"
                // value={"1"}
                //checked={terms === "on"}
                onChange={(e) => setTerms(e.target.checked)}
                label="I accept the Terms of use"
                required
                feedback="You must agree to the terms and condition in order to proceed"
              />
            </Form.Group>
            
            <hr></hr>
            <p className="text-center text-muted mt-4 mb-2">
              <Link
                to="/"
                onClick={(e) => {
                  setSignupShow(false);
                  setLoginShow(true);
                  e.preventDefault();
                }}
              >
                Have already an account?
              </Link>
            </p>
          </Form> */}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProMdl;
