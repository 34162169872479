import { Row, Col, Alert, Form, Button, Spinner, Badge } from "react-bootstrap";
import { ResultList } from "../components/search/ResultList";
import React, { useState, useEffect } from "react";
import ResultHeader from "../components/search/ResultHeader";
import ResultActions from "../components/search/ResultActions";
import ResultCategories from "../components/search/ResultCategories";
import Pagination from "../components/search/Pagination";
import EmailExportMdl from "../components/search/EmailExportMdl";
import { updateTokens } from "./../context/checkTokens";
import NHSLibkey from "../components/search/NHSLibkey";
import NHSLibkeyMdl from "../components/search/NHSLibkeyMdl";
import ConnectedResults from "../components/search/ConnectedResults";

import {
  setQueryStringValue,
  getQueryStringValue,
} from "../components/search/queryString";
import { useAuth } from "./../context/auth";
import _ from "underscore";
import { isMobile } from "react-device-detect";
const axios = require("axios");
const qs = require("query-string");
const store = require("store");

const Searchresult = (props) => {

  const parsed = qs.parse(props.location.search);
  const { authTokens, setAuthTokens } = useAuth();
  const [hasError, setErrors] = useState(false);
  const [data, setData] = useState({});
  const [userdata, setUserData] = useState({});
  const [userId, setUserId] = useState(null);
  const [catToggle, setCatToggle] = useState(true);
  const [currentPage, setCurrentPage] = useState(
    !_.isUndefined(parsed.page) ? parsed.page : 1
  );
  const [loading, setLoading] = useState(true);
  const [currentSort, setSort] = useState(
    !_.isUndefined(parsed.sort) ? parsed.sort : "r"
  );
  const [currentArea, setArea] = useState(
    !_.isUndefined(parsed.area) ? parsed.area : ""
  );
  const [currentParams, setParams] = useState("");
  const [currentTag, setTag] = useState(
    !_.isUndefined(parsed.tag) ? parsed.tag : null
  );
  const [currentfromDate, setfromDate] = useState(
    !_.isUndefined(parsed.from_date) ? parsed.from_date : null
  );
  const [currenttoDate, settoDate] = useState(
    !_.isUndefined(parsed.to_date) ? parsed.to_date : null
  );
  const [currentCategory, setCategory] = useState(
    !_.isUndefined(parsed.category) ? parsed.category : null
  );
  const [currentPublication, setPublication] = useState(
    !_.isUndefined(parsed.pub) ? parsed.pub : null
  );
  const [currentF, setF] = useState(1);
  const [currentRepeatPico, setRepeatPico] = useState(true);
  const [isStarred, setStarred] = useState([]);
  const [snippetshow, setSnippetShow] = useState(!isMobile);
  const [exportshow, setExportShow] = useState(false);
  const [emailexportshow, setEmailExportShow] = useState(false);
  const [libkeymdlshow, setLibkeyMdlShow] = useState(false);
  const [emailexportmessage, setEmailExportMessage] = useState("");
  const [emailexportto, setEmailExportTo] = useState("");
  const [exportbuttonshow, setExportbuttonShow] = useState(true);
  const [articleChecked, setArticleChecked] = useState([]);

  // the set of results we want to use as source for Connected 
  const [articleConnect, setArticleConnect] = useState([])
  
  // the set of results that have been clicked on
  const [articleClicked, setArticleClickedOrig] = useState([]);

  // proxy for setArticleClickedOrig which also calls setArticleConnect if length>=2
  const setArticleClicked = (value) => {
    if (value && value.length>=2) {
      setArticleConnect(value);
    } else {
      setArticleConnect([]);
    }
    return setArticleClickedOrig(value)
  }

  // helper method passed in to ResultList - when "ShowRelated" is clicked for a document, we set that as the source for "Connected"
  const showRelated=( document ) => {
    setArticleConnect([document] );
  }

  // when only one document is the source for Connected, auto-submit 
  useEffect(()=>{
    if (articleConnect?.length===1) {
      postToConnectedService();
    }
  }, [articleConnect]);

  const [exportdata, setExportData] = useState("");
  const [exporttype, setExportType] = useState(".CSV");
  const [disableGo, setGo] = useState(true);
  const [proNotification, setProNotification] = useState(false);
  const [isInstitutionPro, setInstitutionPro] = useState({});
  const [firstPage, setFirstPage] = useState(1);
  const [continousProCheck, setContinousProCheck] = useState(0);
  const [categoryshow, setCategoryShow] = useState(!isMobile);
  const [childInstitutions, setChildInstitutions] = useState([]);
  const [institutionLibId, setInstitutionLibId] = useState("");
  const [previousCriteria, setPreviousCriteria] = useState("");
  const [dois, setDOIs] = useState(null);

  const [connectedResults, setConnectedResults] = useState(null);

  const defaultFactors={ top:5, clicks:1, referenced:1, related:1, cites:1, keywords:1, included:1 };
  const localFactorsKey="connectedFactors"
  const localFactorsJson=localStorage.getItem(localFactorsKey);
  const initialFactors=localFactorsJson?JSON.parse(localFactorsJson):defaultFactors;
  const [factors, setFactorsOrig] = useState(initialFactors);
  const setFactors=newFactors => { setFactorsOrig(newFactors); localStorage.setItem( localFactorsKey, JSON.stringify(newFactors) ) };
  const [connectedSearchId,setConnectedSearchId] = useState(null);

  // calculate if the current user is Pro or not, depending on their own/their institution
  const isPro=(!_.isUndefined(props.isPro) ? props.isPro : false) || (!_.isEmpty(isInstitutionPro) ? true : false)

  function onClickConnected( document ) {
    console.log('onClickConnected', document );

    axios
      .post("https://wfu1t3sekd.execute-api.us-east-1.amazonaws.com/onclick", {
        document: document,
        searchId: connectedSearchId
      }, {
        withCredentials: false
      })
      .then( () => {
        
        console.log('onClick recorded')
      })
      .catch((error) => {
        console.log(error);
      });    
  }

  function postToConnectedService() {
    console.log('Posting to Connected service')
    const startTime=new Date();
    
    factors.top = isPro? 100:3; 

    setConnectedResults({ summary:'Calculating...'});

    axios
      .post("https://wfu1t3sekd.execute-api.us-east-1.amazonaws.com/", {
        documents: articleConnect,
        keywords: currentParams.criteria || '',
        userId: userId,
        factors: factors
      }, {
        withCredentials: false
      })
      .then( result => {

        const results=result?.data?.results || {};

        setConnectedSearchId( results.searchId );

        if (results.documents && results.documents.length) {
          results.documents.map( (document,index ) => { document.index=index })
        } 

        const endTime = new Date();
        const elapsed = (endTime-startTime);
        
        results.metrics=`Elapsed: ${elapsed}ms`;

        setConnectedResults( results );
        
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // if the set of clicked articles changes, and we're already displaying connected results - resubmit
  useEffect(() => {
    if (connectedResults) {
      postToConnectedService();
    }
  }, [articleClicked]);

  async function fetchData() {

    const searchParams = getParameters();
    console.log('/Search',searchParams);

    axios
      .get("/Search", {
        params: searchParams,
      })

      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
          setLoading(false);
          setF(
            !_.isUndefined(res.data.summary.fvalue)
              ? res.data.summary.fvalue
              : 1
          );
          setRepeatPico(
            !_.isUndefined(res.data.summary.repeat_pico)
              ? res.data.summary.repeat_pico
              : 1
          );
          setStarred(
            !_.isUndefined(res.data.summary.starred_data)
              ? res.data.summary.starred_data
              : []
          );
          setInstitutionPro(
            !_.isUndefined(res.data.summary.institution_auth)
              ? res.data.summary.institution_auth
              : {}
          );

          // only clear the list of clicked articles if the search terms have changed
          if (previousCriteria!==searchParams.criteria) {
            setArticleClicked([]);
            setPreviousCriteria(searchParams.criteria);
          }

          continousAuth(res);
        } else {
          const error = new Error(res.error_message);
          //setErrors(res.error_message);
        }
      })
      .catch((error) => {
        //console.log(error);
        // setErrors(error.response.data.error_message);
        setLoading(false);
      });
  }
  /**
   * Continous Authentication/Pro Check
   * @param {*} res
   */
  function continousAuth(res) {
    
    if (
      !_.isUndefined(res.data.summary.institution_auth) ||
      !_.isUndefined(res.data.summary.isPro)
    ) {
      
      if (!res.data.summary.isPro)
        setAuthTokens(updateTokens(authTokens, "isPro", ""));

      if (_.isNull(res.data.summary.institution_auth))
        store.set("instution_auth_tokens", { institution_auth: null });

      setContinousProCheck(res.data.summary.isPro);
      if (!res.data.summary.isPro) {
        setAuthTokens(updateTokens(authTokens, "isPro", ""));
        store.remove("recent");
      }

      if (res.data.summary.user) {
        setUserId(res.data.summary.user.user_id);
      }
    }
  }
  /**
   * Export Search
   * @param {*} keywords
   */
  async function fetchExport(keywords) {
    axios
      .get("/exportSearch", {
        params: getParameters(),
      })

      .then((res) => {
        if (res.status === 200) {
          // console.log(filename);
          //CSV working
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          const filename = !_.isUndefined(res.headers)
            ? res.headers["content-disposition"].split('"')[1]
            : exporttype === ".CSV"
            ? "tripexport.csv"
            : "tripexport.ris";
          link.href = url;
          link.setAttribute("download", filename);

          document.body.appendChild(link);
          link.click();
          setExportbuttonShow(true);
        } else {
          const error = new Error(res.error_message);
          setErrors(res.error_message);
        }
      })
      .catch((error) => {
        setProNotification(true);
        setExportbuttonShow(false);
        setErrors("Access Denied! Unauthorized to perform this action.");
        setLoading(false);
      });
  }
  /**
   * email export
   * @param {*} resultset
   */
  async function emailExport(resultset) {
    var params = getParameters();
    var checklist = [];

    for (let res of resultset) {
      checklist.push(res.id);
    }
    params["resultset"] = checklist;
    params["articlesChecked"] = articleChecked;

    axios
      .post("/emailSearch", {
        params: params,
      })

      .then((res) => {
        if (res.status === 200) {
          if (_.isUndefined(res.data.error_message)) {
            setEmailExportMessage(
              !_.isUndefined(res.data.email) ? res.data.email.message : ""
            );
            setExportbuttonShow(true);
            setUserData(res.data);
            setEmailExportShow(true);
          } else {
            // const error = new Error(res.error_message);
            setErrors(res.data.error_message);
            setExportbuttonShow(true);
          }
        }
      })
      .catch((error) => {
        setExportbuttonShow(true);
        setProNotification(true);
        setErrors(error);
        setLoading(false);
      });
  }
  /**
   * sendEmail Export
   * @param {*} resultset
   */
  async function sendEmailExport() {
    axios
      .post("/emailExportSend", {
        params: { to: emailexportto, message: emailexportmessage },
      })

      .then((res) => {
        if (res.status === 200) {
          if (_.isUndefined(res.data.error_message)) {
            setUserData(res.data);
          } else {
            // const error = new Error(res.error_message);
            setErrors(res.data.error_message);
            setExportbuttonShow(true);
          }
        }
      })
      .catch((error) => {
        setExportbuttonShow(true);
        setProNotification(true);
        setErrors(error);
        setLoading(false);
      });
  }
  useEffect(() => {
    document.body.classList.remove("homepage");
    var criteria = !_.isUndefined(props.location.state)
      ? props.location.state.criteria
      : "";
    // document.title = criteria + " -  Trip Database";
    fetchData(criteria);
  }, [
    currentPage,
    currentSort,
    currentArea,
    currentTag,
    currentfromDate,
    currentCategory,
    currentPublication,
    institutionLibId,
  ]);

  /**
   * prepare search parameters
   */
  function getParameters() {
    var search_type = !_.isUndefined(props.location.state)
      ? props.location.state.search_type
      : getQueryStringValue("search_type");
    var params = {
      criteria: !_.isUndefined(props.location.state)
        ? props.location.state.criteria
        : getQueryStringValue("criteria"),
      search_type: !_.isUndefined(search_type) ? search_type : "standard",
      page: currentPage,
      sort: currentSort,
      clinical: currentArea,
      category_id: currentCategory,
      tags: currentTag,
      from_date: currentfromDate,
      to_date: currenttoDate,
      publication_id: currentPublication,
      export_data: exportdata,
      export_type: exporttype,
      articles: articleChecked,
      from_date_full: getQueryStringValue("from_date_full"),
    };
    document.title = params.criteria + " -  Trip Database";

    switch (search_type) {
      case "pico":
        params["intervention"] = !_.isUndefined(props.location.state)
          ? props.location.state.intervention
          : getQueryStringValue("intervention");
        params["comparison"] = !_.isUndefined(props.location.state)
          ? props.location.state.comparison
          : getQueryStringValue("comparison");
        params["outcome"] = !_.isUndefined(props.location.state)
          ? props.location.state.outcome
          : getQueryStringValue("outcome");
        params["f"] = currentF;
        params["repeat_pico"] = currentRepeatPico;

        break;
      case "advanced":
        params["allwordsfield"] = !_.isUndefined(props.location.state)
          ? props.location.state.allwordsfield
          : getQueryStringValue("allwordsfield");
        params["anywordsfield"] = !_.isUndefined(props.location.state)
          ? props.location.state.anywordsfield
          : getQueryStringValue("anywordsfield");
        params["exactphrasefield"] = !_.isUndefined(props.location.state)
          ? props.location.state.exactphrasefield
          : getQueryStringValue("exactphrasefield");
        params["excludingwordsfield"] = !_.isUndefined(props.location.state)
          ? props.location.state.excludingwordsfield
          : getQueryStringValue("excludingwordsfield");
        params["anywords"] = !_.isUndefined(props.location.state)
          ? props.location.state.anywords
          : getQueryStringValue("anywords");
        params["exactphrase"] = !_.isUndefined(props.location.state)
          ? props.location.state.exactphrase
          : getQueryStringValue("exactphrase");
        params["excludingwords"] = !_.isUndefined(props.location.state)
          ? props.location.state.excludingwords
          : getQueryStringValue("excludingwords");
        params["advfrom"] = !_.isUndefined(props.location.state)
          ? props.location.state.advfrom
          : getQueryStringValue("advfrom");
        params["advto"] = !_.isUndefined(props.location.state)
          ? props.location.state.advto
          : getQueryStringValue("advto");
        params["proximity"] = !_.isUndefined(props.location.state)
          ? props.location.state.proximity
          : getQueryStringValue("proximity");
        break;
    }
    setParams(params);
    return params;
  }
  /**
   * Todo : Improve this further
   * Safety locks to look for empty data sets
   */
  var summary_set = "",
    documents_set = "",
    categories_set = "",
    categories_set_child = "",
    categories_pro = "",
    libkeydata = "",
    clinical_area_set = [],
    refinements_set = [],
    publications_set = [];
  if (data && data.summary) {
    const { summary, documents } = data;

    summary_set = summary;
    documents_set = documents;
    categories_set = summary.all_categories ? summary.all_categories : [];
    categories_set_child = summary.child_categories
      ? summary.child_categories
      : [];
    categories_pro = summary.pro_categories ? summary.pro_categories : [];
    clinical_area_set = summary.clinical_area ? summary.clinical_area : [];
    refinements_set = summary.refinements ? summary.refinements : [];
    libkeydata = summary.libkeydata ? summary.libkeydata : null;

    publications_set = summary.publications_data
      ? summary.publications_data
      : [];
  }

  /**
   * Change Page function
   * @param {int} pageNumber
   */
  const paginate = (e, pageNumber) => {
    setQueryStringValue("page", pageNumber);
    setCurrentPage(pageNumber);
    window.scrollTo({
      top: 180,
      left: 0,
      behavior: "smooth",
    });
    e.preventDefault();
  };
  /**
   * Sort Selection
   * @param {*} e
   * @param {*} pageNumber
   */
  const sortSelection = (sort_selection) => {
    setQueryStringValue("sort", sort_selection);
    setSort(sort_selection);
  };
  /**
   * category Selection
   * @param {*} e
   * @param {*} category
   */
  const categorySelect = (e, category) => {
    if (category == currentCategory) {
      category = "";
    }
    setQueryStringValue("category", category);
    setQueryStringValue("page", 1);
    setCategory(category);
    setCurrentPage(1);
    e.preventDefault();
  };
  const suggestionSelect = (e, keyword) => {
    setQueryStringValue("criteria", keyword);
    //setCri(category);
    e.preventDefault();
  };
  /**
   * clinical area Selection
   * @param {*} e
   * @param {*} area
   */
  const clinicalareaSelect = (e, area) => {
    let value = e.target.checked ? area : "";
    setQueryStringValue("area", value);
    setArea(value);
    setCurrentPage(1);
    setQueryStringValue("page", 1);
    e.preventDefault();
  };

  /**
   * refinement Selection
   * @param {*} e
   * @param {*} type
   * @param {*} value
   */
  const refinementSelect = (e, type, val) => {
    let value = e.target.checked ? val : "";

    switch (type) {
      case "activity":
        break;
      case "tag":
        setQueryStringValue("tag", value);
        setTag(value);
        break;
      case "from_date":
        setQueryStringValue("from_date", value);
        setfromDate(value);
        break;
    }

    e.preventDefault();
  };

  /**
   * publication Selection
   * @param {*} e
   * @param {*} publication
   */
  const publicationSelect = (e, publication) => {
    if (publication == currentPublication) {
      publication = "";
    }
    setPublication(publication);
    setCurrentPage(1);
    setQueryStringValue("pub", publication);
    setQueryStringValue("page", 1);

    e.preventDefault();
  };
  const handleExport = (event) => {
    var pro =
      (!_.isUndefined(props.isPro) ? props.isPro : false) ||
      (!_.isEmpty(isInstitutionPro) ? true : false);

    if (pro && continousProCheck) {
      setProNotification(false);
      setExportbuttonShow(false);
      if (exporttype == "Email") emailExport(documents_set);
      else fetchExport();
    } else {
      setProNotification(true);
    }
  };
  const handleClose = (e) => {
    setEmailExportShow(false);
    setExportbuttonShow(true);
    setLibkeyMdlShow(false);
    // e.preventDefault();
  };
  const handleEmailExport = (e) => {
    // emailExport(documents_set);
    sendEmailExport();
    setEmailExportShow(false);
    e.preventDefault();
  };
  var current_selections = {
    currentArea: currentArea,
    currentTag: currentTag,
    currentfromDate: currentfromDate,
    currentCategory: currentCategory,
  };

  return (
    <div className="container-fluid">
      <EmailExportMdl
        user={userdata}
        handleClose={handleClose}
        handleEmailExport={handleEmailExport}
        show={emailexportshow}
        emailexportto={emailexportto}
        emailexportmessage={emailexportmessage}
        setEmailExportMessage={setEmailExportMessage}
        setEmailExportTo={setEmailExportTo}
      />
      <NHSLibkeyMdl
        childInstitutions={childInstitutions}
        handleClose={handleClose}
        show={libkeymdlshow}
        setInstitutionLibId={setInstitutionLibId}
      />
      <Row className="row search-term-container mt-0">
        <ResultHeader
          props={props}
          totalArticles={
            summary_set && summary_set.total ? summary_set.total : ""
          }
          loading={loading}
          keyword={
            !_.isUndefined(props.location.state)
              ? props.location.state.criteria
              : ""
          }
          sort={currentSort}
          categories_pro={categories_pro}
          currentParams={currentParams}
        />
      </Row>
      <hr></hr>

      <div className="row result-filters-row">
        {/* Sidebar */}
        <div className="col-sm-auto result-filters-container">
          <ResultCategories
            isPro={isPro}
            isInstitutionPro={isInstitutionPro}
            categories_set={categories_set}
            categories_set_child={categories_set_child}
            categories_pro={categories_pro}
            clinical_area_set={clinical_area_set}
            refinements_set={refinements_set}
            current_selections={current_selections}
            loading={loading}
            catToggle={catToggle}
            categorySelect={categorySelect}
            clinicalareaSelect={clinicalareaSelect}
            refinementSelect={refinementSelect}
            setCatToggle={setCatToggle}
          />
        </div>
        {/* End Sidebar */}
        {/* Results details */}
        <div className="col results-list-container">
          <div className="results-list">
            {/* REsult header  action */}
            <div className="results-actions-container results-actions-container__desktop">
              <NHSLibkey
                data={_.isObject(libkeydata) ? libkeydata : { id: 0 }}
                setLibkeyMdlShow={setLibkeyMdlShow}
                setChildInstitutions={setChildInstitutions}
                institutionLibId={institutionLibId}
              />
              <ResultActions
                isPro={isPro}
                sort={currentSort}
                sortSelection={sortSelection}
                setSnippetShow={setSnippetShow}
                snippetshow={snippetshow}
                setExportShow={setExportShow}
                setProNotification={setProNotification}
                exportshow={exportshow}
                isdesktop={true}
                categoryshow={categoryshow}
                setCategoryShow={setCategoryShow}
              />
              <hr></hr>
            </div>
            <div className="results-actions-container results-actions-container__mobile">
              <ResultActions
                isPro={isPro}
                sort={currentSort}
                sortSelection={sortSelection}
                setSnippetShow={setSnippetShow}
                snippetshow={snippetshow}
                setExportShow={setExportShow}
                setProNotification={setProNotification}
                exportshow={exportshow}
                isdesktop={false}
                categoryshow={categoryshow}
                setCategoryShow={setCategoryShow}
              />
              <hr></hr>
            </div>
            <div>
              {exportshow && (
                <Col md="12">
                  <Alert onClose={() => setExportShow(!exportshow)} dismissible>
                    <Alert.Heading>Export results</Alert.Heading>
                    <Row>
                      <Col md="8">
                        <div key={`inline-radio`} className="mb-3">
                          <Form.Check
                            inline
                            name="export_data"
                            label="Selected Results only"
                            type="radio"
                            id={`inline-radio-1`}
                            value="all_selected"
                            onChange={(e) => {
                              setExportData(e.target.value);
                              setGo(false);
                            }}
                            checked={exportdata === "all_selected"}
                          />
                          <Form.Check
                            inline
                            name="export_data"
                            label="All Results on this page"
                            type="radio"
                            id={`inline-radio-2`}
                            value="all_page"
                            onChange={(e) => {
                              setExportData(e.target.value);
                              setGo(false);
                            }}
                            checked={exportdata === "all_page"}
                          />
                          <Form.Check
                            inline
                            name="export_data"
                            label="All "
                            type="radio"
                            id={`inline-radio-3`}
                            value="all_results"
                            onChange={(e) => {
                              setExportData(e.target.value);
                              setGo(false);
                            }}
                            checked={exportdata === "all_results"}
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <Form.Control
                          as="select"
                          onChange={(e) => setExportType(e.target.value)}
                        >
                          <option>.CSV</option>
                          <option>.RIS</option>
                          <option>Email</option>
                        </Form.Control>
                      </Col>
                      <Col md="2">
                        {exportbuttonshow ? (
                          <Button
                            type="submit"
                            name="submit"
                            onClick={(e) => handleExport(e)}
                            disabled={disableGo}
                          >
                            Download <i className="fa fa-download"></i>
                          </Button>
                        ) : (
                          <Spinner animation="border" variant="success" />
                        )}
                      </Col>
                      <Col md="12">
                        <i className="fa fa-arrow-down"></i> Use check boxes to
                        select individual results below
                      </Col>
                      {proNotification && (
                        <Col md="12 mt-1">
                          <Alert key="pronotification" variant="warning">
                            Only <span className="pro-label">PRO</span> users
                            can use the Export feature.{" "}
                            <Alert.Link href="/Pro">
                              Get PRO Now <i className="fa fa-sign-in"></i>
                            </Alert.Link>
                            .
                          </Alert>
                        </Col>
                      )}
                    </Row>
                  </Alert>
                </Col>
              )}
            </div>
            {/* End Result header */}
            {/* Result article */}
            {hasError ? (
              <Alert variant="danger" dismissible>
                <Alert.Heading>{hasError}!</Alert.Heading>
                <p>
                  We cannot process your request, review your search parameters
                  and try again!
                </p>
              </Alert>
            ) : (
              ""
            )}
            <ResultList
              isPro={isPro}
              resultset={documents_set ? documents_set : []}
              publicationset={publications_set}
              start={summary_set.start}
              loading={loading}
              publicationSelect={publicationSelect}
              searchParams={currentParams}
              setStarred={setStarred}
              isStarred={isStarred}
              snippetshow={snippetshow}
              articleChecked={articleChecked}
              setArticleChecked={setArticleChecked}
              articleClicked={articleClicked}
              setArticleClicked={setArticleClicked}
              libkeydata={libkeydata}
              categorySelect={categorySelect}
              dois={dois}
              currentCategory={currentCategory}
              showRelated={showRelated}
            />
            <Pagination
              postsPerPage={20}
              totalArticles={
                summary_set && summary_set.total ? summary_set.total : 0
              }
              paginate={paginate}
              firstPage={firstPage}
              setFirstPage={setFirstPage}
              currentPage={currentPage}
            />
          </div>

          { articleConnect?.length!=0 && <ConnectedResults clicked={articleConnect} results={connectedResults} factors={factors} setFactors={setFactors} onSubmit={postToConnectedService} onClick={onClickConnected} isPro={isPro}/> }

        </div>
        {/* End Reuslt */}
      </div>
    </div>
  );
};

export default Searchresult;
