import React from "react";
import _ from "underscore";
import { Button, Form, Card, InputGroup, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
const store = require("store");

const LoginForm = ({
  logindata,
  handleLogin,
  setPassword,
  setUsername,
  setRememberme,
  rememberme,
  variant,
}) => {
  return (
    // <Card variant={variant}>
    //   <Card.Body>
    //     <Card.Header>
    //       <h2 className="modal-title" id="loginModalLabel">
    //         Login to your account
    //       </h2>{" "}
    //     </Card.Header>

    //     <br></br>
    <Form onSubmit={handleLogin}>
      <Form.Group controlId="Username">
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text id="prepend-email">
              <i className="fa fa-envelope"></i>
            </InputGroup.Text>
          </InputGroup.Prepend>

          <Form.Control
            type="email"
            name="Username"
            placeholder="Enter email"
            value={logindata.username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </InputGroup>
      </Form.Group>
      <Form.Group controlId="Password">
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text id="pre-password">
              &nbsp;&nbsp; <i className="fa fa-lock"></i>
            </InputGroup.Text>
          </InputGroup.Prepend>

          <Form.Control
            type="password"
            name="Password"
            placeholder="**********"
            value={logindata.password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </InputGroup>
      </Form.Group>
      <div className="form-group">
        <div className="form-check">
          <Form.Check
            label="    Remember me"
            onChange={(e) => {
              setRememberme(e.target.checked);
              //  remember(e.target.checked);
              if (e.target.checked === false) store.remove("remember");
            }}
            checked={rememberme}
          />
        </div>
      </div>

      <div className="form-footer">
        <Button
          className="btn btn-primary btn-lg pull-right btn btn-primary"
          type="submit"
        >
          <i className="fa fa-key"></i> Login
        </Button>
        <Link
          to="/Passwordreset"
          className="forgotten-password-link"
          type="button"
        >
          Forgot Password?
        </Link>
      </div>
      {/* <Form.Group as={Row} controlId="formHorizontalCheck">
          <div className="col-9">
            <Form.Check
              label="    Remember me"
              onChange={(e) => {
                setRememberme(e.target.checked);
                //  remember(e.target.checked);
                if (e.target.checked === false) store.remove("remember");
              }}
              checked={rememberme}
            />
          </div>
          <div className="col-3">
            <Link
              to="/Passwordreset"
              className="forgotten-password-link"
              type="button"
            >
              Forgot Password?
            </Link>
          </div>
        </Form.Group>

        <Button className="btn btn-primary btn-lg pull-right" type="submit">
          <i className="fa fa-key"></i> Login{" "}
        </Button> */}
    </Form>
    //   </Card.Body>
    // </Card>
  );
};

export default LoginForm;
