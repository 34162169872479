import React, { useState, useEffect, useMemo } from "react";
import {
  Tabs,
  Tab,
  Row,
  Col,
  Dropdown,
  Breadcrumb,
  Card,
  Table,
  Button,
} from "react-bootstrap";
// import styled from "styled-components";
// import AsyncSelect from "react-select/async";
import DataTable, { pagination } from "react-data-table-component";
import SidebarAdmin from "../../components/SidebarAdmin";
import ErrorMdl from "../../components/errorMdl";
import DeleteMdl from "../../components/deleteMdl";
import "../../components/assets/css/Tabs.css";
import "../../components/assets/css/Buttons.css";
import InstitutionMdl from "./InstitutionMdl";
import InstitutionReportMdl from "./institutionReportMdl";
import PaymentMdl from "./PaymentMdl";
import PaymentExpandedComponent from "../../components/PaymentExpandedComponent";

const dateFormat = require("dateformat");
const axios = require("axios");
const _ = require("underscore");
const { customStyles } = require("./tableStyle");

const Settings = (props) => {
  const [instshow, setInstShow] = useState(false);
  const [errorshow, setErrorShow] = useState(false);
  const [deleteshow, setDeleteShow] = useState(false);
  const [togglerefresh, setTogglerefresh] = useState(false);
  const [tabkey, setTabkey] = useState("institutions");
  const [hasError, setErrors] = useState(false);

  //institution
  const [instname, setInstName] = useState("");
  const [parentid, setParentid] = useState(null);
  const [instshortname, setInstshortName] = useState("");
  const [instemailregex, setInstEmailRegex] = useState("");
  const [instlinkresolverurl, setInstLinkResolverUrl] = useState("");
  const [instlinkresolvername, setInstLinkResolverName] = useState("");
  const [instlinkresolverimage, setInstLinkResolverImage] = useState("");
  const [instsignupcode, setInstSignupcode] = useState("");
  const [instipregex, setInstIpRegex] = useState("");
  const [libkeyaccessid, setLibkeyaccessid] = useState("");
  const [libkeyaccesstoken, setLibkeyaccesstoken] = useState("");
  const [oaorgid, setOAorgid] = useState("");
  const [oascope, setOAscope] = useState("");

  //Payment
  const [paymentshow, setPaymentShow] = useState(false);
  const [usershow, setUserShow] = useState(false);
  const [statshow, setStatsShow] = useState(false);

  var futuredate = new Date();
  futuredate.setFullYear(futuredate.getFullYear() + 1);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [amount, setAmount] = useState("");
  const [paid, setPaid] = useState("");
  const [notes, setNotes] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(futuredate);
  const [instAdmin, setInstAdmin] = useState("");

  //publication datatable
  const [data, setData] = useState([]);
  const [statsdata, setStatsData] = useState([]);
  const [statstotal, setStatsTotal] = useState(0);

  const [deletedata, setDeleteData] = useState([]);
  const [lookupdata, setLookupData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageStats, setCurrentPageStats] = useState(1);

  const [filterTextinst, setfilterTextinst] = useState("");
  const [resetPaginationToggleBatch, setResetPaginationToggleBatch] = useState(
    false
  );
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [currentaction, setAction] = useState("none");
  const [currentPerpage, setPerpage] = useState(10);
  const [currentTotal, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [currentSort, setSort] = useState();
  const [currentinstid, setInstid] = useState("");

  /**
   * fetchPublications
   * Send a call to express API for standard search
   * @param {*} keywords
   */
  async function fetchInstitutions() {
    axios
      .get("/Administrator/getInstitutions", {
        params: {
          page: currentPage,
          sort: currentSort,
          rows: currentPerpage,
          search: filterTextinst,
        },
      })

      .then((res) => {
        if (res.status === 200) {
          setData(res.data.results);
          setLookupData(res.data.lookupdata);
          setTotal(res.data.total);
          setLoading(false);
        } else {
          const error = new Error(res.error_message);
          setErrors(res.error_message);
          setErrorShow(true);
        }
      })
      .catch((error) => {
        setErrors(error.message);
        setErrorShow(true);
        setLoading(false);
      });
  }

  async function fetchInstitutionStats(id, page = 1) {
    setStatsShow(true);
    axios
      .get("/Administrator/getInstitutionStats", {
        params: {
          page: page,
          institution_id: id,
          rows: 10,
        },
      })

      .then((res) => {
        if (res.status === 200) {
          setStatsData(res.data);
          setStatsTotal(res.data.total);
        } else {
          const error = new Error(res.error_message);
          setErrors(res.error_message);
          setErrorShow(true);
        }
      })
      .catch((error) => {
        setErrors(error.message);
        setErrorShow(true);
        setLoading(false);
      });
  }

  useEffect(() => {
    fetchInstitutions();
  }, [
    currentPage,
    currentSort,
    currentPerpage,
    resetPaginationToggle,
    resetPaginationToggleBatch,
    togglerefresh,
  ]);

  const handlePageChange = (page) => {
    setLoading(true);
    setCurrentPage(page);
  };
  const handleInstitutionPageChange = (page) => {
    setCurrentPageStats(page);
    fetchInstitutionStats(currentinstid, page);
  };
  const handlePerRowsChange = (perPage, page) => {
    setLoading(true);
    setPerpage(perPage);
    setCurrentPage(page);
  };
  const handleSort = (column, sortDirection) => {
    setLoading(true);
    setSort([column.selector, sortDirection]);
  };

  const handleClose = () => {
    setInstShow(false);
    setPaymentShow(false);
    setStatsShow(false);
    setUserShow(false);
    setErrorShow(false);
    setCurrentPageStats(1);
  };

  const handleInstitutionAction = (e) => {
    e.preventDefault();
    switch (e.target.getAttribute("data-action")) {
      case "add-institution":
        setAction(e.target.getAttribute("data-action"));
        clearInstitution();
        setInstShow(true);
        break;
      case "edit-institution":
        setAction(e.target.getAttribute("data-action"));
        setInstid(e.target.getAttribute("data-value"));
        var getdata = _.findWhere(data, {
          id: parseInt(e.target.getAttribute("data-value"), 10),
        });
        setInstName(getdata.institution_name);
        setInstshortName(getdata.institution_shortname);
        setInstEmailRegex(getdata.emailregex);
        setInstLinkResolverImage(getdata.link_resolver_image);
        setInstLinkResolverName(getdata.link_resolver_name);
        setInstLinkResolverUrl(getdata.link_resolver_url);
        setInstSignupcode(getdata.signup_code);
        setInstIpRegex(getdata.ip_regex);
        setLibkeyaccesstoken(getdata.libkeyaccesstoken);
        setLibkeyaccessid(getdata.libkeylibraryid);
        setOAorgid(getdata.oa_organisation_id);
        setOAscope(getdata.oa_scope);
        setParentid(getdata.parentid);
        setInstShow(true);
        break;
      case "delete-institution":
        setTogglerefresh(!togglerefresh);
        break;
      case "addpayment-institution":
        setAction(e.target.getAttribute("data-action"));
        setInstid(e.target.getAttribute("data-value"));

        //fetchPayment(e.target.getAttribute("data-value"));
        var getdata = _.findWhere(data, {
          id: parseInt(e.target.getAttribute("data-value"), 10),
        });
        setFirstname(getdata.institution_shortname);
        setLastname("");
        setEmail(getdata.institution_shortname);
        setPaymentShow(true);
        break;
      case "adduser-institution":
        setAction(e.target.getAttribute("data-action"));
        setInstid(e.target.getAttribute("data-value"));

        //fetchPayment(e.target.getAttribute("data-value"));
        var getdata = _.findWhere(data, {
          id: parseInt(e.target.getAttribute("data-value"), 10),
        });
        setFirstname("");
        setLastname("");
        setEmail("");
        setUserShow(true);
        break;
      case "stats-institution":
        setCurrentPageStats(1);
        setAction(e.target.getAttribute("data-action"));
        setInstid(e.target.getAttribute("data-value"));
        var getdata = _.findWhere(data, {
          id: parseInt(e.target.getAttribute("data-value"), 10),
        });
        setInstName(getdata.institution_name);
        fetchInstitutionStats(e.target.getAttribute("data-value"));
        break;
    }
  };
  /**
   * User Institution
   * @param {*} params
   */
  async function UserInstitution(params) {
    axios
      .post("/Administrator/createInstitutionUser", params)
      .then((res) => {
        if (res.status === 200) {
          setTogglerefresh(!togglerefresh);
        } else {
          const error = new Error(res.error);
          setErrors(res.error);
          setErrorShow(true);
        }
      })
      .catch((error) => {
        setErrors(error);
        setErrorShow(true);
        setLoading(false);
      });
  }
  /**
   * Handle user Add
   * @param {*} event
   */
  const handleUserAdd = (event) => {
    UserInstitution({
      institution_id: currentinstid,
      user_id: email,
      is_admin: instAdmin,
    });
    event.preventDefault();
  };
  /**
   * Handle Admin Role
   * @param {*} event
   * @param {*} params
   */
  const handleAdmin = (event, params) => {
    UserInstitution({
      institution_id: params.institution_id,
      user_id: params.user_id,
      is_admin: params.is_admin,
    });
    event.preventDefault();
  };
  /**
   * Delete Institution
   * @param {*} event
   * @param {*} params
   */
  const handleUserRemove = (event, params) => {
    console.log(params);
    // "/Administrator/deleteInstitutionUser"
    axios
      .post("/Administrator/deleteInstitutionUser", params)
      .then((res) => {
        if (res.status === 200) {
          setTogglerefresh(!togglerefresh);
        } else {
          const error = new Error(res.error);
          setErrors(res.error);
          setErrorShow(true);
        }
      })
      .catch((error) => {
        setErrors(error);
        setErrorShow(true);
      });

    event.preventDefault();
  };
  const handleClear = () => {
    setResetPaginationToggle(!resetPaginationToggle);
    setfilterTextinst("");
  };
  const handlePayment = (event) => {
    axios
      .post("/Administrator/savePayment", {
        user_id: null,
        institution_id: currentinstid,
        date_from: startDate,
        date_to: endDate,
        amount: amount,
        paid: paid,
        notes: notes,
        withCredentials: true,
      })
      .then((res) => {
        if (res.status === 200) {
          setPaymentShow(false);
          setTogglerefresh(!togglerefresh);
        } else {
          const error = new Error(res.error_message);
          setErrors(res.error_message);
          setErrorShow(true);
        }
      })
      .catch((error) => {
        setErrors(error);
        setErrorShow(true);
        setLoading(false);
      });

    event.preventDefault();
  };

  const handleInstitution = (event) => {
    console.log("oa", oaorgid, oascope);
    axios
      .post("/Administrator/createInstitution", {
        institution_id: currentinstid,
        institution_shortname: instshortname,
        institution_name: instname,
        emailregex: instemailregex,
        link_resolver_url: instlinkresolverurl,
        link_resolver_name: instlinkresolvername,
        link_resolver_img: instlinkresolverimage,
        signup_code: instsignupcode,
        ip_regex: instipregex,
        libkeylibraryid: libkeyaccessid,
        libkeyaccesstoken: libkeyaccesstoken,
        parentid: parentid,
        oa_organisation_id: oaorgid,
        oa_scope: oascope,
        withCredentials: true,
      })
      .then((res) => {
        if (res.status === 200) {
          setInstShow(false);
          //  setLoading(true);
          setTogglerefresh(!togglerefresh);
        } else {
          const error = new Error(res.error_message);
          setErrors(res.error_message);
          setErrorShow(true);
        }
      })
      .catch((error) => {
        setErrors(error);
        setErrorShow(true);
        setLoading(false);
      });

    event.preventDefault();
  };

  const search = () => {
    setLoading(true);
    setTogglerefresh(!togglerefresh);
  };

  /**
   * Handle Tab - changing tabs
   * @param {*}
   */
  const handleTab = (t) => {
    setTabkey(t);
  };

  const handleDelete = (e) => {
    setDeleteShow(false);
    //Todo: if needed

    setTogglerefresh(!togglerefresh);
  };
  const columns_institutions = [
    {
      name: "ID",
      selector: "id",
      sortable: true,
      wrap: true,
      maxWidth: "100px",
    },
    {
      name: "Full Name",
      selector: "institution_name",
      sortable: true,
      maxWidth: "600px",
      wrap: true,
    },
    {
      name: "Short Name",
      selector: "institution_shortname",
      sortable: true,
      maxWidth: "600px",
      wrap: true,
    },
    {
      name: "Email suffix",
      selector: "emailregex",
      sortable: true,
      maxWidth: "30px",
      wrap: true,
    },
    {
      name: "Link Resolver Url",
      selector: "link_resolver_url",
      sortable: true,
      maxWidth: "400px",
      wrap: true,
    },

    {
      cell: (row) => (
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            <i className="fa fa-cog"></i>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              href="#/add-pub"
              onClick={handleInstitutionAction}
              data-value={row.id}
              data-action="add-institution"
            >
              <i className="fa fa-plus-circle"></i> Add New
            </Dropdown.Item>
            <Dropdown.Item
              href="#/edit"
              onClick={handleInstitutionAction}
              data-value={row.id}
              data-action="edit-institution"
            >
              <i className="fa fa-pencil"></i> Edit
            </Dropdown.Item>
            <Dropdown.Item
              href="#/stats"
              onClick={handleInstitutionAction}
              data-value={row.id}
              data-action="stats-institution"
            >
              <i className="fa fa-bar-chart text-warning"></i> Stats
            </Dropdown.Item>
            <Dropdown.Item
              href="#/delete"
              onClick={handleInstitutionAction}
              data-value={row.id}
              data-action="addpayment-institution"
            >
              <i className="fa fa-dollar text-success"></i> Add Payment
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  function clearInstitution() {
    setInstid("");
    setInstName("");
    setInstshortName("");
    setInstEmailRegex("");
    setInstLinkResolverImage("");
    setInstLinkResolverName("");
    setInstLinkResolverUrl("");
    setInstSignupcode("");
    setInstIpRegex("");
    setLibkeyaccesstoken("");
    setLibkeyaccessid("");
    setOAscope("");
    setOAorgid("");
    return true;
  }
  const subHeaderInstitution = useMemo(() => {
    return (
      <div>
        <Row className="mt-1">
          <Button type="button" className="round-left" onClick={handleClear}>
            X
          </Button>
          <input
            id="search"
            type="text"
            placeholder="Full/Short Name,Email suffix"
            value={filterTextinst}
            onChange={(e) => setfilterTextinst(e.target.value)}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                search();
              }
            }}
          />
          <Button type="button" className="round-right" onClick={search}>
            <i className="fa fa-search"></i>
          </Button>
          &nbsp;
          <Button
            type="button"
            onClick={(event) => {
              setAction("add-institution");
              clearInstitution();
              setInstShow(true);
            }}
            className="round-all"
          >
            <i className="fa fa-plus-circle"></i>
          </Button>
        </Row>
      </div>
    );
  }, [filterTextinst, resetPaginationToggle]);
  return (
    <main role="main" className="container-fluid main mt-2">
      <ErrorMdl error={hasError} setErrorShow={setErrorShow} show={errorshow} />
      <DeleteMdl
        deletedata={deletedata}
        setDeleteShow={setDeleteShow}
        deleteshow={deleteshow}
        handleDelete={handleDelete}
      />
      <PaymentMdl
        user={{
          firstname: firstname,
          lastname: lastname,
          email: email,
          amount: amount,
          paid: paid,
          notes: notes,
          institution: currentinstid,
        }}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setAmount={setAmount}
        setPaid={setPaid}
        setNotes={setNotes}
        handleClose={handleClose}
        handlePayment={handlePayment}
        show={paymentshow}
      />

      <InstitutionMdl
        institution={{
          template_id: currentinstid,
          name: instname,
          shortname: instshortname,
          emailregex: instemailregex,
          link_resolver_image: instlinkresolverimage,
          link_resolver_name: instlinkresolvername,
          link_resolver_url: instlinkresolverurl,
          signupcode: instsignupcode,
          ip_regex: instipregex,
          libkeyaccessid: libkeyaccessid,
          libkeyaccesstoken: libkeyaccesstoken,
          oa_organisation_id: oaorgid,
          oa_scope: oascope,
          parentid: parentid,
          currentaction: currentaction,
        }}
        setInstName={setInstName}
        setInstshortName={setInstshortName}
        setInstEmailRegex={setInstEmailRegex}
        setInstLinkResolverImage={setInstLinkResolverImage}
        setInstLinkResolverName={setInstLinkResolverName}
        setInstLinkResolverUrl={setInstLinkResolverUrl}
        setInstSignupcode={setInstSignupcode}
        setInstIpRegex={setInstIpRegex}
        setLibkeyaccessid={setLibkeyaccessid}
        setLibkeyaccesstoken={setLibkeyaccesstoken}
        setOAorgid={setOAorgid}
        setOAscope={setOAscope}
        setParentid={setParentid}
        handleClose={handleClose}
        handleInstitution={handleInstitution}
        show={instshow}
      />
      <InstitutionReportMdl
        institution={{
          institution_id: currentinstid,
          name: instname,
        }}
        statsData={statsdata}
        statsDataTotal={statstotal}
        handleClose={handleClose}
        handleInstitution={handleInstitution}
        handleInstitutionPageChange={handleInstitutionPageChange}
        show={statshow}
      />
      <Row>
        <SidebarAdmin></SidebarAdmin>

        <Col>
          <Row>
            <Col md="12">
              <Breadcrumb>
                <Breadcrumb.Item href="/Admin">
                  <i className="fa fa-home"></i> Home
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  <i className="fa fa-university"></i> Institutions
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <Tabs id="tabs" activeKey={tabkey} onSelect={handleTab}>
                <Tab
                  eventKey="institutions"
                  title={
                    <div>
                      <i className="fa fa-university"></i> Institutions
                    </div>
                  }
                >
                  <Row>
                    <Col>
                      <DataTable
                        striped={true}
                        customStyles={customStyles}
                        columns={columns_institutions}
                        data={data}
                        progressPending={loading}
                        expandableRows
                        highlightOnHover
                        //defaultSortField="pubication"
                        expandOnRowClicked
                        expandableRowsComponent={
                          <PaymentExpandedComponent
                            setEmail={setEmail}
                            setInstid={setInstid}
                            handleUserAdd={handleUserAdd}
                            handleAdmin={handleAdmin}
                            handleUserRemove={handleUserRemove}
                          />
                        }
                        onSort={handleSort}
                        sortServer
                        pagination
                        paginationServer
                        paginationTotalRows={currentTotal}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        subHeader
                        subHeaderComponent={subHeaderInstitution}
                      />
                    </Col>
                  </Row>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Col>
      </Row>
    </main>
  );
};

export default Settings;
