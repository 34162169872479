import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import ErrorMdl from "../components/errorMdl";
import PageContent from "../components/PageContent";
const axios = require("axios");

const Contact = (props) => {
  const [errorshow, setErrorShow] = useState(false);
  const [hasError, setErrors] = useState(false);
  const [currentPage, setCurrentPage] = useState("contact-us");
  const [data, setData] = useState([]);
  document.title = "Contact Us: Trip Medical Database";

  return (
    <main role="main" className="container-fluid main mt-2">
      <ErrorMdl error={hasError} setErrorShow={setErrorShow} show={errorshow} />

      <div>
        <PageContent
          currentPage={currentPage}
          data={data}
          error={hasError}
          errorsShow={errorshow}
          setData={setData}
          setErrors={setErrors}
          setErrorShow={setErrorShow}
        />
      </div>
    </main>
  );
};
export default Contact;
