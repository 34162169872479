import React, { useState } from "react";
import _ from "underscore";
import { checkTokensStorage } from "../context/checkTokens";
import ProMdl from "../components/ProMdl";
import ProFeatures from "../components/ProFeatures";

const WhyComponent = ({ isPro = false, handleModal }) => {
  var loginObj = checkTokensStorage();
  const [proshow, setProShow] = useState(false);
  const [signupshow, setSignupShow] = useState(false);

  return (
    <div className="why-go-pro-content">
      <div className="pro-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8 text-center">
              <h1 className="pro-section-title">
                Unlock the full Trip medical database potential with a Pro
                Account
              </h1>
              <p>
                Extra content and more functionality, from just $55 per year,
                makes Pro an even better tool for finding the best available
                evidence
              </p>
              <ProMdl
                setProShow={setProShow}
                setSignupShow={setSignupShow}
                show={proshow}
              />
              {!isPro && (
                <>
                  <a
                    type="button"
                    onClick={(e) => {
                      setProShow(!proshow);
                      e.preventDefault();
                    }}
                    className="btn btn-pro mr-3"
                  >
                    Upgrade to Pro today for just $55 per year
                  </a>
                  <a
                    href="#feature-comparison"
                    className="btn btn-outline btn-pro mt-3 mt-md-0"
                  >
                    Find out more
                  </a>
                </>
              )}
              <ul className="usp-cards">
                <li className="card">
                  <i className="fa fa-check"></i>
                  <h4 className="card-content">
                    Huge number of extra systematic reviews
                  </h4>
                </li>
                <li className="card">
                  <i className="fa fa-check"></i>
                  <h4 className="card-content">
                    Millions of full-text articles linked
                  </h4>
                </li>
                <li className="card">
                  <i className="fa fa-check"></i>
                  <h4 className="card-content">Advanced search</h4>
                </li>
                <li className="card">
                  <i className="fa fa-check"></i>
                  <h4 className="card-content">
                    Export to reference management software
                  </h4>
                </li>
                <li className="card">
                  <i className="fa fa-check"></i>
                  <h4 className="card-content">Unlimited Connected Articles</h4>
                </li>
                <li className="card">
                  <i className="fa fa-check"></i>
                  <h4 className="card-content">
                    Access to ongoing clinical trials
                  </h4>
                </li>
              </ul>
              {!isPro && (
                <p>
                  Unlock Pro features for just{" "}
                  <a
                    type="button"
                    onClick={(e) => {
                      setProShow(true);
                      e.preventDefault();
                    }}
                  >
                    $55 per year
                  </a>
                  , cancel any time
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="pro-section-divider">
        <i className="fa fa-quote-right"></i>
      </div>
      {/* Pro Features */}
      <ProFeatures></ProFeatures>
      <div className="pro-section-divider">
        <i className="fa fa-quote-right"></i>
      </div>
      <div className="pro-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col col-12 col-lg-3">
              <div className="pro-graphic mr-3">
                <svg
                  id="Layer_1"
                  data-name="Layer 1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 156.86 104.44"
                >
                  <g>
                    <path
                      d="M23.43,0A23.42,23.42,0,0,0,0,23.4V46.8A23.41,23.41,0,0,0,23.43,70.2H99.6A23.41,23.41,0,0,0,123,46.8V23.4A23.42,23.42,0,0,0,99.6,0Z"
                      fill="#ffe300"
                      fillRule="evenodd"
                    ></path>
                    <g>
                      <path
                        d="M40,14.19q5.84,0,8.84,3t3,8.42q0,5.41-3,8.44c-2,2-5,3-8.84,3H28.18V52.84H23.05V14.19ZM38.24,32.7q4.38.06,6.41-1.79a6.78,6.78,0,0,0,2-5.3,6.73,6.73,0,0,0-2-5.28q-2-1.81-6.41-1.81H28.18V32.7Z"
                        fillRule="evenodd"
                      ></path>
                      <path
                        d="M61.49,24.85v5.9h.11a12.33,12.33,0,0,1,4.11-5,10.26,10.26,0,0,1,6.16-1.52v4.87a13,13,0,0,0-4.7.76A7.48,7.48,0,0,0,64,32.05,9.21,9.21,0,0,0,62.3,35.6a19.14,19.14,0,0,0-.54,4.79V52.84h-4.6v-28Z"
                        fillRule="evenodd"
                      ></path>
                      <path
                        d="M73.71,33.11a13,13,0,0,1,2.59-4.66,12.3,12.3,0,0,1,4.27-3.11,15.61,15.61,0,0,1,11.71,0,12.19,12.19,0,0,1,4.24,3.11,13,13,0,0,1,2.6,4.66,19.65,19.65,0,0,1,0,11.5,13.08,13.08,0,0,1-2.6,4.63,11.75,11.75,0,0,1-4.24,3.08,16,16,0,0,1-11.71,0,11.86,11.86,0,0,1-4.27-3.08,13.06,13.06,0,0,1-2.59-4.63,19.44,19.44,0,0,1,0-11.5m4.67,10.28a9.59,9.59,0,0,0,1.87,3.3,7.89,7.89,0,0,0,2.78,2,8.4,8.4,0,0,0,6.76,0,7.93,7.93,0,0,0,2.79-2,9.42,9.42,0,0,0,1.86-3.3,14,14,0,0,0,.68-4.52,14,14,0,0,0-.68-4.52A9.75,9.75,0,0,0,92.58,31,8,8,0,0,0,89.79,29,8.51,8.51,0,0,0,83,29,8,8,0,0,0,80.25,31a9.93,9.93,0,0,0-1.87,3.33,14,14,0,0,0-.67,4.52,14,14,0,0,0,.67,4.52"
                        fillRule="evenodd"
                      ></path>
                    </g>
                    <path
                      d="M98.08,68.36A22.71,22.71,0,0,0,103,82.44a31,31,0,0,0,30-30.95V49.05A22.79,22.79,0,0,0,98.08,68.36Z"
                      fill="#ffe300"
                      fillRule="evenodd"
                    ></path>
                    <path d="M138.16,84.07a23.55,23.55,0,0,0,6.13-15.87,23.83,23.83,0,1,0-7.91,17.64l18.7,18.6,1.78-1.77Zm-17.72,5.65a21.5,21.5,0,1,1,21.62-21.5A21.57,21.57,0,0,1,120.44,89.72Z"></path>
                  </g>
                </svg>
              </div>
            </div>

            <div className="col col-12 col-lg-6">
              <h3 className="pro-section-title">
                You're one step away from becoming a Pro user
              </h3>

              <p>
                The Trip Pro offering takes an already wonderful – and free –
                version of Trip and makes it even better. With more content,
                more functionality, no adverts and more, it really makes the
                Trip experience even better. Institutional and personal
                subscriptions are available. The{" "}
                <a href="#feature-comparison">comparison table</a> highlights
                the main differences between the various offerings.
              </p>
            </div>
          </div>

          <div className="row upgrade-options--title">
            <div className="col">
              <h5>You can upgrade in one of 2 ways:</h5>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col col-10 col-lg-5  mb-3 mb-lg-0">
              <div className="subscription-card mb-5 mb-lg-0">
                <i className="fa fa-building"></i>

                <h3 className="mb-0">Institutional Subscription</h3>
                <p className="small-print">
                  (VAT, where applicable, will apply)
                </p>

                <p>
                  If your institution has not already purchased an upgrade, you
                  can find out pricing details here, prices start at $215 per
                  annum.
                </p>

                <p>
                  To set this up or ask any further questions please get in
                  touch via our Contact Us page.
                </p>

                <a
                  className="btn btn-pro btn-outline"
                  target="_blank"
                  href="https://blog.tripdatabase.com/2021/07/03/institutional-subscriptions/"
                >
                  See Institution Price List
                </a>
                <a
                  className="btn btn-pro"
                  target="_blank"
                  href="mailto:enquiries@tripdatabase.com"
                >
                  <i className="fa fa-envelope"></i> Contact us for
                  Institutional enquiries
                </a>
              </div>
            </div>

            <div className="col col-10 col-lg-5">
              <div className="subscription-card">
                <i className="fa fa-user"></i>

                <h3>Personal Subscription</h3>

                <p>
                  The annual subscription cost is $55 (US Dollars). If
                  applicable, Trip Database will pay VAT at your home countries
                  rate . If you are VAT registered you may claim this back.
                </p>

                {!isPro && (
                  <a
                    type="button"
                    className="btn btn-pro"
                    onClick={(e) => {
                      setProShow(true);
                      e.preventDefault();
                    }}
                  >
                    Upgrade to Pro today
                  </a>
                )}
              </div>
            </div>
          </div>

          <div className="row upgrade-options--afterward justify-content-center">
            <div className="col col-lg-5">
              <p>
                Any questions then please{" "}
                <a target="_blank" href="mailto:support@tripdatabase.com">
                  get in touch
                </a>
                .
              </p>

              <p>
                You can purchase your own professional/private subscription by
                payment card, and the upgrade will take effect{" "}
                <strong>immediately</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="pro-section-divider">
        <i className="fa fa-quote-right"></i>
      </div>
      <div className="pro-section pro-section--dark">
        <div className="container">
          <div className="row">
            <div className="col">
              <h2 className="pro-section-title text-center mb-5">
                What do our PRO users say?
              </h2>
              <ul className="quote-cards">
                <li className="card card--quote">
                  <p>
                    TRIP is my go-to tool for everything from narrative reviews
                    to systematic reviews...<br></br> <br></br>I cannot do
                    without it!
                  </p>
                  <div className="quote-author">
                    <img
                      src={require("../components/assets/misc/jackyensen.jpg")}
                      alt="Users comments"
                    ></img>
                    <div>
                      <p>Jack Yensen</p>
                      <p>Athabasca University</p>
                    </div>
                  </div>
                </li>
                <li className="card card--quote">
                  <p>
                    Constant improvement has also made the interface so helpful
                    and intuitive...<br></br>
                    <br></br>A very impressive resource
                  </p>
                  <div className="quote-author">
                    <div>
                      <p>Barbara Norrey</p>
                      <p>MA (Dist), Dip Lib, Dip Cons</p>
                    </div>
                  </div>
                </li>
                <li className="card card--quote">
                  <p>
                    TRIP facilitates an in-depth overview of current evidence on
                    any clinical question.
                  </p>
                  <div className="quote-author">
                    <img
                      src={require("../components/assets/misc/igorbrbre.jpg")}
                      alt="Users comments"
                    ></img>
                    <div>
                      <p>Igor Brbre</p>
                      <p>
                        Information Scientist, Healthcare Improvement Scotland
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="pro-section bottom-cta-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col col-lg-8">
              <h4 className="pro-section-title">
                Have questions?{" "}
                <a target="_blank" href="mailto:support@tripdatabase.com">
                  Talk to us.
                </a>
                <br></br>
                Ready to go Pro? Get started:
              </h4>
              {!isPro && (
                <a
                  className="btn btn-pro"
                  type="button"
                  onClick={(e) => {
                    setProShow(!proshow);
                    e.preventDefault();
                  }}
                >
                  Go Pro for just $55 a year
                </a>
              )}
              <p className="small-print">
                Don’t forget you can cancel subscription renewal at any time
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="pro--bottom-graphic"></div>
    </div>
  );
};
export default WhyComponent;
