import React, { useContext } from "react";

import { Accordion, Card, useAccordionToggle } from "react-bootstrap";

const CustomToggle = ({ children, eventKey, callback }) => {
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  );

  return (
    <button
      className="btn btn-link"
      data-toggle="collapse"
      type="button"
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
};
const AboutAccordian = () => {
  return (
    <Accordion defaultActiveKey="0">
      <Card>
        <Card.Header>
          <CustomToggle eventKey="0">
            {" "}
            How does the filtering system work?
            <i className="fa fa-play-circle"></i>
          </CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body className="card-body">
            {" "}
            The filters on left-hand side are intended to make it easier to find
            the most suitable content for your needs. Publishers are classified
            based on their output (e.g. Cochrane are classified in systematic
            reviews, NEJM as key primary research etc). By clicking on the
            systematic review filter you restrict the results to just systematic
            reviews.
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Card.Header>
          <CustomToggle eventKey="1">
            {" "}
            What’s the history of Trip?
            <i className="fa fa-play-circle"></i>
          </CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="1">
          <Card.Body className="card-body">
            {" "}
            Trip was launched in 1997. It was designed to help the two founders
            rapidly answer clinical questions. The idea was to bring together
            all the “evidence based” content in one place and to search that.
            Since its launch Trip has been searched over 175 million times.
            <br></br>
            Trip became incorporated (a limited company) in 2001 with the two
            shareholders being Jon Brassey and Dr Chris Price. They remain the
            sole shareholders.
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Card.Header>
          <CustomToggle eventKey="2">
            {" "}
            How does the Trip algorithm work?
            <i className="fa fa-play-circle"></i>
          </CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="2">
          <Card.Body className="card-body">
            The algorithm uses three main factors when deciding the position of
            particular search results:<br></br>
            <br></br>
            <ul>
              <li>
                Text score, based on how relevant the document is to the search
                query.
              </li>
              <li>
                Publication score. As far as we know Trip is unique in using a
                publication score, based on quality, to help order results. So,
                a high quality resource (such as Cochrane) will have a higher
                score than say the BMJ and this again will score more highly
                than an eTextbook.
              </li>
              <li>Date. The more recent the document the higher the score.</li>
            </ul>
            These three elements are combined to give each document a score. The
            document with the highest score appears at the top of the results.
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Card.Header>
          <CustomToggle eventKey="3">
            How are you financed?
            <i className="fa fa-play-circle"></i>
          </CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="3">
          <Card.Body className="card-body">
            {" "}
            Although we’re a commercial company we operate more like a ‘not for
            profit’ company. Our income tends to go on wages, development or
            other associated costs. Our incomes comes from a number of different
            sources:
            <br />
            <br />
            <ul>
              <li>
                Freemium access. All users get access to a great service, but
                for subscribers they get an even better one, with more content
                and more functionality.
              </li>
              <li>
                Rapid reviews. We undertake a number of bespoke, rapid reviews,
                for 3rd party organisations.
              </li>
              <li>
                Consultancy. We advise on many aspects of evidence, literature
                searching, reviewing and decision support.
              </li>
            </ul>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Card.Header>
          <CustomToggle eventKey="4">
            How is the content selected for Trip?
            <i className="fa fa-play-circle"></i>
          </CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="4">
          <Card.Body className="card-body">
            We identify new sources to add to Trip using two main methods:
            <br />
            <br />
            <ol>
              <li>
                Our continued work in evidence reviewing; If we find a source
                that’s useful and not in Trip we will attempt to add it to the
                index.
              </li>
              <li>
                Our network of users. Over the years we have developed a network
                of users who are keen to see Trip develop. They will often
                suggest sites and in these situations we typically take an
                in-house decision as to the suitability of the content.
              </li>
            </ol>
            NOTE: We typically do not consider sources where a person linked
            with the source suggest we include it!
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Card.Header>
          <CustomToggle eventKey="5">
            What does Trip stand for?
            <i className="fa fa-play-circle"></i>
          </CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="5">
          <Card.Body className="card-body">
            Initially Trip stood for Turning Research Into Practice. However, we
            do not use this anymore and simply use the name Trip!
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Card.Header>
          <CustomToggle eventKey="6">
            What are the benefits of registering?
            <i className="fa fa-play-circle"></i>
          </CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="6">
          <Card.Body className="card-body">
            Registering has a number of benefits to a user, these include:
            <br />
            <br />
            <ul>
              <li>
                Keeping up to date with new research based on your clinical
                speciality and/or specific topics of interest. Each month, we
                identify new content focussed on your interests and email these
                to you.
              </li>
              <li>
                If your institution has registered with Trip you can easily
                access your institution’s full-text via Trip.
              </li>
              <li>
                Many clinicians find it useful to keep a record of activity they
                have spent on Trip. This may be for CPD/CME purposes or simple
                curiosity.
              </li>
              <li>
                Finally, we are currently planning a number of new features that
                are based on a user’s history of usage. So, by registering we
                can start to better learn how you use Trip, which will better
                serve your future information/evidence needs.
              </li>
            </ul>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Card.Header>
          <CustomToggle eventKey="7">
            What does the automated search do?
            <i className="fa fa-play-circle"></i>
          </CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="7">
          <Card.Body className="card-body">
            {" "}
            When registering you are presented with the ability to add terms of
            interest. This is to allow us to highlight new research which might
            be of interest. So, every month, as new content is added we search
            this new content using your recorded keywords.
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Card.Header>
          <CustomToggle eventKey="8">
            How can I find out more about Trip?
            <i className="fa fa-play-circle"></i>
          </CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="8">
          <Card.Body className="card-body">
            {" "}
            Make sure you watch both introductory videos and if you still have
            any questions, drop us a line at{" "}
            <a href="mailto:enquiries@tripdatabase.com" target="_blank">
              enquiries@tripdatabase.com
            </a>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default AboutAccordian;
