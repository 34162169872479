import React from "react";
import { Form, Button, Modal } from "react-bootstrap";
const EditUserMdl = ({
  user,
  setEmail,
  setFirstname,
  setLastname,
  handleClose,
  handleUpdateUser,
  show,
}) => {
  // return results
  var current_selection = "Quality";

  return (
    <Modal show={show} onHide={handleClose} size="xl" animation={false}>
      <Form width="100%">
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              name="email"
              placeholder="name@example.com"
              value={user.email ? user.email : ""}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput2">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              name="firstname"
              value={user.firstname ? user.firstname : ""}
              onChange={(e) => setFirstname(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput3">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              name="lastname"
              value={user.lastname ? user.lastname : ""}
              onChange={(e) => setLastname(e.target.value)}
            />
          </Form.Group>
          {/* <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Country</Form.Label>
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Role</Form.Label>
            <Form.Control as="select">
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
            </Form.Control>
          </Form.Group> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdateUser}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default EditUserMdl;
