import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import ErrorMdl from "../components/errorMdl";
import ProMdl from "../components/ProMdl";
import SignupMdl from "../components/SignupMdl";
import { checkTokensStorage } from "../context/checkTokens";

const axios = require("axios");
const _ = require("underscore");

const Popularsearch = (props) => {
  const [proshow, setProShow] = useState(false);
  const [signupshow, setSignupShow] = useState(false);
  const [pagedata, setPageData] = useState("");
  const [loginshow, setLoginShow] = useState(false);
  const [hasError, setErrors] = useState(false);
  const [errorshow, setErrorShow] = useState(false);
  const [upgBtnshow, setUpgBtnShow] = useState(false);

  var loginObj = checkTokensStorage();
  async function getPage() {
    axios
      .get("/Popularsearches")

      .then((res) => {
        if (res.status === 200) {
          setPageData(res.data);
        }
      })
      .catch((error) => {});
  }

  useEffect(() => {
    document.body.classList.remove("homepage");

    getPage();
    setUpgBtnShow(loginObj.chkLogin !== 1 && loginObj.chkPro !== true);
  }, []);
  return (
    <div className="seo-content">
      <div className="seo-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8 text-center">
              <h1 className="mb-5">Popular Searches</h1>

              <p>
                Below is a non exhaustive list of{" "}
                <strong>popular searches</strong>:
              </p>

              {pagedata && (
                <ul className="seo-link-list mb-5">
                  {pagedata.map((item, i) => (
                    <li key={"keyitem" + item.id}>
                      <a href={"/Popular/" + item.route}>{item.title}</a>
                    </li>
                  ))}
                </ul>
              )}
              <Helmet>
                <meta
                  name="description"
                  content="Trip Popular Searches - Evidence-based answers for health professionals | Searching sources such as systematic reviews, clinical guidelines and RCTs"
                />
              </Helmet>
              <ProMdl
                props={props}
                setProShow={setProShow}
                setSignupShow={setSignupShow}
                show={proshow}
              />

              <SignupMdl
                props={props}
                setLoginShow={setLoginShow}
                setSignupShow={setSignupShow}
                setErrorShow={setErrorShow}
                setErrors={setErrors}
                show={signupshow}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="seo-section-divider">
        <i className="fa fa-search"></i>
      </div>

      {upgBtnshow && (
        <div className="about-section bottom-cta-section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-8">
                <h4 className="about-section-title">
                  Trip is and will always be free to use.
                  <br />
                  <br />
                  Sign up for a free account to save your searches and keep
                  track of your topics of interest
                </h4>

                <a
                  type="button"
                  className="btn btn-pro"
                  onClick={(e) => {
                    setProShow(false);
                    setSignupShow(true);
                    e.preventDefault();
                  }}
                >
                  Sign up for a free Trip account
                </a>
                <p className="small-print">
                  <a
                    href="#"
                    type="button"
                    onClick={(e) => {
                      setSignupShow(false);
                      setProShow(true);
                      e.preventDefault();
                    }}
                  >
                    Or click here if you’re interested in our{" "}
                    <strong>Pro</strong> service
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="about--bottom-graphic"></div>
    </div>
  );
};

export default Popularsearch;
