import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import ErrorMdl from "../components/errorMdl";
import ProMdl from "../components/ProMdl";
import SignupMdl from "../components/SignupMdl";
import { checkTokensStorage } from "../context/checkTokens";

import AboutAccordian from "../pages/AboutAccordian";

const axios = require("axios");

const About = (props) => {
  const [proshow, setProShow] = useState(false);
  const [signupshow, setSignupShow] = useState(false);
  const [loginshow, setLoginShow] = useState(false);
  const [hasError, setErrors] = useState(false);
  const [errorshow, setErrorShow] = useState(false);
  const [upgBtnshow, setUpgBtnShow] = useState(false);

  // const [data, setData] = useState([]);
  var loginObj = checkTokensStorage();

  useEffect(() => {
    document.body.classList.remove("homepage");
    setUpgBtnShow(loginObj.chkLogin !== 1 && loginObj.chkPro !== true);
  }, []);
  return (
    // <main role="main" className="container-fluid main mt-2">

    <div className="about-content">
      <div className="about-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-8 text-center">
              <h1>
                Unlock the full Trip medical database potential with a Pro
                Account
              </h1>
              <ProMdl
                props={props}
                setProShow={setProShow}
                setSignupShow={setSignupShow}
                show={proshow}
              />

              <SignupMdl
                props={props}
                setLoginShow={setLoginShow}
                setSignupShow={setSignupShow}
                setErrorShow={setErrorShow}
                setErrors={setErrors}
                show={signupshow}
              />
              <p>
                100,000s more results and article links, sophisticated search
                and hi-powered export features from just $55 per year
              </p>
              {loginObj.chkPro === false ? (
                <a
                  className="btn btn-pro mb-5"
                  type="button"
                  onClick={(e) => {
                    setProShow(!proshow);
                    e.preventDefault();
                  }}
                >
                  Upgrade to Pro today for just $55 per year
                </a>
              ) : (
                ""
              )}
              <iframe
                className="mb-3"
                src="https://www.youtube.com/embed/uzqUiiaAwtk"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>

              <div className="mb-5">
                <a href="https://www.youtube.com/watch?v=WfL92eSSqHA">
                  Watch Part Two here
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="about-section-divider">
        <i className="fa fa-search"></i>
      </div>

      <div className="about-section about-section--dark">
        <div className="container">
          <div className="row">
            <div className="col col-12 col-lg-4">
              <div className="about-graphic mr-0 mr-lg-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 165.17 104.41"
                >
                  <g>
                    <path
                      d="M59.36,9.66V6.2Q59.36.62,56,.62H3.34Q0,.62,0,6.2V9.66q0,5.56,3.34,5.57H21.05v61.9H38.31V15.23H56q3.34,0,3.34-5.57M78.13,21.79q-7.47,0-12.14,8.48a17,17,0,0,1-.39-1.79,17.79,17.79,0,0,0-.5-2.06c-.15-.41-.38-.93-.67-1.56a3.23,3.23,0,0,0-1.17-1.4A6.56,6.56,0,0,0,60.31,23a38.78,38.78,0,0,0-6.74.89q-4.51.9-4.51,2.68a32.78,32.78,0,0,0,.67,4,69.65,69.65,0,0,1,.67,11.71V77.13H66.88V41.87c2.23-3.72,5.19-5.58,8.91-5.58a11.35,11.35,0,0,1,2.89.45,11.28,11.28,0,0,0,2.34.44c1,0,1.79-1.17,2.45-3.51a22.09,22.09,0,0,0,1-5.86,6.75,6.75,0,0,0-1.4-4.18c-.93-1.22-2.58-1.84-5-1.84m81.25,8q-5.79-8-15-8a17.23,17.23,0,0,0-9,2.34,15.67,15.67,0,0,0-6,6,16.76,16.76,0,0,1-.39-1.68,19.62,19.62,0,0,0-.5-2,14.55,14.55,0,0,0-.67-1.62,3.23,3.23,0,0,0-1.17-1.4,6.53,6.53,0,0,0-3-.44,38.78,38.78,0,0,0-6.74.89q-4.52.9-4.51,2.68a32.78,32.78,0,0,0,.67,4,69.65,69.65,0,0,1,.67,11.71V99.49h16.48v-21a18.85,18.85,0,0,0,11.36,3.46q11.13,0,17.32-8.09t6.18-22.08q0-14-5.79-22M138.11,68.85a15.68,15.68,0,0,1-7.8-2.12V41.87q3.78-6.59,9.13-6.58,9,0,9,16.78T138.11,68.85M99.08,0H96c-4.46,0-6.68,1.19-6.68,3.57v8.11h16.48V3.57Q105.76,0,99.08,0m0,22.85H96c-4.46,0-6.68,1.19-6.68,3.57V77.13h16.48V26.42q0-3.57-6.68-3.57"
                      fill="#fff"
                      fillRule="evenodd"
                    />
                    <path
                      d="M37.33,77.53v.58Q37.33,82,31,82H27.26q-6.36,0-6.36-3.9v-.58Z"
                      fill="#63c608"
                      fillRule="evenodd"
                    />
                    <path
                      d="M67.19,77.53v.58q0,3.9-6.66,3.9h-3.1q-6.66,0-6.66-3.9v-.58Z"
                      fill="#0e6cbb"
                      fillRule="evenodd"
                    />
                    <path
                      d="M106,77.53v.92c0,2.38-2.22,3.56-6.65,3.56H96.25c-4.44,0-6.66-1.18-6.66-3.56v-.92Z"
                      fill="#00a89d"
                      fillRule="evenodd"
                    />
                    <path
                      d="M130.7,100.06v.9q0,3.45-6.55,3.45h-3.06q-6.56,0-6.55-3.45v-.9Z"
                      fill="#eec82f"
                      fillRule="evenodd"
                    />
                    <path
                      d="M106,11.83v.92c0,2.38-2.22,3.56-6.65,3.56H96.25c-4.44,0-6.66-1.18-6.66-3.56v-.92Z"
                      fill="#ba390d"
                      fillRule="evenodd"
                    />
                  </g>
                </svg>
              </div>
            </div>

            <div className="col">
              <h3 className="about-section-title">What is Trip Database?</h3>

              <p>
                Trip is a clinical search engine designed to allow users to
                quickly and easily find and use high-quality research evidence
                to support their practice and/or care.
              </p>

              <p>
                Trip has been online since 1997 and in that time has developed
                into the internet’s premier source of evidence-based content.
                Our motto is ‘Find evidence fast’ and this is something we aim
                to deliver for every single search.
              </p>

              <p>
                For any questions about Trip, please contact us via{" "}
                <a href="mailto:jon.brassey@tripdatabase.com">
                  jon.brassey@tripdatabase.com
                </a>
              </p>
            </div>
          </div>
          {upgBtnshow && (
            <div className="row cta-outlined justify-content-center">
              <div className="col col-10 col-lg-6">
                <p>
                  With a free account, you can save your searches and sign up to
                  email alerts for new evidence of interest
                </p>

                <a
                  className="btn btn-pro"
                  type="button"
                  onClick={(e) => {
                    setProShow(false);
                    setSignupShow(true);
                    e.preventDefault();
                  }}
                >
                  Sign up for a free Trip account
                </a>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="about-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col col-12 col-lg-10">
              <h3 className="about-section-title text-center">
                Answers to some key questions…
              </h3>
              <AboutAccordian></AboutAccordian>
            </div>
          </div>
          {upgBtnshow && (
            <div className="row cta-outlined justify-content-center">
              <div className="col col-10 col-lg-6">
                <p>
                  With a free account, you can save your searches and sign up to
                  email alerts for new evidence of interest
                </p>

                <a
                  className="btn btn-pro"
                  type="button"
                  onClick={(e) => {
                    setProShow(false);
                    setSignupShow(true);
                    e.preventDefault();
                  }}
                >
                  Sign up for a free Trip account
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="about-section-divider">
        <i className="fa fa-chart-bar"></i>
      </div>
      <div className="about-section about-section--dark">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col col-10">
              <h3 className="about-section-title text-center">
                How big is Trip?
              </h3>

              <ul className="about-usps mt-5">
                <li>
                  <h4 className="about-usps-title">175 Million</h4>
                  <p>Searched over 175 million times</p>
                </li>
                <li>
                  <h4 className="about-usps-title">5 Million</h4>
                  <p>Has an index of around 5 million documents</p>
                </li>
                <li>
                  <h4 className="about-usps-title">Guidelines</h4>
                  <p>Has the largest collection of clinical guidelines</p>
                </li>
                <li>
                  <h4 className="about-usps-title">Evidence Based</h4>
                  <p>Has an enviable collection of 'evidence-based' content</p>
                </li>
                <li>
                  <h4 className="about-usps-title">Global Use</h4>
                  <p>
                    Trip is used globally and has subscribers from nearly 100
                    countries
                  </p>
                </li>
                <li>
                  <h4 className="about-usps-title">Codebase</h4>
                  <p>Has around 500,000 lines of code</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="about-section">
        <div className="container">
          <div className="row">
            <div className="col">
              <h2 className="about-section-title text-center">
                What do our Free users say?
              </h2>
              <ul className="quote-cards">
                <li className="card card--quote">
                  <p>
                    TRIP database is an excellent metasearch engine. You can
                    search several information resources in one go, filter
                    results easily, and export the references in several
                    formats, including RIS. TRIP is very useful for quick
                    searches as for exhaustive searches.
                  </p>
                  <div className="quote-author">
                    {/* <img src="src/img/headshots/rociorodriguez.jpg" alt=""> */}
                    <div>
                      <p>Rocio Rodriguez</p>
                      <p>Information Specialist</p>
                    </div>
                  </div>
                </li>
                <li className="card card--quote">
                  <p>
                    TRIP searches return results from an impressively wide array
                    of clinically useful resources, from primary literature to
                    reviews, evidence-syntheses, and information for patients;
                    TRIP is truly a "pan-search" tool.
                  </p>
                  <div className="quote-author">
                    {/* <img src="src/img/headshots/williamcayley.jpg" alt=""> */}
                    <div>
                      <p>Bill Cayley Jr</p>
                      <p>MD MDiv</p>
                    </div>
                  </div>
                </li>
                <li className="card card--quote">
                  <p>
                    Trip offers a user friendly master curation of available
                    evidence on a huge range of topics. It is an indispensable
                    tool for methodologists and clinicians alike
                  </p>
                  <div className="quote-author">
                    {/* <img src="src/img/headshots/christopherbuckle.jpg" alt=""> */}
                    <div>
                      <p>Christopher Buckle</p>
                      <p>MD FRCPC MBA</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {upgBtnshow && (
        <div className="about-section bottom-cta-section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-8">
                <h4 className="about-section-title">
                  Trip is and will always be free to use.
                  <br />
                  <br />
                  Sign up for a free account to save your searches and keep
                  track of your topics of interest
                </h4>

                <a
                  type="button"
                  className="btn btn-pro"
                  onClick={(e) => {
                    setProShow(false);
                    setSignupShow(true);
                    e.preventDefault();
                  }}
                >
                  Sign up for a free Trip account
                </a>
                <p className="small-print">
                  <a
                    href="#"
                    type="button"
                    onClick={(e) => {
                      setSignupShow(false);
                      setProShow(true);
                      e.preventDefault();
                    }}
                  >
                    Or click here if you’re interested in our{" "}
                    <strong>Pro</strong> service
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="about--bottom-graphic"></div>
    </div>
  );
};

export default About;
