import React from "react";

const RobGraphics = ({ score, handleRob }) => {
  return (
    <div className="trip-score">
      <span className="trip-score--title">Trip Score</span>

      <div className="trip-score--gauge">
        <span>Risk of Bias:</span>

        <span className="trip-score--label__red">High</span>
        <div
          className="trip-score-slider"
          data-trip-score={score > 100 ? 100 : score}
        ></div>
        <span className="trip-score--label__green">Low</span>
      </div>

      <a
        type="button"
        className="trip-score--explainer-link"
        href="!/#"
        onClick={(e) => {
          handleRob(e);
        }}
        target="_blank"
      >
        ?
      </a>
    </div>
  );
};
export default RobGraphics;
