import React, { useState, useEffect } from "react";

import {
  setQueryStringValue,
  getQueryStringValue,
} from "../../components/search/queryString";
const axios = require("axios");
const _ = require("underscore");
const qs = require("query-string");

const SearchApi = (props) => {
  const parsed = qs.parse(props.location.search);

  const [errorshow, setErrorShow] = useState(false);
  const [hasError, setErrors] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchtype, setSearchtype] = useState("standard");

  const [currentPage, setCurrentPage] = useState(
    !_.isUndefined(parsed.skip) ? parsed.skip / 20 : 1
  );
  const [currentSort, setSort] = useState(
    !_.isUndefined(parsed.sort) ? parsed.sort : "r"
  );
  const [currentArea, setArea] = useState(
    !_.isUndefined(parsed.area) ? parsed.area : ""
  );
  const [currentParams, setParams] = useState("");
  const [currentTag, setTag] = useState(
    !_.isUndefined(parsed.tag) ? parsed.tag : null
  );
  const [currentfromDate, setfromDate] = useState(
    !_.isUndefined(parsed.from_date) ? parsed.from_date : null
  );
  const [currenttoDate, settoDate] = useState(
    !_.isUndefined(parsed.to_date) ? parsed.to_date : null
  );
  const [currentCategory, setCategory] = useState(
    !_.isUndefined(parsed.category) ? parsed.category : null
  );
  const [currentPublication, setPublication] = useState(
    !_.isUndefined(parsed.pub) ? parsed.pub : null
  );
  const [currentF, setF] = useState(1);
  const [currentRepeatPico, setRepeatPico] = useState(true);
  const [isStarred, setStarred] = useState([]);
  const [articleChecked, setArticleChecked] = useState([]);
  const [articleClicked, setArticleClicked] = useState([]);
  const [exportdata, setExportData] = useState("");
  const [exporttype, setExportType] = useState(".CSV");
  const [firstPage, setFirstPage] = useState(1);
  //console.log(publicationdata);
  async function fetchSearchAPI() {
    axios
      .get("/api/Search", {
        params: getParameters(),
      })

      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
          setLoading(false);
          console.log(res.data);
          //   setF(
          //     !_.isUndefined(res.data.summary.fvalue)
          //       ? res.data.summary.fvalue
          //       : 1
          //   );
          //   setRepeatPico(
          //     !_.isUndefined(res.data.summary.repeat_pico)
          //       ? res.data.summary.repeat_pico
          //       : 1
          //   );
          //   setStarred(
          //     !_.isUndefined(res.data.summary.starred_data)
          //       ? res.data.summary.starred_data
          //       : []
          //   );
          //   setInstitutionPro(
          //     !_.isUndefined(res.data.summary.institution_auth)
          //       ? res.data.summary.institution_auth
          //       : {}
          //   );
          //   setArticleClicked([]);
          //   continousAuth(res);
        } else {
          const error = new Error(res.error_message);
          //setErrors(res.error_message);
        }
      })
      .catch((error) => {
        //console.log(error);
        // setErrors(error.response.data.error_message);
        setLoading(false);
      });
  }
  function getParameters() {
    var params = {
      criteria: !_.isUndefined(props.location.state)
        ? props.location.state.criteria
        : getQueryStringValue("criteria"),
      search_type: searchtype || getQueryStringValue("search_type"),
      page: currentPage,
      sort: currentSort,
      clinical: currentArea,
      category_id: currentCategory,
      tags: currentTag,
      from_date: currentfromDate,
      to_date: currenttoDate,
      publication_id: currentPublication,
      export_data: exportdata,
      export_type: exporttype,
      articles: articleChecked,
      from_date_full: getQueryStringValue("from_date_full"),
    };
    switch (
      !_.isUndefined(props.location.state)
        ? props.location.state.search_type
        : searchtype
    ) {
      case "pico":
        params["intervention"] = !_.isUndefined(props.location.state)
          ? props.location.state.intervention
          : getQueryStringValue("intervention");
        params["comparison"] = !_.isUndefined(props.location.state)
          ? props.location.state.comparison
          : getQueryStringValue("comparison");
        params["outcome"] = !_.isUndefined(props.location.state)
          ? props.location.state.outcome
          : getQueryStringValue("outcome");
        params["f"] = currentF;
        params["repeat_pico"] = currentRepeatPico;

        break;
      case "advanced":
        params["allwordsfield"] = !_.isUndefined(props.location.state)
          ? props.location.state.allwordsfield
          : getQueryStringValue("allwordsfield");
        params["anywordsfield"] = !_.isUndefined(props.location.state)
          ? props.location.state.anywordsfield
          : getQueryStringValue("anywordsfield");
        params["exactphrasefield"] = !_.isUndefined(props.location.state)
          ? props.location.state.exactphrasefield
          : getQueryStringValue("exactphrasefield");
        params["excludingwordsfield"] = !_.isUndefined(props.location.state)
          ? props.location.state.excludingwordsfield
          : getQueryStringValue("excludingwordsfield");
        params["anywords"] = !_.isUndefined(props.location.state)
          ? props.location.state.anywords
          : getQueryStringValue("anywords");
        params["exactphrase"] = !_.isUndefined(props.location.state)
          ? props.location.state.exactphrase
          : getQueryStringValue("exactphrase");
        params["excludingwords"] = !_.isUndefined(props.location.state)
          ? props.location.state.excludingwords
          : getQueryStringValue("excludingwords");
        params["advfrom"] = !_.isUndefined(props.location.state)
          ? props.location.state.advfrom
          : getQueryStringValue("advfrom");
        params["advto"] = !_.isUndefined(props.location.state)
          ? props.location.state.advto
          : getQueryStringValue("advto");
        params["proximity"] = !_.isUndefined(props.location.state)
          ? props.location.state.proximity
          : getQueryStringValue("proximity");
        break;
    }
    setParams(params);
    return params;
  }
  useEffect(() => {
    if (getQueryStringValue("criteria")) fetchSearchAPI();
  }, []);
  return data;
};

export default SearchApi;
