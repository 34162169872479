import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { $CombinedState } from "redux";
import _ from "underscore";
const axios = require("axios");

const ResultSuggestions = ({ props, keywords, qry, currentParams }) => {
  const [suggestionsdata, setSuggestions] = useState([]);
  // const getSuggestions = () => {
  //   return axios
  //     .get("/Searchsuggestions", {
  //       params: { keywords: keywords },
  //       withCredentials: true,
  //     })

  //     .then((res) => {
  //       if (res.status === 200) {
  //         setSuggestions(_.isArray(res.data) ? res.data : []);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  useEffect(() => {
    // if (keywords) {
    //   getSuggestions();
    // }
  }, []);
  // const handleSearch = (event, criteria) => {
  //   const store = require("store");
  //   store.set("key", "standardsearch");

  //   var prepare_qs = criteria
  //     ? "?search_type=standard&criteria=" + criteria
  //     : "";
  //   props.history.push({
  //     pathname: "/Searchresult",
  //     search: prepare_qs,
  //     state: criteria ? { search_type: "standard", criteria: criteria } : "",
  //   });
  //   event.preventDefault();
  // };

  const handleLatest = (e) => {
    e.preventDefault();
    props.history.push({
      pathname: "/Top/" + qry,
      search: "?criteria=" + qry + "&search_type=standard",
      state: {
        criteria: qry,
        search_type: "standard",
      },
    });
  };
  const sanitize = (str) => {
    return str.replace(/”/gi, '"').replace(/%20/gi, " ").replace(/%/gi, "");
  };
  return (
    <div className="search-term--related-container pull-right">
      {/* {suggestionsdata && ( */}
      {/* {!_.isEmpty(suggestionsdata) && <h6>Popular Related Searches</h6>}

      <ul>
        {suggestionsdata.map((suggestion, idx) => (
          <li key={idx}>
            <a
              href={
                "/Searchresult?search_type=standard&criteria=" + suggestion.text
              }
              onClick={(e) => handleSearch(e, suggestion.text)}
            >
              {!_.isUndefined(suggestion.text) ? suggestion.text : ""}{" "}
            </a>
          </li>
        ))}
      </ul> */}

      {!_.isUndefined(currentParams.search_type) &&
        currentParams.search_type == "standard" && (
          <ul>
            <li>
              <Link onClick={handleLatest} to={"/Top/" + sanitize(qry)}>
                <span>Latest and Greatest</span>
              </Link>
            </li>
          </ul>
        )}
      {/* )} */}
    </div>
  );
};

export default ResultSuggestions;
