import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Tabs,
  Tab,
  Row,
  Col,
  Dropdown,
  Breadcrumb,
  Button,
} from "react-bootstrap";
// import styled from "styled-components";
// import AsyncSelect from "react-select/async";
import DataTable, { pagination } from "react-data-table-component";
import SidebarAdmin from "../../components/SidebarAdmin";
import ErrorMdl from "../../components/errorMdl";
import DeleteMdl from "../../components/deleteMdl";
import "../../components/assets/css/Tabs.css";
import "../../components/assets/css/Buttons.css";
import EmailTemplateMdl from "./EmailTemplateMdl";
import BatchMdl from "./BatchMdl";

const dateFormat = require("dateformat");
const axios = require("axios");
const _ = require("underscore");
const { customStyles } = require("./tableStyle");

const Emails = (props) => {
  const [templateshow, setTemplateShow] = useState(false);
  const [errorshow, setErrorShow] = useState(false);
  const [deleteshow, setDeleteShow] = useState(false);
  const [togglerefresh, setTogglerefresh] = useState(false);
  const [tabkey, setTabkey] = useState("broadcast");
  const [hasError, setErrors] = useState(false);

  //Publication

  const [templatename, setTemplateName] = useState("");
  const [templatecode, setTemplateCode] = useState("");
  const [templatesubject, setTemplateSubject] = useState("");
  const [description, setDescription] = useState("");
  const [templatetype, setTemplateType] = useState("");
  // const [batchmessage, setBatchMessage] = useState("");

  //publication datatable
  const [data, setData] = useState([]);
  const [deletedata, setDeleteData] = useState([]);
  const [lookupdata, setLookupData] = useState([]);
  //const [lookupdatapublication, setLookupDataPublication] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  // const [filterText, setFilterText] = useState("");
  const [filterTextTemplate, setFilterTextTemplate] = useState("");
  const [filterTextBatch, setFilterTextBatch] = useState("");
  const [resetPaginationToggleBatch, setResetPaginationToggleBatch] = useState(
    false
  );
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [currentaction, setAction] = useState("none");
  const [currentPerpage, setPerpage] = useState(10);
  const [currentTotal, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [currentSort, setSort] = useState();
  const [currenttempid, setTemplateid] = useState("");

  //Article data table
  const [dataBatch, setDataBatch] = useState([]);
  //const [lookupdataBatch, setLookupDataBatch] = useState([]);
  const [currentBatchPage, setCurrentBatchPage] = useState(1);
  // const [currentBatchaction, setBatchAction] = useState("none");
  const [currentBatchPerpage, setPerpageBatch] = useState(20);
  const [currentBatchTotal, setTotalBatch] = useState(0);
  const [loadingBatch, setLoadingBatch] = useState(true);
  const [currentBatchSort, setBatchSort] = useState();
  const [currentbatchid, setBatchid] = useState("");
  const [batchname, setBatchName] = useState("");
  const [batchstatus, setBatchStatus] = useState("Queued");
  const [batchshow, setBatchShow] = useState(false);
  const [batchselectsubject, setBatchselectsubject] = useState("");
  const [batchselectbody, setBatchselectbody] = useState("");
  /**
   * fetchPublications
   * Send a call to express API for standard search
   * @param {*} keywords
   */
  async function fetchEmailTemplates() {
    axios
      .get("/Administrator/getEmailTemplates", {
        params: {
          page: currentPage,
          sort: currentSort,
          rows: currentPerpage,
          search: filterTextTemplate,
        },
      })

      .then((res) => {
        if (res.status === 200) {
          setData(res.data.results);
          setLookupData(res.data.lookupdata);
          setTotal(res.data.total);
          setLoading(false);
        } else {
          const error = new Error(res.error_message);
          setErrors(res.error_message);
          setErrorShow(true);
        }
      })
      .catch((error) => {
        setErrors(error.message);
        setErrorShow(true);
        setLoading(false);
      });
  }

  /**
   *  fetchArticles
   * @param {*} pub_id
   */
  async function fetchEmailBatches(batch_status) {
    axios
      .get("/Administrator/getEmailBatches", {
        params: {
          page: currentBatchPage,
          sort: currentBatchSort,
          rows: currentBatchPerpage,
          search: filterTextBatch,
          batch_status: batch_status,
        },
      })

      .then((res) => {
        if (res.status === 200) {
          setDataBatch(res.data.results);
          setTotalBatch(res.data.total);
          setLoadingBatch(false);
        } else {
          const error = new Error(res.error);
          setErrors(res.error);
          setErrorShow(true);
        }
      })
      .catch((error) => {
        setErrors(error.message);
        setErrorShow(true);
        setLoadingBatch(false);
      });
  }

  /**
   * updateArticle
   * @param {*} params
   */
  async function updateBatch(status) {
    axios
      .post("/Administrator/createEmailBatch", {
        batch_id: currentbatchid,
        batch_name: batchname,
        batch_status: status,
        subject: batchselectsubject,
        body: batchselectbody,
        withCredentials: true,
      })

      .then((res) => {
        if (res.status === 200) {
          setBatchShow(false);
          fetchEmailBatches(); ////Todo: change the refresh var
        } else {
          const error = new Error(res.error);
          setErrors(res.error);
          setErrorShow(true);
        }
      })
      .catch((error) => {
        setErrors(error.message);
        setErrorShow(true);
        setLoadingBatch(false);
      });
  }

  async function deleteBatch(params) {
    axios
      .post("/Administrator/deleteArticle", {
        id: params.id,
        deleted: params.deleted,
        withCredentials: true,
      })

      .then((res) => {
        if (res.status === 200) {
          setBatchShow(false);
          fetchEmailBatches();
        } else {
          const error = new Error(res.error);
          setErrors(res.error);
          setErrorShow(true);
        }
      })
      .catch((error) => {
        setErrors(error.message);
        setErrorShow(true);
        setLoadingBatch(false);
      });
  }
  useEffect(() => {
    fetchEmailTemplates();
    fetchEmailBatches();
  }, [
    currentPage,
    currentBatchPage,
    currentSort,
    currentBatchSort,
    currentPerpage,
    currentBatchPerpage,
    resetPaginationToggle,
    resetPaginationToggleBatch,
    // pubtypefilter,
    togglerefresh,
  ]);

  const handlePageChange = (page) => {
    setLoading(true);
    setCurrentPage(page);
  };
  const handlePerRowsChange = (perPage, page) => {
    setLoading(true);
    setPerpage(perPage);
    setCurrentPage(page);
  };
  const handleSort = (column, sortDirection) => {
    setLoading(true);
    setSort([column.selector, sortDirection]);
  };
  const handlePageBatchesChange = (page) => {
    setLoadingBatch(true);
    setCurrentBatchPage(page);
  };
  const handlePerRowsBatchesChange = (perPage, page) => {
    setLoadingBatch(true);
    setPerpageBatch(perPage);
    setCurrentBatchPage(page);
  };

  const handleBatchesSort = (column, sortDirection) => {
    setLoadingBatch(true);
    setBatchSort([column.selector, sortDirection]);
  };

  const handleClose = () => {
    setTemplateShow(false);
    setBatchShow(false);
    setErrorShow(false);
  };

  const handleTemplateAction = (e) => {
    e.preventDefault();
    setAction(e.target.getAttribute("data-action"));

    switch (e.target.getAttribute("data-action")) {
      case "add-template":
        setTemplateSubject("");
        setTemplateName("");
        setTemplateCode("");
        setDescription("");
        setTemplateType("");
        setTemplateShow(true);
        break;
      case "edit-template":
        setTemplateid(e.target.getAttribute("data-value"));
        var getdata = _.findWhere(data, {
          id: parseInt(e.target.getAttribute("data-value"), 10),
        });
        setDescription(getdata.body);
        setTemplateType(getdata.type);
        setTemplateSubject(getdata.subject);
        setTemplateName(getdata.name);
        setTemplateCode(getdata.code);
        setTemplateShow(true);
        break;
      case "delete-template":
        var getdata = _.findWhere(data, {
          id: parseInt(e.target.getAttribute("data-value"), 10),
        });
        setDeleteData({
          id: e.target.getAttribute("data-value"),
          description: getdata.name,
          action: e.target.getAttribute("data-action"),
        });
        setDeleteShow(true);
        setTogglerefresh(!togglerefresh);
        break;
    }
  };
  /**
   * handlebatchaction
   * @param {*} e
   */
  const handleBatchAction = (e) => {
    e.preventDefault();
    switch (e.target.getAttribute("data-action")) {
      case "add-batch":
        setAction(e.target.getAttribute("data-action"));
        setBatchid("");
        setBatchName("");
        setBatchselectbody("");
        setBatchselectsubject("");
        setBatchStatus("Queued");
        setBatchShow(true);

        break;
      case "edit-batch":
        setAction(e.target.getAttribute("data-action"));
        setBatchid(e.target.getAttribute("data-value"));
        var getdata = _.findWhere(dataBatch, {
          id: parseInt(e.target.getAttribute("data-value"), 10),
        });
        setBatchselectbody(getdata.message);
        setBatchselectsubject(getdata.subject);
        setBatchName(getdata.batch_name);
        setBatchStatus(getdata.batch_status);
        setBatchShow(true);
        break;
      case "delete-batch":
        var getdata = _.findWhere(dataBatch, {
          id: parseInt(e.target.getAttribute("data-value"), 10),
        });
        // setDeleteData({
        //   id: e.target.getAttribute("data-value"),
        //   description: getdata.title,
        //   action: e.target.getAttribute("data-action"),
        // });
        // setDeleteShow(true);
        setTogglerefresh(!togglerefresh);
        break;
    }
  };
  const columns_template = [
    {
      name: "ID",
      selector: "id",
      sortable: true,
      wrap: true,
      maxWidth: "60px",
    },
    {
      name: "Code",
      selector: "code",
      sortable: true,
      maxWidth: "80px",
      wrap: true,
    },
    {
      name: "Name",
      selector: "name",
      sortable: true,
      maxWidth: "100px",
      wrap: true,
    },
    {
      name: "Subject",
      selector: "subject",
      sortable: true,
      maxWidth: "600px",
      wrap: true,
    },
    {
      name: "Type",
      selector: "type",
      sortable: true,
      maxWidth: "30px",
      wrap: true,
    },
    {
      name: "Date Created",
      selector: "createdAt",
      sortable: true,
      wrap: true,
    },

    {
      cell: (row) => (
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            <i className="fa fa-cog"></i>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              href="#/add-pub"
              onClick={handleTemplateAction}
              data-value={row.id}
              data-action="add-template"
            >
              <i className="fa fa-plus-circle"></i> Add New
            </Dropdown.Item>
            <Dropdown.Item
              href="#/edit"
              onClick={handleTemplateAction}
              data-value={row.id}
              data-action="edit-template"
            >
              <i className="fa fa-pencil"></i> Edit
            </Dropdown.Item>
            <Dropdown.Item
              href="#/delete"
              onClick={handleTemplateAction}
              data-value={row.id}
              data-action="delete-template"
            >
              <i className="fa fa-trash text-danger"></i> Delete
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  //cols article
  const columns_batch = [
    {
      name: "ID",
      selector: "id",
      sortable: true,
      maxWidth: "80px",
    },
    {
      name: "Broadcast Batch",
      selector: "batch_name",
      sortable: true,
      maxWidth: "600px",
      wrap: true,
    },

    {
      name: "Status",
      selector: "batch_status",
      sortable: false,
      maxWidth: "300px",
    },
    {
      name: "Date Created",
      selector: "createdAt",
      sortable: true,
      maxWidth: "80px",
    },

    {
      cell: (row) => (
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            <i className="fa fa-cog"></i>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              href="#/profile"
              onClick={handleBatchAction}
              data-value={row.id}
              data-action="add-batch"
            >
              <i className="fa fa-plus-circle"></i> Add New
            </Dropdown.Item>
            <Dropdown.Item
              href="#/edit"
              onClick={handleBatchAction}
              data-value={row.id}
              data-action="edit-batch"
            >
              <i className="fa fa-pencil"></i> Edit
            </Dropdown.Item>
            <Dropdown.Item
              href="#/delete"
              onClick={handleBatchAction}
              data-value={row.id}
              data-action="delete-batch"
            >
              <i className="fa fa-trash-o text-danger"></i> Delete
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const handleClear = () => {
    setResetPaginationToggle(!resetPaginationToggle);
    setFilterTextTemplate("");
  };

  const handleClearBatch = () => {
    setResetPaginationToggleBatch(!resetPaginationToggleBatch);
    setFilterTextBatch("");
  };

  const handleTemplate = (event) => {
    axios
      .post("/Administrator/createEmailTemplate", {
        template_id: currenttempid,
        subject: templatesubject,
        name: templatename,
        description: description,
        code: templatecode,
        type: templatetype ? templatetype : "Broadcast",
        withCredentials: true,
      })
      .then((res) => {
        if (res.status === 200) {
          setTemplateShow(false);
          // setLoading(true);
          // fetchEmailTemplates();
          setTogglerefresh(!togglerefresh);
        } else {
          const error = new Error(res.error_message);
          setErrors(res.error_message);
          setErrorShow(true);
        }
      })
      .catch((error) => {
        setErrors(error);
        setErrorShow(true);
        setLoading(false);
      });

    event.preventDefault();
  };

  const handleBatch = (event, type) => {
    event.preventDefault();
    updateBatch(type);
  };

  const search = () => {
    setTogglerefresh(!togglerefresh);
  };

  const searchBatch = () => {
    setTogglerefresh(!togglerefresh);
  };
  /**
   * Handle Tab - changing tabs
   * @param {*}
   */
  const handleTab = (t) => {
    //fetchEmailBatches();
    setTogglerefresh(!togglerefresh);

    setTabkey(t);
  };
  async function deleteTemplate(template_id) {
    axios
      .post("/Administrator/deleteEmailTemplate", {
        template_id: template_id,
        withCredentials: true,
      })
      .then((res) => {
        if (res.status === 200) {
          setTogglerefresh(!togglerefresh);
        } else {
          const error = new Error(res.error);
          setErrors(res.error);
          setErrorShow(true);
        }
      })
      .catch((error) => {
        setErrors(error);
        setErrorShow(true);
        setLoading(false);
      });
  }

  const handleDelete = (e) => {
    //Todo: Full or soft delete - to confirm
    setDeleteShow(false);
    switch (e.target.getAttribute("data-action")) {
      case "delete-template":
        setLoading(true);
        deleteTemplate(e.target.getAttribute("data-value"));
        break;
    }
  };

  const subHeaderTemplate = useMemo(() => {
    return (
      <div>
        <Row className="mt-1">
          <Button type="button" className="round-left" onClick={handleClear}>
            X
          </Button>
          <input
            id="search"
            type="text"
            placeholder="Search by Title or URL"
            value={filterTextTemplate}
            onChange={(e) => setFilterTextTemplate(e.target.value)}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                search();
              }
            }}
          />
          <Button type="button" className="round-right" onClick={search}>
            <i className="fa fa-search"></i>
          </Button>
          &nbsp;
          <Button
            type="button"
            onClick={(event) => {
              setAction("add-template");
              setTemplateSubject("");
              setTemplateName("");
              setDescription("");
              setTemplateType("");
              setTemplateShow(true);
            }}
            className="round-all"
          >
            <i className="fa fa-plus-circle"></i>
          </Button>
        </Row>
      </div>
    );
  }, [filterTextTemplate, resetPaginationToggle]);

  const subHeaderBatch = useMemo(() => {
    return (
      <div>
        <Row className="mt-1">
          <Button
            type="button"
            className="round-left"
            onClick={handleClearBatch}
          >
            X
          </Button>
          <input
            id="search_article"
            type="text"
            placeholder="Search by Title or URL"
            value={filterTextBatch}
            onChange={(e) => setFilterTextBatch(e.target.value)}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                searchBatch();
              }
            }}
          />
          <Button type="button" className="round-right" onClick={searchBatch}>
            <i className="fa fa-search"></i>
          </Button>
          &nbsp; &nbsp;
          <Button
            type="button"
            onClick={(event) => {
              setAction("add-batch");
              setBatchName("");
              setBatchselectbody("");
              setBatchselectsubject("");
              setBatchStatus("Test");
              setBatchShow(true);
            }}
            className="round-all"
          >
            <i className="fa fa-plus-circle"></i>
          </Button>
        </Row>
      </div>
    );
  }, [filterTextBatch, resetPaginationToggleBatch]);

  return (
    <main role="main" className="container-fluid main mt-2">
      <ErrorMdl error={hasError} setErrorShow={setErrorShow} show={errorshow} />
      <DeleteMdl
        deletedata={deletedata}
        setDeleteShow={setDeleteShow}
        deleteshow={deleteshow}
        handleDelete={handleDelete}
      />

      <EmailTemplateMdl
        template={{
          template_id: currenttempid,
          subject: templatesubject,
          name: templatename,
          code: templatecode,
          description: description,
          templatetype: templatetype,
          currentaction: currentaction,
        }}
        setTemplateSubject={setTemplateSubject}
        setTemplateName={setTemplateName}
        setTemplateCode={setTemplateCode}
        setDescription={setDescription}
        setTemplateType={setTemplateType}
        handleClose={handleClose}
        handleTemplate={handleTemplate}
        show={templateshow}
      />

      <BatchMdl
        batch={{
          batch_id: currentbatchid,
          batch_name: batchname,
          batch_status: batchstatus,
          currentaction: currentaction,
        }}
        batchselectbody={batchselectbody}
        batchselectsubject={batchselectsubject}
        setBatchselectbody={setBatchselectbody}
        setBatchselectsubject={setBatchselectsubject}
        setBatchName={setBatchName}
        setBatchStatus={setBatchStatus}
        handleClose={handleClose}
        handleBatch={handleBatch}
        show={batchshow}
      />

      <Row>
        <SidebarAdmin></SidebarAdmin>

        <Col>
          <Row>
            <Col md="12">
              <Breadcrumb>
                <Breadcrumb.Item href="/Admin">
                  <i className="fa fa-home"></i> Home
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  <i className="fa fa-envelope"></i> Emails
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <Tabs id="tabs" activeKey={tabkey} onSelect={handleTab}>
                <Tab
                  eventKey="broadcast"
                  title={
                    <div>
                      <i className="fa fa-university"></i> eBroadcast
                    </div>
                  }
                >
                  <Row>
                    <Col>
                      <DataTable
                        striped={true}
                        customStyles={customStyles}
                        columns={columns_batch}
                        data={dataBatch}
                        progressPending={loadingBatch}
                        highlightOnHover
                        defaultSortField="date_entered"
                        onSort={handleBatchesSort}
                        sortServer
                        pagination
                        paginationServer
                        paginationTotalRows={currentBatchTotal}
                        onChangeRowsPerPage={handlePerRowsBatchesChange}
                        onChangePage={handlePageBatchesChange}
                        subHeader
                        subHeaderComponent={subHeaderBatch}
                      />
                    </Col>
                  </Row>
                </Tab>
                <Tab
                  eventKey="templates"
                  title={
                    <div>
                      <i className="fa fa-book"></i> Templates
                    </div>
                  }
                >
                  <Row>
                    <Col>
                      <DataTable
                        striped={true}
                        customStyles={customStyles}
                        columns={columns_template}
                        data={data}
                        progressPending={loading}
                        //expandableRows
                        highlightOnHover
                        //defaultSortField="pubication"
                        //expandableRowsComponent={<UserExpandedComponent />}
                        onSort={handleSort}
                        sortServer
                        pagination
                        paginationServer
                        paginationTotalRows={currentTotal}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        subHeader
                        subHeaderComponent={subHeaderTemplate}
                      />
                    </Col>
                  </Row>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Col>
      </Row>
    </main>
  );
};

export default Emails;
