import { userBanner } from "./userBanner";
import checkDismiss from "./checkDismiss";
const axios = require("axios");
const store = require("store");
const _ = require("underscore");

export async function fectchInstitutionAuth(isPro = false) {
  return axios
    .get("/AuthInstitution", { withCredentials: true })

    .then((res) => {
      if (res.status === 200) {
        store.set("instution_auth_tokens", res.data);
        return res.data;
      } else {
        return false;
      }
    })
    .catch((error) => {
      console.log("authinstition error", error);
      return false;
    });
}
