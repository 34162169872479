import React, { useState } from "react";
import _ from "underscore";
import { Row, Col, Form, Button, Card, Alert } from "react-bootstrap";
import Sidebar from "../components/SidebarRegistration";
// import LoginForm from "../components/LoginForm";
import { useAuth } from "./../context/auth";
import ErrorMdl from "../components/errorMdl";

const axios = require("axios");

const Passwordreset = (props) => {
  const [username, setUsername] = useState("");
  const [hasError, setErrors] = useState(false);
  const [errorshow, setErrorShow] = useState(false);
  const [emailSent, setEmailSent] = useState(true);

  const { setAuthTokens } = useAuth();

  const handleReset = (event) => {
    setAuthTokens("");
    axios
      .post("/User/LoginResetEmail", {
        username: username,
      })

      .then((res) => {
        if (res) {
          if (!_.isUndefined(res.status) && res.status === 200) {
            //display message;
            setEmailSent(false);
          } else {
            //todo setError;
            setErrors(res.Error);
            setErrorShow(true);
          }
        } else {
          // const error = new Error(res.error);
          setErrors("Invalid User name or Password");
          setErrorShow(true);
        }
        //callback for the response
      })
      .catch((error) => {
        setErrors(error);
        setErrorShow(true);
      });

    event.preventDefault();
  };

  return (
    <main role="main" className="main mt-2">
      <ErrorMdl error={hasError} setErrorShow={setErrorShow} show={errorshow} />

      <Row></Row>
      <Row>
        <Col md="3">
          <Sidebar {...props} />
        </Col>
        <Col md="6">
          <Row>
            <Col>
              <Card variant="info">
                <Card.Body>
                  <Card.Header>
                    <h2>Request password reset</h2>
                  </Card.Header>

                  <br></br>
                  <Form onSubmit={handleReset}>
                    <Form.Group controlId="Username">
                      <Form.Label>
                        <i className="fa fa-envelope"></i> Enter Your Email
                        Address
                      </Form.Label>

                      <Form.Control
                        type="email"
                        name="Username"
                        placeholder="Enter email"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                      />
                      <Alert variant="success" hidden={emailSent}>
                        Password reset email sent. If you missed the email,
                        please check your spam/junk folder.
                      </Alert>
                    </Form.Group>

                    <Button
                      className="btn btn-primary btn-lg pull-right"
                      type="submit"
                    >
                      Reset Password <i className="fa fa-sign-in"></i>
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </main>
  );
};

export default Passwordreset;
