import React, { useState, useEffect } from "react";
import { Row, Col, Alert } from "react-bootstrap";

import ErrorMdl from "../components/errorMdl";
import { ResultList } from "../components/search/ResultList";
import Pagination from "../components/search/Pagination";
import "../components/assets/css/Latest.css";

const axios = require("axios");
const _ = require("underscore");
const Top = (props) => {
  const [errorshow, setErrorShow] = useState(false);
  const [hasError, setErrors] = useState(false);
  const [data, setData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSort, setSort] = useState("y");
  const [loading, setLoading] = useState(true);
  const [currentParams, setParams] = useState("");
  // const [currentTab, setTab] = useState("latest");
  const [currentPublication, setPublication] = useState();
  const [currentAdvfilter, setAdvfilter] = useState("latest");
  const [currentCategory, setCategory] = useState(
    "(1 OR 11 OR 16 OR 18 OR 10 OR 9 OR 4 OR 34 OR 13)"
  );
  const [isStarred, setStarred] = useState([]);
  const [snippetshow, setSnippetShow] = useState(true);
  const [firstPage, setFirstPage] = useState(1);
  const [articleChecked, setArticleChecked] = useState([]);
  const [articleClicked, setArticleClicked] = useState([]);
  const [isInstitutionPro, setInstitutionPro] = useState({});

  /**
   * fetchData
   * Send a call to express API for standard search
   * @param {*} keywords
   */
  async function fetchData() {
    axios
      .get("/Search", {
        params: getParameters(),
      })

      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
          setLoading(false);
          setStarred(
            !_.isUndefined(res.data.summary.starred_data)
              ? res.data.summary.starred_data
              : []
          );
          setInstitutionPro(
            !_.isUndefined(res.data.summary.institution_auth)
              ? res.data.summary.institution_auth
              : {}
          );
          setArticleClicked([]);
        } else {
          const error = new Error(res.error_message);
          // setErrors(res.error_message);
        }
      })
      .catch((error) => {
        // setErrors(error.response.data.error_message);
        setLoading(false);
      });
  }
  useEffect(() => {
    fetchData();
  }, [currentPage, currentPublication, currentSort, currentAdvfilter]);
  var keywords = !_.isUndefined(props.match.params)
    ? props.match.params.criteria
    : "";
  function getParameters() {
    keywords = keywords.replace("-", " ");
    var params = {
      criteria: keywords,
      search_type: "standard",
      page: currentPage,
      sort: currentSort,
      category_id: currentCategory,
      publication_id: currentPublication,
      adv_filter: currentAdvfilter,
      articles: articleChecked,
    };
    setParams(params);
    return params;
  }

  /**
   * Change Page function
   * @param {int} pageNumber
   */
  const paginate = (e, pageNumber) => {
    setCurrentPage(pageNumber);
    e.preventDefault();
  };

  /**
   * publication Selection
   * @param {*} e
   * @param {*} publication
   */
  const publicationSelect = (e, publication) => {
    setPublication(publication);
    e.preventDefault();
  };

  const handleTabclick = (e, params) => {
    setSort(params.sort);
    if (params.type === "latest")
      setCategory("(1 OR 11 OR 16 OR 18 OR 10 OR 9 OR 4 OR 34 OR 13)");
    else setCategory("");

    setAdvfilter(params.type);
    e.preventDefault();
  };

  var summary_set = "",
    documents_set = "",
    publications_set = [],
    libkeydata = "";
  if (data && data.summary) {
    const { summary, documents } = data;
    summary_set = summary;
    documents_set = documents;
    libkeydata = summary.libkeydata ? summary.libkeydata : null;
    publications_set = summary.publications_data
      ? summary.publications_data
      : [];
  }

  return (
    <main role="main" className="container-fluid main mt-2">
      <ErrorMdl error={hasError} setErrorShow={setErrorShow} show={errorshow} />

      <Row>
        <Col>
          <Alert variant="success" className="full">
            <Alert.Heading>
              Latest & greatest articles for {keywords}
            </Alert.Heading>
            <p>
              The Trip Database is a leading resource to help health
              professionals find trustworthy answers to their clinical
              questions. Users can access the latest research evidence and
              guidance to answer their clinical questions. We have a large
              collection of systematic reviews, clinical guidelines, regulatory
              guidance, clinical trials and many other forms of evidence. If you
              wanted the latest trusted evidence on acute pain or other clinical
              topics then use Trip today.
            </p>
            <p className="mb-0">
              This page lists the very latest high quality evidence on acute
              pain and also the most popular articles. Popularity measured by
              the number of times the articles have been clicked on by fellow
              users in the last twelve months.
            </p>
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col md="9">
          <Row>
            <Col>
              <div className="site-search">
                Top results for {keywords}
                <nav className="nav nav-tabs">
                  <a
                    id="search-latest"
                    href="#"
                    onClick={(e) => {
                      handleTabclick(e, { sort: "y", type: "latest" });
                      e.preventDefault();
                    }}
                    role="tab"
                    data-rb-event-key="standardsearch"
                    aria-controls="search-form-tabpane-standardsearch"
                    aria-selected="true"
                    className={
                      currentAdvfilter === "latest"
                        ? "nav-item nav-link active"
                        : ""
                    }
                  >
                    Latest{" "}
                  </a>
                  <a
                    id="search-latest"
                    href="#"
                    onClick={(e) => {
                      handleTabclick(e, { sort: "v12m", type: "greatest" });
                      e.preventDefault();
                    }}
                    role="tab"
                    data-rb-event-key="standardsearch"
                    aria-controls="search-form-tabpane-standardsearch"
                    aria-selected="true"
                    className={
                      currentAdvfilter === "greatest"
                        ? "nav-item nav-link active"
                        : ""
                    }
                  >
                    Greatest{" "}
                  </a>
                </nav>
                {/* <ul className="lg-nav">
                  <li>
                    <a
                      href="!/#"
                      onClick={(e) => {
                        handleTabclick(e, { sort: "y", type: "latest" });
                      }}
                      className={currentAdvfilter == "latest" ? "active" : ""}
                    >
                      Latest
                    </a>
                  </li>
                  <li>
                    <a
                      href="!/#"
                      onClick={(e) => {
                        handleTabclick(e, { sort: "v12m", type: "greatest" });
                      }}
                      className={currentAdvfilter == "greatest" ? "active" : ""}
                    >
                      Most popular
                    </a>
                  </li>
                </ul> */}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              {hasError ? (
                <Alert variant="danger" dismissible>
                  <Alert.Heading>{hasError}!</Alert.Heading>
                  <p>
                    We cannot process your request due to, please try again
                    later!
                  </p>
                </Alert>
              ) : (
                ""
              )}

              <ResultList
                isPro={
                  (!_.isUndefined(props.isPro) ? props.isPro : false) ||
                  (!_.isEmpty(isInstitutionPro) ? true : false)
                }
                resultset={documents_set ? documents_set : []}
                publicationset={publications_set}
                start={summary_set.start}
                loading={loading}
                publicationSelect={publicationSelect}
                searchParams={currentParams}
                setStarred={setStarred}
                isStarred={isStarred}
                snippetshow={snippetshow}
                articleChecked={articleChecked}
                setArticleChecked={setArticleChecked}
                articleClicked={articleClicked}
                setArticleClicked={setArticleClicked}
                libkeydata={libkeydata}
              />
              <Pagination
                postsPerPage={20}
                totalArticles={
                  summary_set && summary_set.total ? summary_set.total : 0
                }
                paginate={paginate}
                firstPage={firstPage}
                setFirstPage={setFirstPage}
                currentPage={currentPage}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </main>
  );
};

export default Top;
