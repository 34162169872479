import React from "react";
import { Form, Button, Modal } from "react-bootstrap";
import _ from "underscore";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "../../components/assets/css/Ckeditorcustom.css";

const EmailTemplateMdl = ({
  template,
  setTemplateSubject,
  setTemplateName,
  setTemplateCode,
  setDescription,
  setTemplateType,
  handleClose,
  handleTemplate,
  show,
}) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      animation={false}
      size="xl"
      enforceFocus={false}
      data-focus="false"
    >
      <Form width="100%" onSubmit={handleTemplate}>
        <Modal.Header closeButton>
          <Modal.Title className="text-success">
            <i className="fa fa-book"></i>{" "}
            {template.currentaction && template.currentaction == "add-template"
              ? "Add Email Template"
              : "Update Email Template -" + template.template_id}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="template.code">
            <Form.Label>
              <i className="fa fa-envelope"></i> Code
            </Form.Label>
            <Form.Control
              type="text"
              name="name"
              placeholder="Template Code"
              value={template.code ? template.code : ""}
              onChange={(e) => setTemplateCode(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="template.subject">
            <Form.Label>
              <i className="fa fa-envelope"></i> Name
            </Form.Label>
            <Form.Control
              type="text"
              name="name"
              placeholder="Template Name"
              value={template.name ? template.name : ""}
              onChange={(e) => setTemplateName(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="template.subject">
            <Form.Label>
              <i className="fa fa-envelope"></i> Subject
            </Form.Label>
            <Form.Control
              type="text"
              name="subject"
              placeholder="Subject"
              value={template.subject ? template.subject : ""}
              onChange={(e) => setTemplateSubject(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="template.body">
            <Form.Label>
              <i className="fa fa-copy"></i> Body
            </Form.Label>
            <CKEditor
              style={{ height: "500px" }}
              editor={ClassicEditor}
              data={template.description ? template.description : ""}
              onInit={(editor) => {
                // You can store the "editor" and use when it is needed.
              }}
              onChange={(event, editor) => {
                //  const data = editor.getData();
                setDescription(editor.getData());
              }}
            />
          </Form.Group>

          <Form.Group controlId="template.type">
            <Form.Label>Select Type</Form.Label>
            <Form.Control
              as="select"
              defaultValue={
                template.templatetype ? template.templatetype : "Broadcast"
              }
              onChange={(e) => setTemplateType(e.target.value)}
            >
              <option>Auto</option>
              <option>Broadcast</option>
            </Form.Control>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            <i className="fa fa-times"></i> Close
          </Button>
          <Button variant="primary" onClick={handleTemplate}>
            <i className="fa fa-disc"></i> Save Changes
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default EmailTemplateMdl;
