import React from "react";

export default () => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 13.61 15.81"
  >
    <g>
      <path
        d="M9.88,2.53l2.65,2.63H9.88ZM2.52,2.38H9.06V6h3.69v9H2.52Zm-.85-.76V15.81H13.61V5.16L10,1.62Z"
        fill="#b3b3b3"
        fillRule="evenodd"
      ></path>
      <polygon
        points="9.21 0.79 8.05 0 0 0 0 14.36 0.8 14.36 0.8 0.79 9.21 0.79"
        fill="#b3b3b3"
        fillRule="evenodd"
      ></polygon>
      <rect x="3.95" y="8.14" width="7.38" height="0.43" fill="#b3b3b3"></rect>
      <rect x="3.95" y="9.42" width="7.38" height="0.43" fill="#b3b3b3"></rect>
      <rect x="3.95" y="10.7" width="7.38" height="0.43" fill="#b3b3b3"></rect>
      <rect x="3.95" y="11.98" width="7.38" height="0.43" fill="#b3b3b3"></rect>
      <rect x="3.95" y="13.26" width="7.38" height="0.43" fill="#b3b3b3"></rect>
      <rect x="3.95" y="4.3" width="4.18" height="0.43" fill="#b3b3b3"></rect>
      <rect x="3.95" y="5.58" width="4.18" height="0.43" fill="#b3b3b3"></rect>
      <rect x="3.95" y="6.86" width="7.38" height="0.43" fill="#b3b3b3"></rect>
    </g>
  </svg>
);
