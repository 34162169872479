import React, { useState, useEffect } from "react";
import { Row, Col, Alert } from "react-bootstrap";

import ErrorMdl from "../components/errorMdl";
import { ResultList } from "../components/search/ResultList";
import "../components/assets/css/Latest.css";

const axios = require("axios");
const _ = require("underscore");
const Document = (props) => {
  const [errorshow, setErrorShow] = useState(false);
  const [hasError, setErrors] = useState(false);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [currentParams, setParams] = useState("");
  const [isStarred, setStarred] = useState([]);
  const [articleChecked, setArticleChecked] = useState([]);
  const [articleClicked, setArticleClicked] = useState([]);
  /**
   * fetchData
   * Send a call to express API for standard search
   * @param {*} keywords
   */
  async function fetchData() {
    axios
      .get("/Search", {
        params: getParameters(),
      })

      .then((res) => {
        if (res.status === 200) {
          if (!_.isUndefined(res.data.url)) {
            window.location.href = res.data.url;
            var redirect =
              '<p>If redirect is taking longer, click <a href="' +
              res.data.url +
              '">' +
              res.data.title +
              "</a></p>";
            setData(redirect);
          } else {
            setData("<p>Document not found!</p>");
          }
          setLoading(false);
        } else {
          const error = new Error(res.error_message);
          // setErrors(res.error_message);
        }
      })
      .catch((error) => {
        console.log("error", error);
        //setErrors(error.response.data);
        setLoading(false);
      });
  }
  useEffect(() => {
    document.title = "Document : Trip Medical Database";

    fetchData();
  }, []);
  var keywords = !_.isUndefined(props.match.params)
    ? props.match.params.criteria
    : "";
  function getParameters() {
    keywords = keywords.replace("-", " ");
    var params = {
      criteria: keywords,
      search_type: "document",
      page: 1,
      sort: "y",
      document_id: keywords,
    };
    setParams(params);
    return params;
  }

  /**
   * publication Selection
   * @param {*} e
   * @param {*} publication
   */
  const publicationSelect = (e, publication) => {
    e.preventDefault();
  };

  var summary_set = "",
    documents_set = "",
    categories_set = "",
    clinical_area_set = [],
    refinements_set = [],
    publications_set = [];
  if (data && data.summary) {
    const { summary, documents } = data;
    summary_set = summary;
    documents_set = documents;
    categories_set = summary.all_categories ? summary.all_categories : [];
    clinical_area_set = summary.clinical_area ? summary.clinical_area : [];
    refinements_set = summary.refinements ? summary.refinements : [];
    publications_set = summary.publications_data
      ? summary.publications_data
      : [];
  }

  return (
    <main role="main" className="container-fluid main mt-2">
      <ErrorMdl error={hasError} setErrorShow={setErrorShow} show={errorshow} />
      <Row>
        <Col md="9">
          <Row>
            <Col>
              <ResultList
                resultset={documents_set ? documents_set : []}
                publicationset={publications_set}
                start={0}
                loading={loading}
                publicationSelect={publicationSelect}
                searchParams={currentParams}
                setStarred={setStarred}
                isStarred={isStarred}
                snippetshow={true}
                articleChecked={articleChecked}
                setArticleChecked={setArticleChecked}
                articleClicked={articleClicked}
                setArticleClicked={setArticleClicked}
                // libkeydata={libkeydata}
                // categorySelect={categorySelect}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </main>
  );
};

export default Document;
