import React from "react";

export default () => (
  <svg
    id="lines_3_new"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1815 579"
  >
    <g opacity="0.4">
      <g>
        <path
          d="M1556.38,91.86a10,10,0,1,0-6.06,12.78,10,10,0,0,0,6.06-12.78h0"
          fill="#8a5ea5"
        />
        <path
          d="M1664.89,279.82a6.5,6.5,0,1,0-4,8.31,6.51,6.51,0,0,0,4-8.31"
          fill="#8a5ea5"
        />
        <path
          d="M1736.84,451.63a10,10,0,1,0-6.69,12.47l.47-.16a9.77,9.77,0,0,0,6.22-12.31"
          fill="#8a5ea5"
        />
        <path
          d="M1764.62,359a9.5,9.5,0,1,0-5.76,12.15,9.5,9.5,0,0,0,5.76-12.15"
          fill="#8a5ea5"
        />
        <path
          d="M1704,545.6a9.5,9.5,0,1,0,5.76-12.13h0A9.5,9.5,0,0,0,1704,545.6"
          fill="#8a5ea5"
        />
        <path
          d="M1339.73,26.4a11.5,11.5,0,1,0,7-14.7h0a11.5,11.5,0,0,0-7,14.69"
          fill="#8a5ea5"
        />
        <path
          d="M1574.18,195.42a7.5,7.5,0,1,0,4.56-9.59,7.51,7.51,0,0,0-4.56,9.59"
          fill="#8a5ea5"
        />
        <path
          d="M1619.63,212.64a8,8,0,1,0,4.91-10.19h0a8,8,0,0,0-4.88,10.18"
          fill="#8a5ea5"
        />
        <path
          d="M1624.29,484.38a13.5,13.5,0,1,0-8.18,17.25,13.49,13.49,0,0,0,8.18-17.25v0"
          fill="#8a5ea5"
        />
        <path
          d="M1585.52,331a12.5,12.5,0,1,0-7.56,16h0a12.51,12.51,0,0,0,7.56-16"
          fill="#8a5ea5"
        />
        <path
          d="M1565.68,584.93a9.5,9.5,0,1,0-5.74,12.15,9.5,9.5,0,0,0,5.74-12.15v0"
          fill="#8a5ea5"
        />
        <path
          d="M956.21,513.7A13.5,13.5,0,1,0,948,531h0a13.5,13.5,0,0,0,8.17-17.25"
          fill="#8a5ea5"
        />
        <path
          d="M1377.15,44.89a10.5,10.5,0,1,0,6.36-13.42h0a10.5,10.5,0,0,0-6.36,13.42"
          fill="#8a5ea5"
        />
        <path
          d="M1477.9,202.17a11.5,11.5,0,1,0,7-14.69h0a11.49,11.49,0,0,0-7,14.68h0"
          fill="#8a5ea5"
        />
        <path
          d="M1287,128.56a8,8,0,1,0-4.85,10.22h0a8,8,0,0,0,4.85-10.22"
          fill="#8a5ea5"
        />
        <path
          d="M1525.68,211.13a10,10,0,1,0-6,12.78h0a10,10,0,0,0,6-12.78"
          fill="#8a5ea5"
        />
        <path
          d="M1800.7,226.7a8,8,0,1,0-4.9,10.2h0a8,8,0,0,0,4.89-10.19"
          fill="#8a5ea5"
        />
        <path
          d="M1718.63,114.84a6.06,6.06,0,1,0-3.72,7.72h0a6.06,6.06,0,0,0,3.7-7.72"
          fill="#8a5ea5"
        />
        <path
          d="M1192.81,14.35a6.05,6.05,0,1,0-3.71,7.72h0a6,6,0,0,0,3.7-7.71"
          fill="#8a5ea5"
        />
        <path
          d="M1245.13,61.75a12,12,0,1,0-7.26,15.34h0a12,12,0,0,0,7.26-15.34"
          fill="#8a5ea5"
        />
        <path
          d="M1243,510.55a12,12,0,1,0-7.26,15.34h0a12,12,0,0,0,7.26-15.34"
          fill="#8a5ea5"
        />
        <path
          d="M1454.87,339.93a8.5,8.5,0,1,0-5.15,10.87,8.5,8.5,0,0,0,5.15-10.87"
          fill="#8a5ea5"
        />
        <path
          d="M1559.91,462.37a8.5,8.5,0,1,0-5.15,10.87,8.5,8.5,0,0,0,5.15-10.87"
          fill="#8a5ea5"
        />
        <path
          d="M1536.17,461.49a12,12,0,1,0-7.27,15.35,12,12,0,0,0,7.27-15.35"
          fill="#8a5ea5"
        />
        <path
          d="M1364.55,452.83a12.5,12.5,0,1,0,7.58-16h0a12.52,12.52,0,0,0-7.57,16"
          fill="#8a5ea5"
        />
        <path
          d="M1443.11,262.37a11.5,11.5,0,1,0,7-14.7h0a11.49,11.49,0,0,0-7,14.68v0"
          fill="#8a5ea5"
        />
        <path
          d="M1376.84,359.29a8.5,8.5,0,1,0-5.16,10.87,8.51,8.51,0,0,0,5.16-10.87h0"
          fill="#8a5ea5"
        />
        <path
          d="M1804.52,33.08a8.5,8.5,0,1,0-10.87-5.14,8.49,8.49,0,0,0,10.87,5.14h0"
          fill="#8a5ea5"
        />
        <path
          d="M1321.65,142.19a9.5,9.5,0,1,0-5.75,12.15,9.49,9.49,0,0,0,5.75-12.15h0"
          fill="#8a5ea5"
        />
        <path
          d="M1160.26,139.3a13.5,13.5,0,1,0-8.17,17.25h0a13.5,13.5,0,0,0,8.17-17.25"
          fill="#8a5ea5"
        />
        <path
          d="M1262.75,273.13a11.5,11.5,0,1,0-14.7-7h0a11.5,11.5,0,0,0,14.69,7"
          fill="#8a5ea5"
        />
        <path
          d="M1175.64,313.25a11.5,11.5,0,1,0-7,14.71,11.5,11.5,0,0,0,7-14.71v0"
          fill="#8a5ea5"
        />
        <path
          d="M1310.18,580.06a12,12,0,1,0-7.27,15.34h0a12,12,0,0,0,7.27-15.33"
          fill="#8a5ea5"
        />
        <path
          d="M1168.54,537.26a9.28,9.28,0,1,0-5.63,11.86h0a9.28,9.28,0,0,0,5.62-11.86"
          fill="#8a5ea5"
        />
        <path
          d="M1453.22,585.22a12,12,0,1,0,7.26-15.34h0a12,12,0,0,0-7.26,15.34"
          fill="#8a5ea5"
        />
        <path
          d="M1730.88,149.56a8.5,8.5,0,1,0-5.15,10.87,8.5,8.5,0,0,0,5.15-10.87h0"
          fill="#8a5ea5"
        />
        <path
          d="M1787.57,22.65a9.5,9.5,0,1,0-5.76,12.15,9.5,9.5,0,0,0,5.76-12.15v0"
          fill="#8a5ea5"
        />
        <path
          d="M1083.69,63.11a9.5,9.5,0,1,0-12.14-5.75h0a9.48,9.48,0,0,0,12.12,5.75h0"
          fill="#8a5ea5"
        />
        <path
          d="M816,566.53a8,8,0,1,0-10.24-4.85h0A8,8,0,0,0,816,566.53h0"
          fill="#8a5ea5"
        />
        <path
          d="M1135.56,484.35a8,8,0,1,0-10.25-4.85h0a8,8,0,0,0,10.24,4.86h0"
          fill="#8a5ea5"
        />
        <path
          d="M1502.93,41.59a8,8,0,1,0-10.25-4.85h0a8,8,0,0,0,10.23,4.85h0"
          fill="#8a5ea5"
        />
        <path
          d="M1071.38,556.65a8,8,0,1,0-10.25-4.85h0a8,8,0,0,0,10.23,4.85h0"
          fill="#8a5ea5"
        />
        <path
          d="M1073.72,528.4a11.67,11.67,0,1,0-14.92-7.06h0a11.66,11.66,0,0,0,14.89,7.07h0"
          fill="#8a5ea5"
        />
        <path
          d="M881.43,423.85A8,8,0,1,0,871.18,419h0a8,8,0,0,0,10.23,4.86h0"
          fill="#8a5ea5"
        />
        <path
          d="M1283.24,458.07a8,8,0,1,0-10.24-4.85h0a8,8,0,0,0,10.23,4.85h0"
          fill="#8a5ea5"
        />
        <path
          d="M1616.52,51.43a8,8,0,1,0-10.25-4.85h0a8,8,0,0,0,10.23,4.85h0"
          fill="#8a5ea5"
        />
        <path
          d="M1064.71,160.15a8,8,0,1,0-4.91,10.19h0a8,8,0,0,0,4.88-10.18"
          fill="#8a5ea5"
        />
        <path
          d="M1065.86,71.06a10.5,10.5,0,1,0-6.37,13.41h0a10.49,10.49,0,0,0,6.36-13.41"
          fill="#8a5ea5"
        />
        <path
          d="M882.35,210.14A10.5,10.5,0,1,0,876,223.56h0a10.5,10.5,0,0,0,6.37-13.41"
          fill="#8a5ea5"
        />
        <path
          d="M955.91,77.48a8.45,8.45,0,1,0-5.13,10.79h0a8.44,8.44,0,0,0,5.12-10.78"
          fill="#8a5ea5"
        />
        <path
          d="M693.39,573.63a8.45,8.45,0,1,0-5.13,10.78h0a8.44,8.44,0,0,0,5.12-10.78"
          fill="#8a5ea5"
        />
        <path
          d="M1182.83,398.82a12.5,12.5,0,1,0-7.57,16h0a12.48,12.48,0,0,0,7.57-16h0"
          fill="#8a5ea5"
        />
        <path
          d="M1061.28,394.44a7.5,7.5,0,0,0-9.58-4.55h0a7.5,7.5,0,0,0-4.53,9.59h0a7.49,7.49,0,0,0,9.57,4.54h0a7.49,7.49,0,0,0,4.56-9.56h0"
          fill="#8a5ea5"
        />
        <path
          d="M1130.94,390.27a10,10,0,1,0-6.06,12.78h0a10,10,0,0,0,6.06-12.78"
          fill="#8a5ea5"
        />
        <path
          d="M1149.73,321.14a10.5,10.5,0,1,0-6.36,13.42h0a10.51,10.51,0,0,0,6.36-13.42"
          fill="#8a5ea5"
        />
      </g>
      <polyline
        points="1164.82 317.13 1258.89 262.3 1147.55 143.84 1057.16 162.79 1139.84 324.67 1147.55 139.25 1279.5 130.34 1258.89 266.98 1368.83 362.15 1139.84 328.63 1171.06 403.02 1121.52 393.63 1134.04 477.54 1280.55 450.52 1054.22 396.95 1069.79 518.24 1231.73 514.59 1159.79 541.08 1171.06 408.29 1374.48 448.63 1446.87 345.02 1453.94 258.5 1312.71 143.84 1488.72 198.31 1516.26 214.49 1524.87 465.54 1573.75 335.18 1551.9 465.24 1613.91 488.92 1556.96 588.14 1498.28 607.28 1464.52 581.18 1554.94 584.09 1635.18 617.5 1712.91 542.42 1727.3 453.53 1755.67 362.15 1656.48 282 1627.18 210 1722.41 152.42 1712.91 116.85 1581.25 192.9 1546.96 95.22 1793.15 229.35 1856.5 145.4 1921.15 456.73 1866.66 510.41 1955.44 551.92 1805.65 625.5 1708.35 540.11 1611.58 483.36 1722.41 454.52 1577.55 335.18 1298.88 584.09 1390.12 635.93 1467.59 576.47 1231.73 510.41 1187.1 605.81 1068.68 549.1 870.71 601.03 943.5 518.24 815.37 558.98 682.8 576.46 878.73 419.06 870.71 213.66 1055.97 74.58 946.17 80.31 1080.49 53.78 1233.83 64.08 1187.1 16.35 995.84 -45.91 1350.56 22.54 1387.04 42.06 1308.86 148.15 1500.23 35.86 1613.82 44.66 1648.98 -28.39 1778.62 25.08 1799.25 25.08 1862.56 -10.39"
        fill="none"
        stroke="#8a5ea5"
        strokeMiterlimit="10"
      />
      <polyline
        points="873.45 213.66 1139.84 325.73 1054.22 396.95 869.68 214.66 946.83 81.64 993.83 -45.91"
        fill="none"
        stroke="#8a5ea5"
        strokeMiterlimit="10"
      />
      <polyline
        points="1297.78 584.09 1280.55 450.52 1231.73 514.59 1373.82 450.52 1283.59 450.52 1367.79 361.25 1448.97 342.79 1255.91 264.03 1171.06 403.02"
        fill="none"
        stroke="#8a5ea5"
        strokeMiterlimit="10"
      />
      <polyline
        points="1611.58 488.92 1754.69 362.15 1551.9 465.24 1556.74 588.14 1523.68 465.54 1464.52 579 1376.33 448.63 1527.01 465.54"
        fill="none"
        stroke="#8a5ea5"
        strokeMiterlimit="10"
      />
      <polyline
        points="995.84 -45.91 1080.49 53.78 1055.97 74.58 1147.27 145.87 1233.83 64.08 1279.5 130.34 1350.56 22.54 1308.86 148.15 1258.89 262.3 1452.52 257.35 1488.72 198.31 1546.96 95.22 1500.23 35.86 1648.98 -28.39 1712.91 116.85 1550.76 97.29 1627.18 210 1582.21 194.27 1573.75 334.29 1515.26 214.49 1658.77 282 1573.08 339.17"
        fill="none"
        stroke="#8a5ea5"
        strokeMiterlimit="10"
      />
      <polyline
        points="943.5 518.24 878.59 415.53 1054.22 396.95 947.94 517.41 1069.79 518.24 1068.68 549.1 1159.79 541.08 1073.42 518.24 1121.52 393.63"
        fill="none"
        stroke="#8a5ea5"
        strokeMiterlimit="10"
      />
      <polyline
        points="873.45 213.66 1057.16 162.79 1055.97 74.58"
        fill="none"
        stroke="#8a5ea5"
        strokeMiterlimit="10"
      />
      <polyline
        points="1080.49 53.78 1187.1 16.35 1350.56 22.54 1500.23 35.86 1387.04 42.06 1488.72 198.31"
        fill="none"
        stroke="#8a5ea5"
        strokeMiterlimit="10"
      />
      <polyline
        points="1546.96 95.22 1613.82 44.66 1712.91 116.85 1778.62 25.08 1793.15 229.35 1755.67 362.15 1920.69 454.52 1727.3 453.53 1866.66 510.41"
        fill="none"
        stroke="#8a5ea5"
        strokeMiterlimit="10"
      />
      <polyline
        points="1139.84 324.67 1164.82 317.13 1147.55 143.84"
        fill="none"
        stroke="#8a5ea5"
        strokeMiterlimit="10"
      />
      <line
        x1="1279.5"
        y1="130.34"
        x2="1312.71"
        y2="143.84"
        fill="none"
        stroke="#8a5ea5"
        strokeMiterlimit="10"
      />
      <line
        x1="1452.52"
        y1="257.35"
        x2="1573.75"
        y2="334.29"
        fill="none"
        stroke="#8a5ea5"
        strokeMiterlimit="10"
      />
    </g>
  </svg>
);
