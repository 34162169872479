import React, { useState, useEffect } from "react";
import {
  Badge,
  Form,
  Row,
  Col,
  Button,
  Alert,
  Spinner,
  Card,
} from "react-bootstrap";
import Sidebar from "../components/SidebarRegistration";
import _ from "underscore";
import { loadStripe } from "@stripe/stripe-js";
import ErrorMdl from "../components/errorMdl";
import PriceListMdl from "../components/personal/PriceList";
// import ProComponent from "../components/ProComponent";
import ProFeatures from "../components/ProFeatures";

const dateFormat = require("dateformat");
const queryString = require("query-string");

//Todo: get pkey from above and use it
const axios = require("axios");
// const stripePromise = loadStripe(
//   "pk_test_51Gt9fJGzB3G6sucEoO2v90JvFexlerEDz0RKAKHXoVTX8jSoLnD1SnBPReczP3Tgm92D73BxesOb5Zn7vvaHfw7N00qaiJzhd3"
// );
const stripePromise = loadStripe("pk_live_yU2xAbih3W42FcfcORUmCOWI");
const Subscription = (props) => {
  const [validated, setValidated] = useState(false);
  const [hasError, setErrors] = useState(false);
  const [errorshow, setErrorShow] = useState(false);
  const [show, setShow] = useState(false);
  const [publicKey, setPublicKey] = useState(false);
  const [data, setData] = useState({});
  const [dataexpiry, setDataExpiry] = useState("");
  const [sid, setSession] = useState("");
  const [loading, setLoading] = useState(true);
  /**
   * fecthcheckoutsession
   */
  async function fetchCheckoutSession() {
    axios
      .post("/Auth/Subscription", {
        withCredentials: true,
      })

      .then((res) => {
        setSession(res.data.sessionId);
        checkout(res.data.sessionId);
      })
      .catch((error) => {
        if (!_.isEmpty(error)) setErrors(error);
      });
  }
  async function fetchExpiry() {
    axios
      .get("/Auth/getSubscriptionExpiry", {
        withCredentials: true,
      })

      .then((res) => {
        setDataExpiry(res.data);
      })
      .catch((error) => {
        if (!_.isEmpty(error)) setErrors(error);
      });
  }

  const handleModal = (e) => {
    setShow(true);
    e.preventDefault();
  };

  useEffect(() => {
    document.body.classList.remove("homepage");
    if (!_.isUndefined(props.isPro) ? props.isPro : false)
      document.body.classList.add("pro-user");
    else document.body.classList.add("free-user");

    function fetchPulickey() {
      return axios
        .get(" /Auth/getPublicKey", {
          withCredentials: true,
        })

        .then((res) => {
          const stripePromise = loadStripe(res.data.PublicKey);
        })
        .catch((error) => {
          if (!_.isEmpty(error)) setErrors(error);
        });
    }
    // fetchPulickey();
    fetchExpiry();
    //setPublicKey(key.PublicKey);
    //console.log(key);
  }, []);
  async function checkout(sessionId) {
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      sessionId,
    });
  }
  const handleClick = async (event) => {
    // Call your backend to create the Checkout session.
    const chekc = await fetchCheckoutSession();
  };
  //
  // dateFormat(row.createdAt, "dd/mm/yyyy H:M:s")
  return (
    <main role="main" className="main mt-2">
      <Row></Row>
      {hasError ? (
        <Alert variant="danger">You must login to access this page!</Alert>
      ) : (
        <Row>
          <Col md="3">
            <Sidebar {...props} />
          </Col>
          <Col md="9">
            {props.isPro === true ? (
              <Card>
                <Card.Body>
                  <Card.Header className="label label-success">
                    <h2>Renew</h2> your <span className="pro-label">PRO</span>{" "}
                    Account
                  </Card.Header>
                  <br></br>
                  {dataexpiry && (
                    <div
                      className="mb-2 alert alert-info"
                      role="alert"
                      dangerouslySetInnerHTML={{ __html: dataexpiry.message }}
                    ></div>
                  )}

                  <Button className="btn btn-default" onClick={handleClick}>
                    Pay $55.00
                  </Button>
                </Card.Body>
              </Card>
            ) : (
              <Card>
                <Card.Body>
                  <Card.Header className="label label-success">
                    <h2>Upgrade</h2> to Trip{" "}
                    <span className="pro-label">PRO</span>
                  </Card.Header>
                  <p>
                    {" "}
                    You're one step away from becoming a Pro user The Trip Pro
                    offering takes an already wonderful – and free – version of
                    Trip and makes it even better. With more content, more
                    functionality, no adverts and more, it really makes the Trip
                    experience even better.{" "}
                  </p>
                  <br></br>
                  {dataexpiry && (
                    <div
                      className="mb-2 alert alert-info"
                      role="alert"
                      dangerouslySetInnerHTML={{ __html: dataexpiry.message }}
                    ></div>
                  )}
                  <p>
                    Institutional and personal subscriptions are available
                    <a
                      href="https://blog.tripdatabase.com/2021/07/03/institutional-subscriptions/"
                      className="btn btn-primary"
                      target="_blank"
                      // onClick={handleModal}
                    >
                      click here to see Institutional price list
                    </a>
                    . The table below highlights the main differences between
                    the various offerings. You can upgrade in one of 2 ways:
                    Institutional Subscription (VAT, where applicable, will
                    apply) If your institution has not already purchased an
                    upgrade, you can find out pricing details here, prices start
                    at $215 per annum. To set this up or ask any further
                    questions please get in touch via our ‘Contact Us‘ page.
                    Personal Subscription The annual subscription cost is $55
                    (US Dollars). If applicable, Trip Database will pay VAT at
                    your home countries rate . If you are VAT registered you may
                    claim this back. Any questions then please get in touch via
                    our ‘Contact Us‘ page. You can purchase your own
                    professional/private subscription by payment card, and this
                    will take effect IMMEDIATELY:
                  </p>

                  <Button className="btn btn-default" onClick={handleClick}>
                    Pay $55.00
                  </Button>
                  <br></br>
                </Card.Body>
                <Card.Footer>
                  {/* <ProComponent
                    isPro={props}
                    handleModal={handleModal}
                  ></ProComponent> */}
                  <div className="why-go-pro-content">
                    <ProFeatures></ProFeatures>
                  </div>
                </Card.Footer>
              </Card>
            )}
          </Col>
        </Row>
      )}
      <ErrorMdl error={hasError} setErrorShow={setErrorShow} show={errorshow} />
      <PriceListMdl setShow={setShow} show={show}></PriceListMdl>
    </main>
  );
};

export default Subscription;
