import React from "react";

const Pagination = ({
  postsPerPage,
  totalArticles,
  paginate,
  firstPage,
  setFirstPage,
  currentPage,
}) => {
  const pageNumbers = [];
  const lastPage = Math.ceil(totalArticles / postsPerPage);
  var max_pages = totalArticles <= 20 ? lastPage : firstPage + 11;
  for (let i = firstPage; i <= max_pages; i++) {
    if (i <= lastPage) pageNumbers.push(i);
  }

  return (
    totalArticles > 20 && (
      <nav aria-label="...">
        <ul className="pagination">
          {firstPage > 1 ? (
            <li key={lastPage - 1} className="page-item">
              <span
                onClick={(e) => {
                  paginate(e, firstPage - 12);
                  setFirstPage(firstPage - 12);
                }}
                className="page-link"
              >
                Previous
              </span>
            </li>
          ) : (
            ""
          )}
          {pageNumbers.map((number) => (
            <li
              key={number}
              className={
                number == currentPage ? "page-item active" : "page-item"
              }
            >
              <a
                className="page-link"
                href="#!"
                onClick={(e) => paginate(e, number)}
              >
                {number}{" "}
                {number == currentPage && (
                  <span className="sr-only">(current)</span>
                )}
              </a>
            </li>
          ))}
          {lastPage > 12 ? (
            <li key={lastPage} className="page-item">
              <a
                onClick={(e) => paginate(e, lastPage)}
                href="!#"
                className="page-link"
              >
                .. {lastPage}
              </a>
            </li>
          ) : (
            ""
          )}
          {lastPage > 12 && max_pages < lastPage ? (
            <li key={lastPage + 1} className="page-item">
              <a
                onClick={(e) => {
                  paginate(e, max_pages + 1);
                  setFirstPage(max_pages + 1);
                }}
                href="!#"
                className="page-link"
              >
                Next
              </a>
            </li>
          ) : (
            ""
          )}
        </ul>
      </nav>
    )
  );
};

export default Pagination;
