import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Tabs,
  Tab,
  Row,
  Col,
  Dropdown,
  DropdownButton,
  SplitButton,
  Table,
  Breadcrumb,
  Button,
  Form,
  Card,
} from "react-bootstrap";
// import styled from "styled-components";
import AsyncSelect from "react-select/async";
import DataTable, { pagination, createTheme } from "react-data-table-component";
import SidebarAdmin from "../../components/SidebarAdmin";
import ErrorMdl from "../../components/errorMdl";
import DeleteMdl from "../../components/deleteMdl";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../components/assets/css/Tabs.css";
import "../../components/assets/css/Buttons.css";
import PublicationMdl from "./PublicationMdl";
import GuidelinesMdl from "./GuidelinesMdl";

import ArticleMdl from "./ArticleMdl";
import {
  setQueryStringValue,
  getQueryStringValue,
} from "../../components/search/queryString";
import PublicationPromiseOptions from "./PublicationPromiseOptions";
const dateFormat = require("dateformat");
const axios = require("axios");
const _ = require("underscore");
const { customStyles } = require("./tableStyle");

const Publications = (props) => {
  const [file, setFile] = useState(""); // storing the uploaded file    // storing the recived file from backend
  // const [filedata, getFile] = useState({ name: "", path: "" });
  const [progress, setProgess] = useState(0); // progess bar
  const el = useRef(); // accesing input element
  const [show, setShow] = useState(false);
  const [guidelinesshow, setGuidelinesShow] = useState(false);
  const [publicationshow, setPublicationShow] = useState(false);
  const [errorshow, setErrorShow] = useState(false);
  const [deleteshow, setDeleteShow] = useState(false);
  const [togglerefresh, setTogglerefresh] = useState(false);
  const [cutoff_date, setCutoffDate] = useState(new Date());
  const [tabkey, setTabkey] = useState("pub");
  const [hasError, setErrors] = useState(false);
  //const [currentid, setID] = useState("");
  //Publication
  const [methodology, setMethodology] = useState(0);
  const [evidencegrading, setEvidenceGrading] = useState(0);
  const [systematicevidence, setSystematicEvidence] = useState(0);
  const [funding, setFunding] = useState(0);
  const [conflict, setConflict] = useState(0);
  const [comments, setComments] = useState("");

  const [categoryid, setCategoryid] = useState("");
  const [publication, setPublication] = useState("");
  const [description, setDescription] = useState("");
  const [pubmedabbrev, setPubmedabbrev] = useState("");
  const [puburl, setPuburl] = useState("");
  const [weight, setWeight] = useState("");
  const [datechecked, setDatechecked] = useState("");
  const [pubtype, setPublicationtype] = useState("");
  const [pubhide, setPublicationhide] = useState(1);
  const [pubtypefilter, setPublicationtypefilter] = useState("All");
  const [pubdeletefilter, setPublicationtypeDeletefilter] = useState(0);
  const [pubfilter, setPublicationfilter] = useState("");

  //const [inputValue, setInputValue] = useState("");
  // const [pubaction, setPublicationaction] = useState("");

  //publication datatable
  const [data, setData] = useState([]);
  const [guidelinesexist, setGuidelinesexist] = useState("");
  const [deletedata, setDeleteData] = useState([]);

  const [lookupdata, setLookupData] = useState([]);
  const [lookupdatapublication, setLookupDataPublication] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [filterTextPub, setFilterTextPub] = useState("");
  const [filterTextArticle, setFilterTextArticle] = useState("");
  const [resetPaginationToggleArticle, setResetPaginationToggleArticle] =
    useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  // const [startDate, setStartDate] = useState(new Date());
  // const [endDate, setEndDate] = useState(futuredate);
  const [currentaction, setAction] = useState("none");
  const [currentPerpage, setPerpage] = useState(10);
  const [currentTotal, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [currentSort, setSort] = useState();
  const [currentpubid, setPubid] = useState("");

  //Article data table
  const [dataArticle, setDataArticle] = useState([]);
  const [lookupdataArticle, setLookupDataArticle] = useState([]);
  const [currentArticlePage, setCurrentArticlePage] = useState(1);
  const [currentArticlePerpage, setPerpageArticle] = useState(10);
  const [currentArticleTotal, setTotalArticle] = useState(0);
  const [loadingArticle, setLoadingArticle] = useState(true);
  const [currentArticleSort, setArticleSort] = useState();
  const [currentarticleid, setArticleid] = useState("");
  const [articletitle, setArticleTitle] = useState("");
  const [articleurl, setArticleURL] = useState("");
  const [articlepub, setArticlePub] = useState("");
  const [articlepubcurrent, setArticlePubcurrent] = useState({});
  const [articledate, setArticleDate] = useState("");
  const [articleindexed, setArticleIndexed] = useState(1);
  const [articleshow, setArticleShow] = useState(false);
  const [latest, setLatest] = useState(0);
  const [datesaved, setDateSaved] = useState(false);
  const [validated, setValidated] = useState(false);

  async function fetchCutoff() {
    axios
      .get("/Administrator/getDate")

      .then((res) => {
        if (res.status === 200) {
          if (!_.isUndefined(res.data.cdate))
            setCutoffDate(new Date(res.data.cdate));
        }
      })
      .catch((error) => {
        // setErrors(error.message);
        // setErrorShow(true);
        // setLoading(false);
      });
  }

  async function saveCutoffDate() {
    axios
      .post("/Administrator/saveDate", {
        cdate: cutoff_date,
        withCredentials: true,
      })

      .then((res) => {
        if (res.status === 200) {
          //do nothing
          setDateSaved(true);
          window.setTimeout(() => {
            setDateSaved(false);
          }, 2000);
        } else {
          // const error = new Error(res.error);
          setErrors(res.error);
          setErrorShow(true);
        }
      })
      .catch((error) => {
        setErrors(error.message);
        setErrorShow(true);
      });
  }

  /**
   * fetchPublications
   * Send a call to express API for standard search
   * @param {*} keywords
   */
  async function fetchPublications() {
    axios
      .get("/Administrator/getPublications", {
        params: {
          page: currentPage,
          sort: currentSort,
          rows: currentPerpage,
          search: filterTextPub,
          pubtype: pubtypefilter,
          hidden: pubdeletefilter,
        },
      })

      .then((res) => {
        if (res.status === 200) {
          setData(res.data.results);
          setLookupData(res.data.lookupdata);
          setTotal(res.data.total);
          setLoading(false);
        } else {
          //  const error = new Error(res.error_message);
          setErrors(res.error_message);
          setErrorShow(true);
        }
      })
      .catch((error) => {
        setErrors(error.message);
        setErrorShow(true);
        setLoading(false);
      });
  }

  /**
   * updatePublication
   * @param {*} pub_id
   */
  async function updatePublication(params) {
    axios
      .get("/Administrator/updatePublications", {
        params: {
          hidden: params.hidden,
          id: params.id, //currentpubid,
        },
      })

      .then((res) => {
        if (res.status === 200) {
          fetchPublications();
          setLoading(false);
        } else {
          //  const error = new Error(res.error);
          setErrors(res.error);
          setErrorShow(true);
        }
      })
      .catch((error) => {
        setErrors(error.message);
        setErrorShow(true);
        setLoadingArticle(false); //Todo:check this
      });
  }

  /**
   * DeletePurgePublication
   * @param {*} pub_id
   */
  async function deletePurgePublication(params) {
    axios
      .get("/Administrator/deletePurgePublication", {
        params: {
          id: params.id, //currentpubid,
          mode: params.mode,
        },
      })

      .then((res) => {
        if (res.status === 200) {
          fetchPublications();
          setLoading(false);
        } else {
          //  const error = new Error(res.error);
          setErrors(res.error);
          setErrorShow(true);
        }
      })
      .catch((error) => {
        setErrors(error.message);
        setErrorShow(true);
        setLoadingArticle(false); //Todo:check this
      });
  }
  /**
   * Delete Guidelines Score
   * @param {*} params
   */
  async function deleteGuidelines(params) {
    console.log("r", params);
    axios
      .post("/Administrator/deleteGuideline", {
        pub_id: params.id,
        withCredentials: true,
      })

      .then((res) => {
        if (res.status === 200) {
          fetchPublications();
          setLoading(false);
        } else {
          //  const error = new Error(res.error);
          setErrors(res.error);
          setErrorShow(true);
        }
      })
      .catch((error) => {
        setErrors(error.message);
        setErrorShow(true);
        setLoadingArticle(false); //Todo:check this
      });
  }
  /**
   *  fetchArticles
   * @param {*} pub_id
   */
  async function fetchArticles(pub_id) {
    axios
      .get("/Administrator/getArticles", {
        params: {
          page: currentArticlePage,
          sort: currentArticleSort,
          rows: currentArticlePerpage,
          search: filterTextArticle,
          deleted: 0,
          pub_id: pub_id,
        },
      })

      .then((res) => {
        if (res.status === 200) {
          setDataArticle(res.data.results);
          setLookupDataArticle(res.data.lookupdata);
          setTotalArticle(res.data.total);
          setLoadingArticle(false);
        } else {
          //  const error = new Error(res.error_message);
          setErrors(res.error_message);
          setErrorShow(true);
        }
      })
      .catch((error) => {
        setErrors(error.message);
        setErrorShow(true);
        setLoadingArticle(false);
      });
  }
  /**
   * fetch all publications
   */
  async function fetchAllPublications() {
    axios
      .get("/Administrator/getAllPublications", {
        withCredentials: true,
      })

      .then((res) => {
        if (res.status === 200) {
          setLookupDataPublication(res.data.lookupdata);
        } else {
          //  const error = new Error(res.error_message);
          setErrors(res.error_message);
          setErrorShow(true);
        }
      })
      .catch((error) => {
        setErrors(error.message);
        setErrorShow(true);
        setLoadingArticle(false);
      });
  }
  /**
   * fetcharticle
   */
  async function fetchArticle(article_id) {
    axios
      .get("/Administrator/getArticle", {
        params: { article_id: article_id },
        withCredentials: true,
      })

      .then((res) => {
        if (res.status === 200) {
          setAction("edit-article");
          setArticleid(article_id);
          if (!_.isUndefined(res.data.results)) {
            let dt = "";
            if (res.data.results.trip_date) {
              dt = new Date(res.data.results.trip_date);
              dt = dt.toISOString().split("T")[0];
            }
            setArticleTitle(res.data.results.title);
            setArticlePub(res.data.results.publicationId);
            setArticlePubcurrent({
              value: res.data.results.publiationId,
              label: res.data.results["publication.publication"],
            });
            setArticleURL(res.data.results.url);
            setArticleDate(dt);
            setArticleIndexed(res.data.results.needs_indexing);
            setArticleShow(true);
          }
        } else {
          //  const error = new Error(res.error_message);
          setErrors(res.error_message);
          setErrorShow(true);
        }
      })
      .catch((error) => {
        setErrors(error.message);
        setErrorShow(true);
        setLoadingArticle(false);
      });
  }
  /**
   * updateArticle
   * @param {*} params
   */
  async function updateArticle() {
    axios
      .post("/Administrator/updateArticle", {
        article_id: currentarticleid,
        publicationId: articlepub,
        title: articletitle,
        url: articleurl,
        trip_date: articledate,
        needs_indexing: articleindexed,
        withCredentials: true,
      })

      .then((res) => {
        if (res.status === 200) {
          setArticleShow(false);
          fetchArticles();
        } else {
          //  const error = new Error(res.error);
          setErrors(res.error);
          setErrorShow(true);
        }
      })
      .catch((error) => {
        setErrors(error.message);
        setErrorShow(true);
        setLoadingArticle(false);
      });
  }
  /**
   * Delete individual article
   * @param {*} params
   */
  async function deleteArticle(params) {
    axios
      .post("/Administrator/deleteArticle", {
        id: params.id,
        deleted: params.deleted,
        withCredentials: true,
      })

      .then((res) => {
        if (res.status === 200) {
          setArticleShow(false);
          fetchArticles();
        } else {
          // const error = new Error(res.error);
          setErrors(res.error);
          setErrorShow(true);
        }
      })
      .catch((error) => {
        setErrors(error.message);
        setErrorShow(true);
        setLoadingArticle(false);
      });
  }
  useEffect(() => {
    var article_id = getQueryStringValue("article_id");
    fetchPublications();
    if (currentpubid) fetchArticles(currentpubid);
    fetchAllPublications();
    if (article_id) {
      fetchArticle(article_id);
      document.getElementById("tabs-tab-articles").click();
    }
  }, [
    currentPage,
    currentArticlePage,
    currentSort,
    currentpubid,
    currentArticleSort,
    currentPerpage,
    currentArticlePerpage,
    resetPaginationToggle,
    resetPaginationToggleArticle,
    pubtypefilter,
    pubdeletefilter,
    togglerefresh,
  ]);

  const handlePublicationtypeFilter = (e) => {
    setPublicationtypefilter(e.target.value);
    setLoading(true);
  };
  const handlePublicationDelete = (e) => {
    setPublicationtypeDeletefilter(e.target.value);
    setLoading(true);
  };

  const handlePageChange = (page) => {
    setLoading(true);
    setCurrentPage(page);
  };
  const handlePerRowsChange = (perPage, page) => {
    setLoading(true);
    setPerpage(perPage);
    setCurrentPage(page);
  };
  const handleSort = (column, sortDirection) => {
    setLoading(true);
    setSort([column.selector, sortDirection]);
  };
  const handlePageArticlesChange = (page) => {
    setLoadingArticle(true);
    setCurrentArticlePage(page);
  };
  const handlePerRowsArticlesChange = (perPage, page) => {
    setLoadingArticle(true);
    setPerpageArticle(perPage);
    setCurrentArticlePage(page);
  };

  const handleArticlesSort = (column, sortDirection) => {
    setLoadingArticle(true);
    setArticleSort([column.selector, sortDirection]);
  };

  const handleClose = (e) => {
    //e.preventDefault();
    setShow(false);
    setPublicationShow(false);
    setGuidelinesShow(false);
    setArticleShow(false);
    setErrorShow(false);
    setQueryStringValue("article_id", "");
  };

  const handlePubAction = (e) => {
    e.preventDefault();
    switch (e.target.getAttribute("data-action")) {
      case "add-pub":
        clearPublication();
        setAction(e.target.getAttribute("data-action"));
        setPublicationShow(true);
        break;
      case "edit-pub":
        clearPublication();
        setAction(e.target.getAttribute("data-action"));
        setPubid(e.target.getAttribute("data-value"));
        var getdata = _.findWhere(data, {
          id: parseInt(e.target.getAttribute("data-value"), 10),
        });
        setPublication(getdata.publication);
        setPubmedabbrev(getdata.pubmedabbrev);
        setPublicationtype(getdata.publication_type);
        setDescription(getdata.description);
        setPuburl(getdata.url);
        setWeight(getdata.weight);
        setDatechecked(getdata.date_checked);
        setCategoryid(getdata.categoryId);
        setPublicationShow(true);
        break;
      case "edit-guidelines":
        clearGuidelines();
        setPubid(e.target.getAttribute("data-value"));
        var getdata = _.findWhere(data, {
          id: parseInt(e.target.getAttribute("data-value"), 10),
        });
        setPublication(getdata.publication);
        setGuidelinesexist(getdata.guidelines_exist);
        if (getdata.guidelines_exist) {
          setMethodology(getdata.methodology);
          setFunding(getdata.funding);
          setConflict(getdata.conflict);
          setComments(getdata.comments);
          setEvidenceGrading(getdata.evidence_grading);
          setSystematicEvidence(getdata.systematic_evidence);
        }
        setGuidelinesShow(true);

        break;
      case "hide-pub":
        var getpubdata = _.findWhere(data, {
          id: parseInt(e.target.getAttribute("data-value"), 10),
        });
        setDeleteData({
          id: e.target.getAttribute("data-value"),
          info: "Hide",
          description:
            " will only hide the publication and keep the related articles for " +
            getpubdata.publication,
          action: e.target.getAttribute("data-action"),
        });
        setDeleteShow(true);
        setTogglerefresh(!togglerefresh);
        break;
      case "delete-pub":
        var getpubdata = _.findWhere(data, {
          id: parseInt(e.target.getAttribute("data-value"), 10),
        });
        setDeleteData({
          id: e.target.getAttribute("data-value"),
          description:
            " will hide the publication and permanently delete all the related articles for " +
            getpubdata.publication,
          info: " Delete ",
          action: e.target.getAttribute("data-action"),
        });
        setDeleteShow(true);
        setTogglerefresh(!togglerefresh);
        break;
      case "delete-guidelines":
        var getpubdata = _.findWhere(data, {
          id: parseInt(e.target.getAttribute("data-value"), 10),
        });
        setDeleteData({
          id: e.target.getAttribute("data-value"),
          description:
            " will delete guidelines score for " + getpubdata.publication,
          info: " Delete ",
          action: e.target.getAttribute("data-action"),
        });
        setDeleteShow(true);
        setTogglerefresh(!togglerefresh);
        break;
      case "purge-pub":
        var getpubdata = _.findWhere(data, {
          id: parseInt(e.target.getAttribute("data-value"), 10),
        });
        setDeleteData({
          id: e.target.getAttribute("data-value"),
          info: "Purge",
          description:
            " will retain the publication and permanently delete all the related articles for " +
            getpubdata.publication,
          action: e.target.getAttribute("data-action"),
        });
        setDeleteShow(true);
        setTogglerefresh(!togglerefresh);
        break;
    }
  };
  const handleArticleAction = (e) => {
    e.preventDefault();
    switch (e.target.getAttribute("data-action")) {
      case "add-article":
        setAction(e.target.getAttribute("data-action"));
        setArticleid("");
        setArticleTitle("");
        setArticlePub("");
        setArticleURL("");
        setArticleDate("");
        setArticleIndexed(1);
        setArticlePubcurrent("");
        setArticleShow(true);

        break;
      case "edit-article":
        setAction(e.target.getAttribute("data-action"));
        setArticleid(e.target.getAttribute("data-value"));
        var getdata = _.findWhere(dataArticle, {
          id: parseInt(e.target.getAttribute("data-value"), 10),
        });
        setArticleTitle(getdata.title);
        setArticlePub(getdata.publicationId);
        setArticlePubcurrent(getdata.publicationdata);
        setArticleURL(getdata.url);
        setArticleDate(getdata.trip_date);
        setArticleIndexed(getdata.needs_indexing);
        setArticleShow(true);
        break;
      case "delete-article":
        var getdata = _.findWhere(dataArticle, {
          id: parseInt(e.target.getAttribute("data-value"), 10),
        });
        setDeleteData({
          id: e.target.getAttribute("data-value"),
          description: !_.isUndefined(getdata)
            ? getdata.title
            : e.target.getAttribute("data-title"),
          action: e.target.getAttribute("data-action"),
        });
        setDeleteShow(true);
        setTogglerefresh(!togglerefresh);
        break;
    }
  };
  var today = new Date();
  const columns_pub = [
    {
      name: "ID",
      selector: "id",
      sortable: true,
      wrap: true,
      maxWidth: "100px",
    },
    {
      name: "Publication Name",
      selector: "publication",
      sortable: true,
      maxWidth: "600px",
      wrap: true,
      cell: (row) => (
        <div>
          <a href={row.url} target="_blank" rel="noopener noreferrer">
            <i className="fa fa-external-link"></i>
          </a>{" "}
          <b> {row.publication}</b>
        </div>
      ),
    },

    {
      name: "URL",
      selector: "url",
      sortable: true,
      wrap: true,
    },
    {
      name: "Date checked",
      selector: "date_checked",
      sortable: true,
      wrap: true,
    },
    {
      name: "Articles",
      button: true,
      cell: (row) => (
        <a
          href="#/"
          onClick={publicationClick}
          data-value={row.id}
          data-action="pubclick"
          rel="noopener noreferrer"
        >
          Articles ({row.total_articles}) <i className="fa fa-arrow-right"></i>
        </a>
      ),
    },
    {
      cell: (row) => (
        <Dropdown align={{ lg: "end" }}>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            <i className="fa fa-cog"></i>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              href="#/add-pub"
              onClick={handlePubAction}
              data-value={row.id}
              data-action="add-pub"
            >
              <i className="fa fa-plus-circle"></i> Add
            </Dropdown.Item>
            <Dropdown.Item
              href="#/edit"
              onClick={handlePubAction}
              data-value={row.id}
              data-action="edit-pub"
            >
              <i className="fa fa-pen"></i> Edit
            </Dropdown.Item>

            <Dropdown.Item
              href="#/hide"
              onClick={(e) => {
                if (row.hidden === 0) setPublicationhide(1);
                else setPublicationhide(0);
                handlePubAction(e);

                e.preventDefault();
              }}
              data-value={row.id}
              data-action="hide-pub"
            >
              <i
                className={
                  row.hidden
                    ? "fa fa-eye text-warning"
                    : "fa fa-eye-slash text-warning"
                }
              ></i>{" "}
              {row.hidden ? "Unhide" : "Hide"}
            </Dropdown.Item>
            <Dropdown.Item
              href="#/deletepub"
              onClick={handlePubAction}
              data-value={row.id}
              data-action="delete-pub"
            >
              <i className="fa fa-trash text-danger"></i> Delete Publication
            </Dropdown.Item>
            <Dropdown.Item
              href="#/purgepub"
              onClick={handlePubAction}
              data-value={row.id}
              data-action="purge-pub"
            >
              <i className="fa fa-minus text-success"></i> Purge Publication
            </Dropdown.Item>
            <Dropdown.Item
              href="#/publication"
              onClick={publicationClick}
              data-value={row.id}
              data-action="pubclick"
            >
              <i className="fa fa-arrow-right"></i> Articles
            </Dropdown.Item>
            <Dropdown.Item
              href="#/guidelines"
              onClick={handlePubAction}
              data-value={row.id}
              data-action="edit-guidelines"
            >
              <i className="fa fa-bars"></i>{" "}
              {row.guidelines_exist === null
                ? "Add Guidelines Score"
                : "Update Guidelines Score"}
            </Dropdown.Item>
            <Dropdown.Item
              href="#/deletepub"
              onClick={handlePubAction}
              data-value={row.id}
              data-action="delete-guidelines"
              disabled={row.guidelines_exist === null ? true : false}
            >
              <i className="fa fa-times text-danger"></i> Delete Guidelines
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
      ignoreRowClick: true,
      allowOverflow: false,
      button: true,
    },
  ];

  //cols article
  const columns_article = [
    {
      name: "ID",
      selector: "id",
      sortable: true,
      maxWidth: "80px",
    },
    {
      name: "Article Title",
      selector: "title",
      sortable: true,
      maxWidth: "600px",
      wrap: true,
      cell: (row) => (
        <div>
          <a href={row.url} target="_blank" rel="noopener noreferrer">
            <i className="fa fa-external-link"></i>
          </a>{" "}
          {row.title}
        </div>
      ),
    },

    {
      name: "Publication",
      selector: "publication",
      sortable: false,
      maxWidth: "300px",
    },
    {
      name: "DOI",
      selector: "doi",
      sortable: true,
      maxWidth: "120px",
    },
    {
      name: "Date ",
      selector: "trip_date",
      sortable: true,
      maxWidth: "80px",
    },

    {
      cell: (row) => (
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            <i className="fa fa-cog"></i>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              href="#/profile"
              onClick={handleArticleAction}
              data-value={row.id}
              data-action="add-article"
            >
              <i className="fa fa-plus-circle"></i> Add New
            </Dropdown.Item>
            <Dropdown.Item
              href="#/edit"
              onClick={handleArticleAction}
              data-value={row.id}
              data-action="edit-article"
            >
              <i className="fa fa-pen"></i> Edit
            </Dropdown.Item>
            <Dropdown.Item
              href="#/delete"
              onClick={handleArticleAction}
              data-value={row.id}
              data-action="delete-article"
            >
              <i className="fa fa-times text-danger"></i> Delete
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const handleClear = () => {
    //if (filterText) {
    setResetPaginationToggle(!resetPaginationToggle);
    //setFilterText("");
    setFilterTextPub("");

    //fetchPublications();
    //}
  };

  const handleClearArticle = () => {
    //if (filterText) {
    setResetPaginationToggleArticle(!resetPaginationToggleArticle);
    //setFilterText("");
    setCurrentArticlePage(1);
    setFilterTextArticle("");

    //fetchPublications();
    //}
  };

  const handlePublication = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      axios
        .post("/Administrator/createPublication", {
          pub_id: currentpubid,
          categoryid: categoryid,
          publication: publication,
          description: description,
          pubmedabbrev: pubmedabbrev,
          publication_type: pubtype,
          puburl: puburl,
          weight: weight,
          date_checked: datechecked,
          withCredentials: true,
        })
        .then((res) => {
          if (res.status === 200) {
            setPublicationShow(false);
            setLoading(true);
            fetchPublications();
          } else {
            const error = new Error(res.error_message);
            setErrors(res.error_message);
            setErrorShow(true);
          }
        })
        .catch((error) => {
          console.log(error);

          setErrors(error);
          setErrorShow(true);
          setLoading(false);
        });
    }
    event.preventDefault();
    setValidated(true);
  };
  const handleGuidelines = (event) => {
    console.log("caleed");
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      console.log("not valid");
      event.stopPropagation();
    } else {
      axios
        .post("/Administrator/createGuidelines", {
          pub_id: currentpubid,
          methodology: methodology,
          evidence_grading: evidencegrading,
          systematic_evidence: systematicevidence,
          funding: funding,
          conflict: conflict,
          comments: comments,
          withCredentials: true,
        })
        .then((res) => {
          if (res.status === 200) {
            setGuidelinesShow(false);
            setLoading(true);
            fetchPublications();
          } else {
            const error = new Error(res.error_message);
            setErrors(res.error_message);
            setErrorShow(true);
          }
        })
        .catch((error) => {
          console.log(error);

          setErrors(error);
          setErrorShow(true);
          setLoading(false);
        });
    }
    event.preventDefault();
    setValidated(true);
  };
  const clearPublication = () => {
    setPubid("");
    setPublication("");
    setPubmedabbrev("");
    setDescription("");
    setCategoryid("");
    setPuburl("");
    setPublicationtype("");
    setWeight("");
    return true;
  };
  const clearGuidelines = () => {
    setPubid("");
    setPublication("");
    setMethodology(0);
    setFunding(0);
    setConflict(0);
    setComments("");
    setEvidenceGrading(0);
    setSystematicEvidence(0);
    return true;
  };
  const handleArticle = (event) => {
    event.preventDefault();
    updateArticle();
  };

  const search = () => {
    setTogglerefresh(!togglerefresh);
  };

  const searchArticle = () => {
    // if (currentpubid) fetchArticles(currentpubid);
    // else fetchArticles();
    setTogglerefresh(!togglerefresh);
  };
  /**
   * Handle Tab - changing tabs
   * @param {*}
   */
  const handleTab = (t) => {
    // fetchArticles();
    setTabkey(t);
    if (t === "cutoff") fetchCutoff();
  };
  /**
   * Publication Select
   * @param {*} e
   */
  const publicationClick = (e) => {
    e.preventDefault();
    var pub_id = e.target.getAttribute("data-value");
    setPubid(pub_id);
    setTabkey("articles");
    fetchArticles(pub_id);
  };
  const handleDelete = (e) => {
    setDeleteShow(false);
    switch (e.target.getAttribute("data-action")) {
      case "delete-pub":
        setLoading(true);
        deletePurgePublication({
          id: e.target.getAttribute("data-value"),
          mode: "delete",
        });

        break;
      case "purge-pub":
        setLoading(true);
        deletePurgePublication({
          id: e.target.getAttribute("data-value"),
          mode: "purge",
        });

        break;
      case "hide-pub":
        setLoading(true);
        updatePublication({
          id: e.target.getAttribute("data-value"),
          hidden: pubhide,
        });

        break;
      case "delete-article":
        setLoadingArticle(true);
        deleteArticle({
          id: e.target.getAttribute("data-value"),
          deleted: 1,
        });
        setQueryStringValue("article_id", "");
        break;
      case "delete-guidelines":
        setLoading(true);
        deleteGuidelines({
          id: e.target.getAttribute("data-value"),
        });
        break;
      default:
        break;
    }
    //  setTogglerefresh(!togglerefresh);
  };
  /**
   * handleChange for File upload
   * @param {*} e
   */
  const handleChange = (e) => {
    setProgess(0);
    const file = e.target.files[0]; // accesing file
    // console.log(file);
    setFile(file); // storing file
  };
  const uploadFile = () => {
    const formData = new FormData();
    formData.append("csvfile", file); // appending file
    formData.append("latest", latest);
    axios
      .post("/Administrator/uploadArticles", formData, {
        onUploadProgress: (ProgressEvent) => {
          let progress =
            Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100) +
            "%";
          setProgess(progress);
        },
      })
      .then((res) => {})
      .catch((err) => console.log(err));
  };
  const handleSaveCutoffDate = (e) => {
    saveCutoffDate();
  };
  const subHeaderPublication = useMemo(() => {
    return (
      <div>
        <Row>
          <Col>
            <Form>
              <Form.Control
                width="100%"
                as="select"
                defaultValue={pubtypefilter}
                onChange={handlePublicationtypeFilter}
              >
                <option>All</option>
                <option>Non Pubmed</option>
                <option>Pubmed</option>
                <option>RSS Feed</option>
              </Form.Control>
              <Form.Control
                width="100%"
                as="select"
                defaultValue={pubdeletefilter}
                onChange={handlePublicationDelete}
              >
                <option value="0">Active</option>
                <option value="1">Show Hidden</option>
              </Form.Control>
            </Form>
          </Col>
        </Row>

        <Row className="mt-1">
          <Button type="button" className="round-left" onClick={handleClear}>
            X
          </Button>
          <input
            id="search"
            type="text"
            placeholder="Search by Title or URL"
            value={filterTextPub}
            onChange={(e) => setFilterTextPub(e.target.value)}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                search();
              }
            }}
          />
          <Button type="button" className="round-right" onClick={search}>
            <i className="fa fa-search"></i>
          </Button>
          &nbsp;
          <Button
            type="button"
            onClick={(event) => {
              setAction("add-pub");
              clearPublication();
              setPublicationShow(true);
            }}
            className="round-all"
          >
            <i className="fa fa-plus-circle"></i>
          </Button>
          &nbsp;
        </Row>
      </div>
    );
  }, [filterTextPub, resetPaginationToggle]);

  const subHeaderArticle = useMemo(() => {
    return (
      <div>
        <Row>
          <Col width="100%">
            <AsyncSelect
              cacheOptions
              defaultOptions
              loadOptions={PublicationPromiseOptions}
              // value={article.articlepubcurrent ? article.articlepubcurrent : ""}
              onChange={(selectedOptions) => {
                // handlePubSelect(selectedOptions);
                setCurrentArticlePage(1);
                setPubid(selectedOptions.value);
                // fetchArticles(selectedOptions.value);
              }}
            />
          </Col>
        </Row>
        <Row className="mt-1">
          <Button
            type="button"
            className="round-left"
            onClick={handleClearArticle}
          >
            X
          </Button>
          <input
            id="search_article"
            type="text"
            placeholder="Title, DOI, URL"
            value={filterTextArticle}
            onChange={(e) => setFilterTextArticle(e.target.value)}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                searchArticle();
              }
            }}
          />
          <Button type="button" className="round-right" onClick={searchArticle}>
            <i className="fa fa-search"></i>
          </Button>
          &nbsp;
          <Button
            type="button"
            onClick={(event) => {
              setAction("add-article");
              setArticleid("");
              setArticleTitle("");
              setArticlePub("");
              setArticleURL("");
              setArticleDate("");
              setArticleIndexed(1);
              setArticlePubcurrent("");
              setArticleShow(true);
            }}
            className="round-all"
          >
            <i className="fa fa-plus-circle"></i>
          </Button>
        </Row>
      </div>
    );
  }, [filterTextArticle, resetPaginationToggleArticle]);

  return (
    <main role="main" className="container-fluid main mt-2 mb-3">
      <ErrorMdl error={hasError} setErrorShow={setErrorShow} show={errorshow} />
      <DeleteMdl
        deletedata={deletedata}
        setDeleteShow={setDeleteShow}
        deleteshow={deleteshow}
        handleDelete={handleDelete}
      />

      <PublicationMdl
        publication={{
          pub_id: currentpubid,
          categoryid: categoryid,
          publication: publication,
          description: description,
          pubmedabbrev: pubmedabbrev,
          url: puburl,
          weight: weight,
          date_checked: datechecked,
          currentaction: currentaction,
          publication_type: pubtype,
        }}
        categorydata={lookupdata}
        setCategoryid={setCategoryid}
        setPublication={setPublication}
        setDescription={setDescription}
        setPubmedabbrev={setPubmedabbrev}
        setPuburl={setPuburl}
        setWeight={setWeight}
        setDatechecked={setDatechecked}
        setPublicationtype={setPublicationtype}
        handleClose={handleClose}
        handlePublication={handlePublication}
        validated={validated}
        show={publicationshow}
      />
      <GuidelinesMdl
        guidelines={{
          pub_id: currentpubid,
          publication: publication,
          methodology: methodology,
          evidencegrading: evidencegrading,
          systematicevidence: systematicevidence,
          funding: funding,
          conflict: conflict,
          comments: comments,
          guidelinesexist: guidelinesexist,
        }}
        setMethodology={setMethodology}
        setEvidenceGrading={setEvidenceGrading}
        setSystematicEvidence={setSystematicEvidence}
        setFunding={setFunding}
        setConflict={setConflict}
        setComments={setComments}
        handleClose={handleClose}
        handleGuidelines={handleGuidelines}
        show={guidelinesshow}
      />

      <ArticleMdl
        article={{
          article_id: currentarticleid,
          publicationId: articlepub,
          title: articletitle,
          url: articleurl,
          trip_date: articledate,
          currentaction: currentaction,
          articlepubcurrent: articlepubcurrent,
          indexed: articleindexed,
        }}
        publicationdata={lookupdatapublication}
        // inputValue={inputValue}
        // setInputValue={setInputValue}
        setArticlePub={setArticlePub}
        setArticlePubcurrent={setArticlePubcurrent}
        setArticleTitle={setArticleTitle}
        setArticleURL={setArticleURL}
        setArticleDate={setArticleDate}
        setArticleIndexed={setArticleIndexed}
        handleClose={handleClose}
        handleArticle={handleArticle}
        handleArticleAction={handleArticleAction}
        show={articleshow}
      />

      <Row>
        <SidebarAdmin></SidebarAdmin>

        <Col>
          <Row>
            <Col md="12">
              <Breadcrumb>
                <Breadcrumb.Item href="/Admin">
                  <i className="fa fa-home"></i> Home
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  <i className="fa fa-book"></i> Publications
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <Tabs id="tabs" activeKey={tabkey} onSelect={handleTab}>
                <Tab
                  eventKey="pub"
                  title={
                    <div>
                      <i className="fa fa-book"></i> Publications
                    </div>
                  }
                >
                  <Row>
                    <Col>
                      <DataTable
                        striped={true}
                        customStyles={customStyles}
                        columns={columns_pub}
                        data={data}
                        progressPending={loading}
                        //expandableRows
                        highlightOnHover
                        //defaultSortField="pubication"
                        //expandableRowsComponent={<UserExpandedComponent />}
                        onSort={handleSort}
                        sortServer
                        pagination
                        paginationServer
                        paginationTotalRows={currentTotal}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        subHeader
                        subHeaderComponent={subHeaderPublication}
                      />
                    </Col>
                  </Row>
                </Tab>

                <Tab
                  eventKey="articles"
                  title={
                    <div>
                      <i className="fa fa-university"></i> Articles
                    </div>
                  }
                >
                  <Row>
                    <Col>
                      <DataTable
                        //   title="Articles"
                        striped={true}
                        customStyles={customStyles}
                        columns={columns_article}
                        data={dataArticle}
                        progressPending={currentpubid ? loadingArticle : false}
                        highlightOnHover
                        defaultSortField="id"
                        onSort={handleArticlesSort}
                        sortServer
                        pagination
                        paginationServer
                        paginationTotalRows={currentArticleTotal}
                        onChangeRowsPerPage={handlePerRowsArticlesChange}
                        onChangePage={handlePageArticlesChange}
                        subHeader
                        subHeaderComponent={subHeaderArticle}
                      />
                    </Col>
                  </Row>
                </Tab>
                <Tab
                  eventKey="upload"
                  title={
                    <div>
                      <i className="fa fa-user-circle"></i> Upload here
                    </div>
                  }
                >
                  <Row>
                    <Col>
                      <h4>CSV File fromat</h4>
                      <Table>
                        <thead>
                          <tr>
                            <th>publicationId/source</th>
                            <th>title</th>
                            <th>url</th>
                            <th>date</th>
                            <th>clinicalareas</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>44444</td>
                            <td>Title of the article goes here</td>
                            <td>www.articleurl.co.uk</td>
                            <td>2019</td>
                            <td>Primary care*</td>
                          </tr>
                        </tbody>
                      </Table>
                      <div>*Optional - can be null value</div>
                      <Card>
                        <Card.Body>
                          <Card.Header>
                            <h2 className="text-info">Upload Articles</h2>
                          </Card.Header>
                          <div>
                            <div className="file-upload">
                              <input
                                type="file"
                                className="btn btn-info"
                                ref={el}
                                onChange={handleChange}
                              />{" "}
                              <div
                                className="progessBar mt-2"
                                style={{ width: progress }}
                              >
                                {progress}
                              </div>
                              <Form.Check
                                className="mt-2"
                                type="checkbox"
                                id={`default-checkbox`}
                                label={`Latest`}
                                onChange={(e) => setLatest(e.target.checked)}
                              />
                              <button
                                onClick={uploadFile}
                                className="btn btn-success upbutton mt-2"
                              >
                                {" "}
                                Upload
                              </button>
                              <hr />
                              {/* displaying received image*/}
                              {/* {filedata.path && (
                            <img src={filedata.path} alt={filedata.name} />
                          )} */}
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Tab>
                <Tab
                  eventKey="cutoff"
                  title={
                    <div>
                      <i className="fa fa-user-circle"></i> Cuttoff Date
                    </div>
                  }
                >
                  <Row>
                    <Col>
                      <h4>Cutoff Date for Latest Articles</h4>

                      <div>
                        <DatePicker
                          name="date_from"
                          showPopperArrow={true}
                          selected={cutoff_date}
                          onChange={(date) => {
                            setCutoffDate(date);
                          }}
                          dateFormat="dd/MM/yyyy"
                        />
                        <div>
                          {" "}
                          {datesaved ? (
                            <div
                              role="alert"
                              className="fade alert alert-success show"
                            >
                              Date Saved!
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <Button
                          className="btn-default"
                          onClick={handleSaveCutoffDate}
                        >
                          Set Cut off Date
                        </Button>
                      </div>
                      <div>*Optional - can be null value</div>
                    </Col>
                  </Row>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Col>
      </Row>
    </main>
  );
};

export default Publications;
