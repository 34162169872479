import React, { useState, useEffect } from "react";
import _ from "underscore";
import { Row, Col, Form, Button, Card, Alert } from "react-bootstrap";
import PasswordStrengthBar from "react-password-strength";
import Sidebar from "../components/SidebarRegistration";
import ErrorMdl from "../components/errorMdl";
const axios = require("axios");

const Changepassword = (props) => {
  const [hasError, setErrors] = useState(false);
  const [errorshow, setErrorShow] = useState(false);
  const [passcurrent, setPasscurrent] = useState("");
  const [pass1, setPass1] = useState("");
  const [pass2, setPass2] = useState("");
  const [passkey, setPasskey] = useState("");
  const [passValid, setPassValid] = useState(false);
  const [passMatch, setHideMatch] = useState(true);

  async function fetchCheck() {
    axios
      .get("/User/getToken", {
        withCredentials: true,
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.key) {
            setPasskey(res.data.key);
          } else {
            setErrors("Invalid password reset request");
            setErrorShow(true);
          }
        } else {
          const error = new Error(res.message);
          setErrors(res.message);
          setErrorShow(true);
        }
      })
      .catch((error) => {
        if (!_.isEmpty(error)) {
          setErrors(error);
          setErrorShow(true);
        }
      });
  }

  useEffect(() => {
    fetchCheck();
  }, [passMatch, passValid]);

  async function sendUpdate() {
    axios
      .post("/User/UpdateToken", {
        key: passkey,
        passkey: pass2,
      })
      .then((res) => {
        if (res) {
          if (!_.isUndefined(res.status) && res.status === 200) {
            props.history.push({
              pathname: "/Login",
              state: {
                withCredentials: true,
              },
            });
          } else {
            //todo setError;
            setErrors(res.Error);
            setErrorShow(true);
          }
        } else {
          // const error = new Error(res.error);
          setErrors("Invalid User name or Password");
          setErrorShow(true);
        }
        //callback for the response
      })
      .catch((error) => {
        setErrors(error);
        setErrorShow(true);
      });
  }
  const updateToken = (event) => {
    if (passValid === false) {
      if (!hasError) setErrors({ message: "Invalid Password" });
    }

    if (hasError) setErrorShow(true);
    else {
      setErrorShow(false);
      sendUpdate();
    }

    event.preventDefault();
  };
  const handlePass1 = (params) => {
    setPass1(params.password);
    setPassValid(params.isValid);
    if (!params.isValid) setErrors({ message: "Invalid Password" });
    else {
      //setPassValid(params.isValid);
      setErrors(false);
    }
    //event.preventDefault();
  };

  const handlePass2 = (params) => {
    setPass2(params.password);
    setPassValid(params.isValid);
    setErrors(params.isValid);

    if (pass1 === params.password) {
      setHideMatch(true);
      console.log("it is now matched");
    } else setHideMatch(false);
    if (passMatch === true) {
      setPassValid(false);
      setErrors({ message: "Password mismatched!" });
    } else {
      setPassValid(params.isValid);
      setErrors(false);
    }
  };

  return (
    <main role="main" className="main mt-2">
      <ErrorMdl error={hasError} setErrorShow={setErrorShow} show={errorshow} />

      <Row></Row>
      <Row>
        <Col md="3">
          <Sidebar {...props} />
        </Col>
        <Col md="6">
          <Row>
            <Col>
              <Card variant="info">
                <Card.Body>
                  <Card.Header>
                    <h2>Update Password!</h2>
                  </Card.Header>
                  <Form onSubmit={updateToken}>
                    <Form.Group controlId="pass1">
                      <Form.Label>
                        <i className="fa fa-lock"></i> Enter New Password
                      </Form.Label>

                      <PasswordStrengthBar
                        isRequired={true}
                        password={pass1}
                        minLength={5}
                        minScore={2}
                        changeCallback={handlePass1}
                        inputProps={{
                          name: "password1",
                          autoComplete: "off",
                          required: true,
                          className: "form-control",
                        }}
                      />
                    </Form.Group>
                    <Form.Group controlId="pass2">
                      <Form.Label>
                        <i className="fa fa-lock"></i> Re-type New Password
                      </Form.Label>

                      <PasswordStrengthBar
                        isRequired={true}
                        password={pass2}
                        minLength={5}
                        minScore={2}
                        changeCallback={handlePass2}
                        inputProps={{
                          name: "password2",
                          autoComplete: "off",
                          required: true,
                          className: "form-control",
                        }}
                      />
                    </Form.Group>
                    <Alert variant="info">
                      You must specify a password of minimum 6 characters. You
                      can choose any combination and the password strength meter
                      is for information only.
                    </Alert>
                    <Alert variant="warning" hidden={passMatch}>
                      Password Mismatched
                    </Alert>

                    <Button
                      className="btn btn-success btn-lg pull-right"
                      type="submit"
                      disabled={hasError}
                    >
                      Update Password <i className="fa fa-sign-in"></i>
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </main>
  );
};

export default Changepassword;
