import React, { useState, useEffect } from "react";
import { Table, Row, Col, Button, Alert, Card } from "react-bootstrap";
import DataTable, { pagination } from "react-data-table-component";
import Sidebar from "../components/SidebarRegistration";
import _ from "underscore";
const dateFormat = require("dateformat");
const axios = require("axios");
const { customStyles } = require("./CMS/tableStyle");

const Inbox = (props) => {
  const [currentTotal, setTotal] = useState(0);
  const [hasError, setErrors] = useState(false);
  const [data, setData] = useState([]);
  const [userid, setUserid] = useState(0);
  const [loading, setLoading] = useState(true);
  const [currentPerpage, setPerpage] = useState(10);
  const [currentSort, setSort] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [togglerefresh, setTogglerefresh] = useState(false);
  const [isloggedin, setLoggedin] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  async function fetchLatest() {
    axios
      .get("/Searchlatest", {
        // params: { page: currentPage, sort: currentSort, rows: currentPerpage },
        withCredentials: true,
      })

      .then((res) => {
        if (res.status === 200) {
          setData(res.data.results);
          setLoading(false);
          setLoggedin(1);
        } else {
          const error = new Error(res.message);
          setErrors(res.message);
          setLoggedin(0);
        }
      })
      .catch((error) => {
        if (!_.isEmpty(error)) {
          setErrors(error);
          setLoggedin(0);
        }
      });
  }

  useEffect(() => {
    document.title = "Latest Articles";

    fetchLatest();
  }, [currentPage, currentPerpage, currentSort]);

  const handleSearch = (event, criteria) => {
    props.history.push({
      pathname: "/Searchresult",
      state: JSON.parse(criteria),
    });
    event.preventDefault();
  };

  return (
    <main role="main" className="main mt-2">
      {hasError ? (
        <Alert variant="danger">Request Failed. Access Denied!</Alert>
      ) : (
        <Row>
          <Col md="3">
            <Sidebar {...props} />
          </Col>
          <Col md="9">
            <div dangerouslySetInnerHTML={{ __html: data }}></div>
          </Col>
        </Row>
      )}
    </main>
  );
};

export default Inbox;
