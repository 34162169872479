import React, { useState, useEffect } from "react";
import _ from "underscore";
import { Row, Col, Form, Button, Card, Alert } from "react-bootstrap";
import ErrorMdl from "../components/errorMdl";
import { getQueryStringValue } from "../components/search/queryString";
import { useAuth } from "./../context/auth";

// import { set } from "store";
const axios = require("axios");

const InstitutionUserAuth = (props) => {
  const [hasError, setErrors] = useState(false);
  const [errorshow, setErrorShow] = useState(false);
  const [showPasswordtxt, setPasswordtxt] = useState("Processing Request..");
  const { setAuthTokens } = useAuth();

  async function fetchCheck() {
    axios
      .get("/Auth/InstitutionHashAuth", {
        params: {
          instId: getQueryStringValue("instId"),
          email: getQueryStringValue("email"),
          name: getQueryStringValue("name"),
          hash: getQueryStringValue("hash"),
        },
        withCredentials: true,
      })
      .then((res) => {
        if (!_.isUndefined(res.status) && res.status === 200) {
          setAuthTokens(res.data);
        } else setPasswordtxt("Request failed!!");
        props.history.push({
          pathname: "/Home",
          state: {
            withCredentials: true,
          },
        });
      })
      .catch((error) => {
        //in case of error redirect to home
        setPasswordtxt("Authentication Request failed!!");
        if (!_.isEmpty(error)) {
          setErrors("Authentication failed, resource Not found");
          setErrorShow(true);
        }
        props.history.push({
          pathname: "/Home",
          state: {
            withCredentials: true,
          },
        });
      });
  }

  useEffect(() => {
    if (
      !_.isUndefined(getQueryStringValue("instId")) &&
      !_.isUndefined(getQueryStringValue("email")) &&
      !_.isUndefined(getQueryStringValue("hash"))
    )
      fetchCheck();
  }, []);

  return (
    <main role="main" className="main mt-2">
      <ErrorMdl error={hasError} setErrorShow={setErrorShow} show={errorshow} />

      <Row></Row>
      <Row>
        <Col md="3"></Col>
        <Col md="9">
          <Row>
            <Col>
              {showPasswordtxt && (
                <Card variant="info">
                  <Card.Body>
                    <Card.Header>
                      <h2>Processing...</h2>
                    </Card.Header>
                    <p>{showPasswordtxt}</p>
                  </Card.Body>
                </Card>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </main>
  );
};

export default InstitutionUserAuth;
