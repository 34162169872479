import React from "react";
import { Form, Button, Modal } from "react-bootstrap";
import _ from "underscore";
import "react-datepicker/dist/react-datepicker.css";
const PublicationMdl = ({
  publication,
  categorydata,
  setCategoryid,
  setPublication,
  setDescription,
  setPubmedabbrev,
  setPuburl,
  setWeight,
  setDatechecked,
  setPublicationtype,
  handleClose,
  handlePublication,
  validated,
  show,
}) => {
  var catdata = categorydata.categories ? categorydata.categories : [];
  return (
    <Modal show={show} onHide={handleClose} animation={false} size="xl">
      <Form
        width="100%"
        noValidate
        validated={validated}
        onSubmit={handlePublication}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-success">
            <i className="fa fa-book"></i>{" "}
            {publication.currentaction && publication.currentaction == "add-pub"
              ? "Add Publication"
              : "Update Publication -" + publication.pub_id}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="publication">
            <Form.Label>
              <i className="fa fa-envelope"></i> Publication
            </Form.Label>
            <Form.Control
              type="text"
              name="publication"
              placeholder="Publication Name"
              value={publication.publication ? publication.publication : ""}
              onChange={(e) => setPublication(e.target.value)}
              required
            />
            <Form.Control.Feedback type="invalid">
              *Publication name required
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="pubmedabbrev">
            <Form.Label>
              <i className="fa fa-calendar"></i> Pubmed Abbrev
            </Form.Label>
            <Form.Control
              type="text"
              name="pubmedabbrev"
              placeholder="Pubmed Abbrev"
              value={publication.pubmedabbrev ? publication.pubmedabbrev : ""}
              onChange={(e) => setPubmedabbrev(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="form.category">
            <Form.Label>Category</Form.Label>
            <Form.Control
              as="select"
              defaultValue={
                publication.categoryid ? publication.categoryid : ""
              }
              onChange={(e) => setCategoryid(e.target.value)}
              required
            >
              <option key="" value="">
                Select
              </option>
              {_.map(catdata, function (datavalue, key) {
                return (
                  <option key={key} value={datavalue.value}>
                    {datavalue.label}
                  </option>
                );
                //  console.log("key", key);
              })}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              *Category required
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="paymentForm.to">
            <Form.Label>
              <i className="fa fa-calendar"></i> URL
            </Form.Label>
            <Form.Control
              type="text"
              name="puburl"
              placeholder="URL"
              value={publication.url ? publication.url : ""}
              onChange={(e) => setPuburl(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="ctrl.weight">
            <Form.Label>
              <i className="fa fa-money"></i> Weight
            </Form.Label>
            <Form.Control
              type="text"
              name="weight"
              value={publication.weight ? publication.weight : ""}
              onChange={(e) => setWeight(e.target.value)}
              required
            />
            <Form.Control.Feedback type="invalid">
              *Weight required
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="description">
            <Form.Label>
              <i className="fa fa-comment"></i> Description
            </Form.Label>
            <Form.Control
              as="textarea"
              rows="3"
              name="description"
              value={publication.description ? publication.description : ""}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="pubtype">
            <Form.Label>Select Type</Form.Label>
            <Form.Control
              as="select"
              name="pubtype"
              defaultValue={
                publication.publication_type ? publication.publication_type : ""
              }
              onChange={(e) => setPublicationtype(e.target.value)}
              required
            >
              <option key="pubtype-0" value="">
                Select Type
              </option>
              <option key="Non Pubmed" value="Non Pubmed">
                Non Pubmed
              </option>
              <option key="Pubmed" value="Pubmed">
                Pubmed
              </option>
              <option key="RSS Feed" value="RSS Feed">
                RSS Feed
              </option>
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              *Publication type required
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            <i className="fa fa-times"></i> Close
          </Button>
          <Button variant="primary success" type="submit">
            <i className="fa fa-disc"></i> Save Changes
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default PublicationMdl;
