import React, { useState, useEffect } from "react";
import axios from "axios";
const _ = require("underscore");

const RelatedComponent = ({ data }) => {
  const [isHidden, setHidden] = useState(false);
  const [htmlRes, setHtmlRes] = useState("");
  const handleRelated = (e) => {
    setHidden(!isHidden);
    data.setHiddenRelated(null);
    e.preventDefault();
  };

  const getRelated = async (document_id) => {
    axios
      .get("/Searchrelated", {
        params: { document_id: document_id },
      })
      .then((res) => {
        setHtmlRes(res.data.result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (data.document_id == data.isHiddenRelated) {
      getRelated(data.document_id);
    }
  }, []);
  return (
    <span>
      {data.document_id == data.isHiddenRelated && (
        <div className="related-posts-block">
          <div className="related-posts-block--header row">
            <div className="related-posts-block--title">
              <h5>Related Articles</h5>
            </div>
            <div className="related-posts-block--dismiss">
              <a
                className="js-dismiss"
                href="#"
                onClick={(e) => {
                  handleRelated(e, data.document_id);
                }}
              >
                <i className="fa fa-times"></i>
              </a>
            </div>
          </div>
          <div className="related-posts-block--content row no-gutters">
            <div className="related-posts-block--list col-lg related-posts-block--list__two-col col-lg-8">
              {htmlRes ? (
                <div dangerouslySetInnerHTML={{ __html: htmlRes }}></div>
              ) : (
                <div className="related-posts-block--content row no-gutters">
                  {" "}
                  <div className="spinner-border spinner-border-md text-warning"></div>
                  &nbsp;&nbsp;Loading...
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </span>
  );
};
export default RelatedComponent;
