import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "./App/context/auth";
import _ from "underscore";

function PrivateRouteSEO({ component: Component, ...rest }) {
  var getdata = null,
    getdata2 = null;
  const { authTokens } = useAuth();
  if (authTokens) {
    getdata = _.findWhere(authTokens.roles, {
      role: "Admin",
    });
    getdata2 = _.findWhere(authTokens.roles, {
      role: "SEO",
    });
  }

  var isAdmin = false;
  if (getdata) {
    if (!_.isUndefined(getdata.role))
      isAdmin = getdata.role === "Admin" ? true : false;
  }
  if (getdata2 && isAdmin === false) {
    if (!_.isUndefined(getdata2.role))
      isAdmin = getdata2.role === "SEO" ? true : false;
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        isAdmin ? <Component {...props} /> : <Redirect to="/SEO/Login" />
      }
    />
  );
}

export default PrivateRouteSEO;
