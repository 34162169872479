import _ from "underscore";
const pk = "9lsoo3-4924-4kkkka-llikh2";
const store = require("store");
const jwt = require("jsonwebtoken");
const axios = require("axios");

/**
 * remeber function
 * @param {*} flag
 */
export function remember(flag, username, password) {
  if (flag) {
    var remember_token = "";
    remember_token = jwt.sign({ username: username, password: password }, pk, {
      expiresIn: "300d",
    });
    store.set("remember", remember_token);
  } else {
    store.remove("remember", null);
  }
}
/**
 * checkRemember me
 */
export function checkRemember(setUsername, setPassword, setRememberme) {
  var existingrememberTokens = "";
  if (!_.isUndefined(store.get("remember"))) {
    existingrememberTokens =
      !_.isNull(store.get("remember")) && store.get("remember") !== ""
        ? store.get("remember")
        : null;
    if (!_.isNull(existingrememberTokens)) {
      var decode = jwt.decode(existingrememberTokens, {
        complete: true,
      });
      if (!_.isUndefined(decode.payload)) {
        setUsername(decode.payload.username);
        setPassword(decode.payload.password);
        setRememberme(true);
      }
    } else setRememberme(false);
  } else {
    setRememberme(false);
  }
}
/**
 * stand alone Login function from remember me
 * @param {*} event
 * @param {*} setAuthTokens
 */
export async function handleLoginRemember(setAuthTokens) {
  var existingrememberTokens = "";
  if (!_.isUndefined(store.get("remember"))) {
    existingrememberTokens =
      !_.isNull(store.get("remember")) && store.get("remember") !== ""
        ? store.get("remember")
        : null;
    if (!_.isNull(existingrememberTokens)) {
      var decode = jwt.decode(existingrememberTokens, {
        complete: true,
      });
      if (!_.isUndefined(decode.payload)) {
        return await axios
          .post(
            "/Auth/Login",
            {
              username: decode.payload.username,
              password: decode.payload.password,
            },
            { withCredentials: true }
          )

          .then((res) => {
            if (res) {
              if (!_.isUndefined(res.status) && res.status === 200) {
                setAuthTokens(res.data);
                return res.data;
              }
            }
          })
          .catch((error) => {
            // do nothing!
          });
      }
    }
  }
}
/**
 * checkExpiration
 * @param {*} key
 */
export function checkExpiration(key) {
  var check_expired = false;
  if (!_.isUndefined(store.get(key))) {
    var expiration = store.get(key);
    if (expiration <= new Date().getTime()) {
      store.remove(key);
      check_expired = true;
    }
  } else check_expired = true;
  return check_expired;
}
/**
 * checkAuth
 */
export async function checkAuth(setAuthTokens, setIsLogin) {
  // checkExpiration("auth_check");
  // if (_.isUndefined(store.get("auth_check"))) {
  return await axios
    .get("/Auth/checkLogin", { withCredentials: true })
    .then((res) => {
      var login = false;
      if (res) {
        if (
          !_.isUndefined(res.status) &&
          (res.status === 200 || res.status === 304)
        ) {
          login = res.data.login;
          store.set("auth_check", new Date().getTime() + 1000 * 60 * 1);
        }
      }
      if (login === false) setAuthTokens("");
      setIsLogin(login);
      return login;
    })
    .catch((error) => {
      // do nothing!
      return false;
    });
  //} else return true;
}

export async function logout(setAuthTokens) {
  axios
    .get("/Auth/Logout", {
      withCredentials: true,
    })

    .then((res) => {
      setAuthTokens("");
      if (!_.isUndefined(store.get("instution_auth_tokens"))) {
        store.set("instution_auth_tokens", { institution_auth: null });
      }
    })
    .catch((error) => {
      console.log(error);
    });
}
