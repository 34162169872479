import React from "react";
import { Form, Button, Modal, Row, Col } from "react-bootstrap";
import _ from "underscore";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "../../components/assets/css/Ckeditorcustom.css";

const InstitutionMdl = ({
  institution,
  setInstName,
  setInstshortName,
  setInstEmailRegex,
  setInstLinkResolverImage,
  setInstLinkResolverName,
  setInstLinkResolverUrl,
  setInstSignupcode,
  setInstIpRegex,
  setLibkeyaccessid,
  setLibkeyaccesstoken,
  setOAscope,
  setOAorgid,
  setParentid,
  handleClose,
  handleInstitution,
  show,
}) => {
  return (
    <Modal show={show} onHide={handleClose} animation={false} size="xl">
      <Form width="100%" onSubmit={handleInstitution}>
        <Modal.Header closeButton>
          <Modal.Title className="text-success">
            <i className="fa fa-book"></i>{" "}
            {institution.currentaction &&
            institution.currentaction == "add-institution"
              ? "Add Institution"
              : "Update Institution -" + institution.institution_id}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Row>
            <Form.Group as={Col} controlId="institution.name">
              <Form.Label>
                <i className="fa fa-address-card"></i> Name
              </Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="institution Name"
                value={institution.name ? institution.name : ""}
                onChange={(e) => setInstName(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group as={Col} controlId="institution.short">
              <Form.Label>
                <i className="fa fa-clipboard"></i> Shorter Name
              </Form.Label>
              <Form.Control
                type="text"
                name="shortername"
                placeholder="Shorter name"
                value={institution.shortname ? institution.shortname : ""}
                onChange={(e) => setInstshortName(e.target.value)}
                required
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="email.suffix">
              <Form.Label>
                <i className="fa fa-envelope-circle"></i> Email Suffix
              </Form.Label>
              <Form.Control
                type="text"
                name="emailregex"
                placeholder="Email Suffix"
                value={institution.emailregex ? institution.emailregex : ""}
                onChange={(e) => setInstEmailRegex(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group as={Col} controlId="inst.signupcode">
              <Form.Label>
                <i className="fa fa-code"></i> Signup code
              </Form.Label>
              <Form.Control
                type="text"
                name="signupcode"
                placeholder="Sign up code"
                value={institution.signupcode ? institution.signupcode : ""}
                onChange={(e) => setInstSignupcode(e.target.value)}
                required
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="inst.linkname">
              <Form.Label>
                <i className="fa fa-copy"></i> Link Resolver Name
              </Form.Label>
              <Form.Control
                type="text"
                name="linkresolvername"
                placeholder="Link Resolver"
                value={
                  institution.link_resolver_name
                    ? institution.link_resolver_name
                    : ""
                }
                onChange={(e) => setInstLinkResolverName(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group as={Col} controlId="inst.linkurl">
              <Form.Label>
                <i className="fa fa-address-card-o"></i> Link Resolver URL
              </Form.Label>
              <Form.Control
                type="text"
                name="Linkresolverurl"
                placeholder="Link Resolver URL"
                value={
                  institution.link_resolver_url
                    ? institution.link_resolver_url
                    : ""
                }
                onChange={(e) => setInstLinkResolverUrl(e.target.value)}
                required
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="inst.libkeyid">
              <Form.Label>
                <i className="fa fa-key"></i> Libkey Id
              </Form.Label>
              <Form.Control
                type="text"
                name="libkeyid"
                placeholder="Libkey Library Id"
                value={
                  institution.libkeyaccessid ? institution.libkeyaccessid : ""
                }
                onChange={(e) => setLibkeyaccessid(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group as={Col} controlId="inst.libkeyaccesstoken">
              <Form.Label>
                <i className="fa fa-lock"></i> Libkey Access Token
              </Form.Label>
              <Form.Control
                type="text"
                name="Libkeyaccesstoken"
                placeholder="Libkey Access Token"
                value={
                  institution.libkeyaccesstoken
                    ? institution.libkeyaccesstoken
                    : ""
                }
                onChange={(e) => setLibkeyaccesstoken(e.target.value)}
                required
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="oa.orgid">
              <Form.Label>
                <i className="fa fa-key"></i> OpenAthens Org Id
              </Form.Label>
              <Form.Control
                type="text"
                name="oaorgid"
                placeholder="Organisation Id"
                value={
                  institution.oa_organisation_id
                    ? institution.oa_organisation_id
                    : ""
                }
                onChange={(e) => setOAorgid(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group as={Col} controlId="oa.scope">
              <Form.Label>
                <i className="fa fa-lock"></i> OpenAthens Scope
              </Form.Label>
              <Form.Control
                type="text"
                name="oascope"
                placeholder="Scope"
                value={institution.oa_scope ? institution.oa_scope : ""}
                onChange={(e) => setOAscope(e.target.value)}
                required
              />
            </Form.Group>
          </Form.Row>
          <Form.Group controlId="inst.ip">
            <Form.Label>
              <i className="fa fa-code"></i> IP Addresse (one/line)
            </Form.Label>
            <Form.Control
              type="text"
              as="textarea"
              rows="4"
              name="ipregex"
              placeholder="IP"
              value={institution.ip_regex ? institution.ip_regex : ""}
              onChange={(e) => setInstIpRegex(e.target.value)}
              required
            />
            <br />
            <Form.Label>
              <i className="fa fa-key"></i> Parent ID (Use integer id)
            </Form.Label>
            <Form.Control
              type="text"
              name="parentid"
              placeholder="parent id "
              value={institution.parentid ? institution.parentid : ""}
              onChange={(e) => setParentid(e.target.value)}
              required
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            <i className="fa fa-times"></i> Close
          </Button>
          <Button variant="primary" onClick={handleInstitution}>
            <i className="fa fa-disc"></i> Save Changes
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default InstitutionMdl;
